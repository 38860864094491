import React, { useState, useEffect } from 'react';
import Header from '../organisms/header';
import Sidebar from '../organisms/sidebar';
import "../../../assets/scss/app.scss";
import { useDispatch, useSelector } from 'react-redux';
import { storeMenuCollapse, storeIsFullEngOpen } from "../../../store/actions";
import MyProfile from "../atoms/my-profile"
import { useLocation } from "react-router-dom";

function AuthenticateUserTemplate(props) {
    const [isActive, setIsActive] = useState(false)
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const { useRef } = React;
    const childRef = useRef();
    const childSideRef = useRef();
    const isFullEngOpen = useSelector(state => state.myBookData.isFullEngOpen);


    const handleToggle = () => {
        setIsActive(!isActive)
    };
    const auth = useSelector(state => state.auth);
    const role = auth?.userDetail?.role === "Teacher" ? "Teacher" : auth?.userDetail?.role === "School" ?
        "School" : auth?.userDetail?.role === "District" ? "District" : "Admin";

    const onScrollTop = () => {
        var myElement = document.getElementById('mainContainerId');
        myElement.scrollTop = 0
    }

    useEffect(() => {
        dispatch(storeIsFullEngOpen(false))
        onScrollTop()
        if (window.location.pathname.startsWith('/teacher-Lesson-plan')) {
            !isActive && setIsActive(true)
            !isActive && dispatch(storeMenuCollapse(true));
        }
        else if (window.location.pathname === '/book-flip') {
            !isActive && setIsActive(true)
            !isActive && dispatch(storeMenuCollapse(true));
        }
        else {
            setIsActive(false)
            dispatch(storeMenuCollapse(false));
        }
    }, [pathname]);

    const onSideBarOptionClick = (value) => {
        
        childSideRef?.current?.setActiveItem(value)
    }

    const aleeLibraryPage = /^teacher-library$/;
    const myLibraryPage = /^my-library$/;
    const curatedForYouPage = /^curatedForYou$/;
    const bookdetailPage = /^book-detail$/;
    const teacherLessonPage = /^teacher-lesson$/;
    const schoolTeacherPage = /^school-teacher$/;
    const myTeacherPage = /^my-teachers$/;
    const teacherStudentPage = /^teacher-student$/;
    const mySchoolPage = /^my-schools$/;
    const schoolDetailsPage = /^school-details$/;
    const teacherAllLessonPage = /^teacher-all-lesson$/;
    const pathName = window.location.pathname.split("/")[1]

    let isToAddClass = aleeLibraryPage.test(pathName) ? true : myLibraryPage.test(pathName) ? true :
        curatedForYouPage.test(pathName) ? true : bookdetailPage.test(pathName) ? true :
            teacherLessonPage.test(pathName) ? true : teacherStudentPage.test(pathName) ? true : mySchoolPage.test(pathName) ? true : schoolDetailsPage.test(pathName) ? true : schoolTeacherPage.test(pathName) ? true :
                myTeacherPage.test(pathName) ? true : teacherAllLessonPage.test(pathName) ? true : false

    return (
        <div className={`App ${isActive ? "menuCollapse" : ""} ${role}`} isCollapseActive={isActive}
        >
            <div>
                {auth.userDetail &&
                    <> <Header ref={childRef} onSideBarOptionClick={onSideBarOptionClick} onMenuClick={handleToggle} isActive={isActive} />
                        <Sidebar ref={childSideRef} {...props} isActive={isActive} />
                    </>
                }
            </div>
            <div id="mainContainerId" className={`main-container ${isFullEngOpen ? "isFullEngOpen" : ""}`}>
                <div id="mainPageId" className={`main-page ${isToAddClass ? "teacher-navbar" : ""}`}
                >
                    {role === "Teacher" ?
                        <MyProfile />
                        : role === "School" ?
                            <MyProfile />
                            : role === "District" ?
                                <MyProfile />
                                : null
                    }
                    {props.children}
                </div>
            </div>
        </div>
    );
}


export default AuthenticateUserTemplate;