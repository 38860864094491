import React, { useState, useEffect, useRef, } from "react";
import { Button, Form, Grid, Modal, Input, Header } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { commonFunctions, Notifications } from "../../../../functional/global-import";
import SimpleReactValidator from 'simple-react-validator';
import { apiCall } from "../../../../../store/actions/api.actions";


const AddPromoCode = (props) => {
    const initivalue = {
        promoCodeId: null, promoCodeName: "", numberOfUses: null, promoCodeChargeType: "", amount: null,
        defaultPromoCodeToggle: true, expiryDate: null, startDate: null, isActive: true, durationTime: null, durationType: null,
        isFreeTrial: true
    }
    const [promoCodeDetail, setPromoCodeDetail] = useState(initivalue);
    const [freeTrailLoader, setFreeTrailDetailLoader] = useState(false);
    const [percentageValue, setPercentageValue] = useState(null);
    const [amountValue, setAmountValue] = useState(null);
    const [durationTimeDefault, setDurationTimeDefault] = useState(null);
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const simpleValidatorAmount = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))

    const simpleValidatorPercentageValue = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const simpleValidatorAmountValue = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const simpleDurationValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))

    const onHandleChange = (e, { data, value }) => {
        setPromoCodeDetail({ ...promoCodeDetail, [data]: value })
    };
    const onHandleChangeCheck = (e, { data, checked, type }) => {

        if (type === "checkbox") {
            setPromoCodeDetail({ ...promoCodeDetail, [data]: checked })
        }
    }
    const onHandlesubmitApiCall = (e) => {
        setFreeTrailDetailLoader(true);

        promoCodeDetail.promoCodeChargeType = promoCodeDetail.isFreeTrial ? null :
            promoCodeDetail.defaultPromoCodeToggle ? "A" : !promoCodeDetail.defaultPromoCodeToggle ? "P" : null

        promoCodeDetail.durationTime = durationTimeDefault
        promoCodeDetail.amount = promoCodeDetail.isFreeTrial ? promoCodeDetail.amount :
            promoCodeDetail.defaultPromoCodeToggle ? amountValue : percentageValue
        dispatch(apiCall({
            urls: ["ADDUPDATEPROMOCODE"], method: "POST", data: promoCodeDetail,
            onSuccess: (response) => {
                props.closeModal();
                props.GridReload()
            },
            onFinally: () => {
                setFreeTrailDetailLoader(false);
            }, actionOfMethod:promoCodeDetail?.promoCodeId > 0 ? "Edit" : "Add",
            showNotification: true
        }))
    }
    const onHandleSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        const isPercentageFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidatorPercentageValue, forceUpdate);
        const isAmountFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidatorAmountValue, forceUpdate);
        const isDurationFormValid = commonFunctions.onHandleFormSubmit(e, simpleDurationValidator, forceUpdate);


        if (promoCodeDetail.isFreeTrial) {
            if (isFormValid && isDurationFormValid) {
                onHandlesubmitApiCall()
            }
        }
        else if (!promoCodeDetail.isFreeTrial) {
            if (promoCodeDetail.defaultPromoCodeToggle) {
                if (isFormValid && isAmountFormValid) {
                    onHandlesubmitApiCall()
                }
            }
            else {
                if (isFormValid && isPercentageFormValid) {
                    onHandlesubmitApiCall()
                }
            }
        }

    }
    useEffect(() => {
        if (props.modalType === "EDIT") {
            editPromoCode()
        }
    }, [props.openModal]);

    const editPromoCode = () => {
        if (props.editPromoCode) {

            const { promoCodeId, promoCodeName, numberOfUses, promoCodeChargeType, amount,
                expiryDate, startDate, isActive, isFreeTrial, durationType, durationTime, defaultPromoCodeToggle } = props.editPromoCode;
            const toggle = promoCodeChargeType.trim() === "P" ? false :
                promoCodeChargeType.trim() === "p" ? false :
                    true
            toggle ? setAmountValue(amount) : setPercentageValue(amount)

            setDurationTimeDefault(durationTime)
            setPromoCodeDetail({
                ...promoCodeDetail, promoCodeId: promoCodeId, promoCodeName: promoCodeName,
                numberOfUses: numberOfUses, promoCodeChargeType: promoCodeChargeType, isFreeTrial: isFreeTrial,
                amount: amount === 0 ? null : amount, expiryDate: expiryDate, startDate: startDate, isActive: isActive, durationType: durationType, durationTime: durationTime,
                defaultPromoCodeToggle: toggle
            });
        }
    }

    const onAmountValueChange = (e, { value, data }) => {

        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setAmountValue(e.target.value)
        }
        else {
            setAmountValue("")

        }
    }
    const onPercentageValueChange = (e, { value, data }) => {

        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setPercentageValue(e.target.value)
        }
        else {
            setPercentageValue("")

        }
    }
    const onChange = (e, { value, data }) => {

        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setPromoCodeDetail({ ...promoCodeDetail, [data]: e.target.value })
        }
        else {
            setPromoCodeDetail({ ...promoCodeDetail, [data]: "" })

        }
    }
    const onDurationChange = (e, { value, data }) => {
        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setDurationTimeDefault(e.target.value)
        }
        else {
            setDurationTimeDefault("")

        }
    }
    const priceValidation = simpleValidatorAmount.current.message("amount", promoCodeDetail.amount, "required");

    const isAmountValueError = simpleValidatorAmountValue.current.message('Amount', amountValue, 'required|min:1,num')
    const isPercentageValueError = simpleValidatorPercentageValue.current.message('Precentage', percentageValue,
        'required|min:1,num,|max:100,num')
    const disableValue = promoCodeDetail.promoCodeId > 0
    return (
        <Modal open={props.openModal} onClose={props.closeModal} size="tiny" closeOnDimmerClick={false}>
            <Modal.Header>{promoCodeDetail.promoCodeId > 0 ? "View Promo Code" : "Add Promo Code"} </Modal.Header>
            {freeTrailLoader && commonFunctions.showLoader()}
            <Modal.Content>
                <Form>
                    <Grid>
                        <Grid.Column width={8}>
                            <Form.Input label="Prom code name *" placeholder="Promo code title"
                                data="promoCodeName" onChange={onHandleChange}
                                className="opacity-1"
                                disabled={disableValue}
                                value={promoCodeDetail.promoCodeName} fluid
                                error={simpleValidator.current.message('Promo Code Name', promoCodeDetail.promoCodeName, 'required')} />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="amountPercentag">
                                <Form.Checkbox label="Free Trial"
                                    className="opacity-1"
                                    disabled={disableValue} data="isFreeTrial"
                                    fluid checked={promoCodeDetail.isFreeTrial ? true : false}
                                    onChange={onHandleChangeCheck}
                                    value={promoCodeDetail.isFreeTrial}
                                />
                            </div>
                        </Grid.Column>
                        {promoCodeDetail.isFreeTrial === true &&
                            <>
                                <Grid.Column width={8}>
                                    <Form.Input  className="opacity-1" placeholder="Duration period" disabled={disableValue} label="Duration type"
                                        data="durationType"
                                        value={"Days"} fluid />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Form.Input className="opacity-1" placeholder="Duration period" disabled={disableValue} label="Duration period *"
                                        fluid
                                        onChange={onDurationChange}
                                        data="durationTime"
                                        value={durationTimeDefault}
                                        error={simpleDurationValidator.current.message('DurationTime', durationTimeDefault, 'required|min:1,num|max:365,num')}
                                    />
                                </Grid.Column>
                            </>}
                        {promoCodeDetail.isFreeTrial === false && <>
                            <Grid.Column width={8}>
                                <div className='amountPercentag'>
                                    <label>Precentage</label>
                                    <Form.Checkbox toggle
                                        label="Amount" className='commonToggle percentage opacity-1'
                                        disabled={disableValue}
                                        checked={promoCodeDetail.defaultPromoCodeToggle ? true : false}
                                        onChange={onHandleChangeCheck}
                                        data="defaultPromoCodeToggle"
                                        value={promoCodeDetail.defaultPromoCodeToggle} />
                                </div>
                            </Grid.Column>
                            {promoCodeDetail.defaultPromoCodeToggle === true &&
                                <Grid.Column width={8}>
                                    <div
                                        className={!isAmountValueError ? "customPhoneInputField" : "customPhoneInputField error"}>
                                        <label>Amount *</label>
                                        <Input className="opacity-1" label={{ basic: true, content: '$' }} labelPosition='left'
                                            data="amount" step="any" placeholder="Enter amount" disabled={disableValue}fluid  
                                            value={amountValue}
                                            onChange={onAmountValueChange} />
                                    </div>
                                    {simpleValidatorAmountValue.current.message('Amount', amountValue, 'required|min:1,num')}
                                </Grid.Column>}
                            {promoCodeDetail.defaultPromoCodeToggle === false &&
                                <Grid.Column width={8}>
                                    <div
                                        className={
                                            !isPercentageValueError
                                                ? "customPhoneInputField"
                                                : "customPhoneInputField error"}>
                                        <label>Precentage *</label>
                                        <Input label={{ basic: true, content: '%' }}
                                            labelPosition='right'
                                            disabled={disableValue}
                                            className="opacity-1"
                                            data="amount" step="any"
                                            placeholder="Enter percentage" fluid name="txtValue"
                                            value={percentageValue}
                                            onChange={onPercentageValueChange}
                                        />
                                        {simpleValidatorPercentageValue.current.message('Precentage', percentageValue,
                                            'required|min:1,num,|max:100,num')}
                                    </div>
                                </Grid.Column>
                            }
                        </>}
                        <Grid.Column width={8}>
                            <Form.Input    className="opacity-1" label="Number of uses *" placeholder="Number of uses" data="numberOfUses" onChange={onChange}
                                value={promoCodeDetail.numberOfUses}
                                disabled={disableValue}
                                error={simpleValidator.current.message('number of uses', promoCodeDetail.numberOfUses, 'required|min:1,num')}
                                fluid />
                        </Grid.Column>

                        <Grid.Column width={8} className="status">
                            <b>Status</b>
                            <div  className="statusToggles">
                                <label >Inactive</label>
                                <Form.Checkbox
                                    label="Active"
                                    toggle
                                    disabled={disableValue}
                                    className="commonToggle"
                                    onChange={onHandleChangeCheck}
                                    data="isActive"
                                    checked={promoCodeDetail.isActive ? true : false}
                                />
                            </div>
                        </Grid.Column>

                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondaryBtn' onClick={props.closeModal}> Cancel </Button>
                {promoCodeDetail.promoCodeId > 0 ? null : <Button className='primaryBtn' onClick={onHandleSubmit}>Save</Button>}
            </Modal.Actions>
        </Modal>
    )
}

export default AddPromoCode