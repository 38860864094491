import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Grid, Modal } from 'semantic-ui-react'
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/common-functions";
import { apiCall } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import { Notifications } from "../../../../functional/global-import";

const AddRoleModal = (props) => {
    const [, forceUpdate] = useState()
    const initialValues = {
        applicationRoleId: null,
        applicationId: null,
        applicationRoleName: "",
        applicationRoleDescription: "",
        roleJson: []
    };
    let disableView = props.viewModal
    const [roleLoader, setRoleLoader] = useState(false)
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const [roleForm, setRoleForm] = useState(initialValues);
    const dispatch = useDispatch();


    useEffect(() => {

        if (props?.rolePermission?.roleAndPermission) {
            let rolesData = toParseAllJsonData(props.rolePermission)
            setRoleForm({ ...roleForm, roleJson: rolesData, applicationRoleId: props?.rolePermission?.applicationRoleId, applicationRoleName: props?.rolePermission?.applicationRoleName, applicationRoleDescription: props?.rolePermission?.applicationRoleDescription })
        }
        else {
            getPermissionList();
        }
    }, []);

    const toParseAllJsonData = (data) => {
        let rolesDetail = JSON.parse(data.roleAndPermission)?.map((singleObj) => {
            singleObj.Permissions = singleObj?.Permissions && JSON.parse(singleObj?.Permissions) &&
                JSON.parse(singleObj?.Permissions).map((singlePermissionObj) => {
                    singlePermissionObj.Actions = singlePermissionObj?.Actions && JSON.parse(singlePermissionObj?.Actions)
                    return singlePermissionObj
                })
            return singleObj
        })
        return rolesDetail
    }
    const getPermissionList = () => {
        setRoleLoader(true)
        dispatch(
            apiCall({
                urls: ["GETROLESANDPERMISSION"],
                method: "GET",
                data: ({
                    ApplicationId: null, ApplicationRoleId: 1
                }),
                onSuccess: (response) => {
                    let rolesData = toParseAllJsonData(response)
                    console.log(`roles , ${JSON.stringify(rolesData)}`)
                    setRoleForm({ ...roleForm, roleJson: rolesData })
                }, onFinally: () => {
                    setRoleLoader(false)
                },
            })
        );
    };

    const onErrorOfNoScreenSelected = () => {
        dispatch(Notifications.show({
            title: "Error",
            message: 'Please select atleast one module and screen',
            position: 'br', autoDismiss: 5
        }, "error"))
    }

    const onSaveRoleClick = (e) => {

        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {

            let moduleName = roleForm.roleJson?.filter((item) => {
                return item.Access === 1 || item.Access && item.Permissions
            });
            let allowedScreens = []
            moduleName && moduleName.length > 0 && moduleName?.map((item) => {
                item.Permissions.map((item2) => {

                    if (item2.Access === 1 || item2.Access === true) {
                        allowedScreens.push(item2)
                        return item2
                    }
                    else {
                        return item2
                    }
                })
                return item
            })
            if (allowedScreens.length > 0) {

                setRoleLoader(true)
                roleForm.data = JSON.stringify(roleForm.roleJson)
                dispatch(
                    apiCall({
                        urls: ["ADDROLES"],
                        method: "POST",
                        data: (roleForm),
                        onSuccess: (response) => {
                            setRoleForm(initialValues)
                            props?.closeModal?.()
                            props?.gridReload?.()
                        }, onFinally: () => {
                            setRoleLoader(false)
                        },
                        // actionOfMethod: roleForm?.applicationRoleId > 0 ? "Edit" : "Add",
                        showNotification: true,
                    })
                );
            }
            else {
                onErrorOfNoScreenSelected()
            }


        }
    };

    const onHandleChange = (e, { data, value }) => {
        setRoleForm({ ...roleForm, [data]: value })
    }
    const onModuleClick = (e, { parentIndex, checked }) => {
        roleForm.roleJson[parentIndex].Access = checked
        roleForm.roleJson[parentIndex]?.Permissions &&
            roleForm.roleJson[parentIndex]?.Permissions.map((singleObj) => {
                singleObj.Access = checked
                singleObj?.Actions && singleObj?.Actions?.map((singleAction) => {
                    singleAction.Access = checked
                    return singleAction
                })
                return singleObj
            })
        setRoleForm({ ...roleForm })
    }




    const onScreenClick = (e, { parentIndex, permissionIndex, checked }) => {
        if (permissionIndex === 0) {
            roleForm.roleJson[parentIndex].Access = checked
            if (!checked) {
                roleForm.roleJson[parentIndex]?.Permissions.map((singleObj) => {
                    singleObj.Access = checked
                    singleObj?.Actions && singleObj?.Actions?.map((singleAction) => {
                        singleAction.Access = checked
                        return singleAction
                    })
                    return singleObj
                })
            }
            else {
                roleForm.roleJson[parentIndex].Permissions[permissionIndex].Access = checked
                roleForm.roleJson[parentIndex]?.Permissions[permissionIndex]?.Actions?.map((singleObj) => {
                    singleObj.Access = checked
                    return singleObj
                })
            }
        }
        else if (permissionIndex > 0) {
            if (roleForm.roleJson[parentIndex].Access === 1 || roleForm.roleJson[parentIndex].Access === true) {
                roleForm.roleJson[parentIndex].Permissions[permissionIndex].Access = checked
                roleForm.roleJson[parentIndex]?.Permissions[permissionIndex]?.Actions?.map((singleObj) => {
                    singleObj.Access = checked
                    return singleObj
                })
            }
            else {
                roleForm.roleJson[parentIndex].Access = checked
                roleForm.roleJson[parentIndex]?.Permissions.map((singleObj) => {
                    singleObj.Access = checked
                    singleObj?.Actions && singleObj?.Actions?.map((singleAction) => {
                        singleAction.Access = checked
                        return singleAction
                    })
                    return singleObj
                })
            }
        }
        setRoleForm({ ...roleForm })
    }

    // const onScreenClick = (e, { parentIndex, permissionIndex, checked }) => {


    //     if (permissionIndex === 0) {
    //         roleForm.roleJson[parentIndex].Access = checked
    //     }
    //     else {

    //     }

    //     if (permissionIndex === 0 && !checked) {
    //         roleForm.roleJson[parentIndex]?.Permissions.map((singleObj) => {
    //             singleObj.Access = checked
    //             singleObj?.Actions && singleObj?.Actions?.map((singleAction) => {
    //                 singleAction.Access = checked
    //                 return singleAction
    //             })
    //             return singleObj
    //         })
    //     }
    //     else {
    //         roleForm.roleJson[parentIndex].Permissions[permissionIndex].Access = checked
    //         roleForm.roleJson[parentIndex]?.Permissions[permissionIndex]?.Actions?.map((singleObj) => {
    //             singleObj.Access = checked
    //             return singleObj
    //         })
    //     }



    //     setRoleForm({ ...roleForm })
    // }
    const onActionClick = (parentIndex, permissionIndex, actionIndex) => {


        roleForm.roleJson[parentIndex].Permissions[permissionIndex].Actions[actionIndex].Access = !roleForm.roleJson[parentIndex].Permissions[permissionIndex].Actions[actionIndex].Access
        setRoleForm({ ...roleForm })
    }

    return (
        <Modal open={props.openModal} onClose={props.closeModal} size="small">
            {disableView ? <Modal.Header>View Role</Modal.Header> :
                <Modal.Header> {roleForm?.applicationRoleId > 0 ? "Edit Role" : "Add Role"}</Modal.Header>}
            <Modal.Content scrolling>
                {roleLoader && commonFunctions.showLoader()}
                <Form >
                    <Grid>
                        <Grid.Column width={16}>
                            <Form.Input
                                label="Role* "
                                className="opacity-1"
                                placeholder="Role Name"
                                data="applicationRoleName"
                                disabled={disableView}
                                value={roleForm.applicationRoleName}
                                onChange={onHandleChange}
                                error={simpleValidator.current.message('roleName', roleForm.applicationRoleName, 'required')} fluid />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <Form.TextArea

                                label="Description *"
                                placeholder="Description"
                                disabled={disableView}
                                data="applicationRoleDescription"
                                value={roleForm.applicationRoleDescription}
                                onChange={onHandleChange}
                                error={simpleValidator.current.message('Description', roleForm.applicationRoleDescription, 'required')} fluid
                            />
                        </Grid.Column>
                        <Grid.Column width={16}>
                            <div className='rolesPermissions'>
                                {roleForm?.roleJson && roleForm?.roleJson.map((singleRoleObj, parentIndex) => {
                                    return (
                                        <>
                                            <Form.Checkbox disabled={disableView} label={singleRoleObj.ModuleName === "Alee library" ?
                                                "ALEE library" :
                                                singleRoleObj.ModuleName === "Alee coach" ? "ALEE Coach" :
                                                    singleRoleObj.ModuleName}
                                                parentIndex={parentIndex} className='modulesCheckBox'
                                                checked={singleRoleObj.Access}
                                                onClick={onModuleClick}
                                            />

                                            {singleRoleObj.Permissions &&
                                                singleRoleObj.Permissions.map((singlePermissionObj, permissionIndex) => {

                                                    return (
                                                        <>
                                                            <Form.Checkbox disabled={disableView} label={singlePermissionObj.ScreenName === "Alee-coachs" ? "ALEE-coachs" : singlePermissionObj.ScreenName
                                                            } className='screenCheckBox'
                                                                parentIndex={parentIndex}
                                                                permissionIndex={permissionIndex}
                                                                checked={singlePermissionObj.Access}
                                                                onClick={onScreenClick}
                                                            />
                                                            {singlePermissionObj?.Actions &&
                                                                singlePermissionObj.Actions.map((sibgelActionObj, actionIndex) => {
                                                                    return (
                                                                        <Form.Checkbox disabled={disableView} label={sibgelActionObj.ActionName} className='actionCheckBox'
                                                                            onClick={() => onActionClick(parentIndex, permissionIndex, actionIndex)} checked={sibgelActionObj.Access}/>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )

                                                })
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </Grid.Column>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {disableView ? <Button className='secondaryBtn' onClick={props.closeModal}> Cancel </Button> :
                    <><Button className='secondaryBtn' onClick={props.closeModal}> Cancel </Button>
                        <Button className='primaryBtn'
                            onClick={onSaveRoleClick}
                        >Save</Button>

                    </>
                }
            </Modal.Actions>
        </Modal>
    )
}

export default AddRoleModal