import React, { useState, useRef, useEffect } from 'react';
import { Button, Form, Grid, Header, Image, Icon, Popup } from "semantic-ui-react";
import { apiCall, logout } from "../../store/actions";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { env, commonFunctions } from "../../shared/functional/global-import";
import { BlueLogo } from "../../shared/functional/global-image-import";
import { executeLogin } from '../login/login';

function ResetPassword(props) {
    const search = useLocation().search;
    let token = new URLSearchParams(search).get('Token');
    token === null ? token = props?.props?.token : token = token
    let email = new URLSearchParams(search).get('emailId');
    email === null ? email = props?.props?.urlEmail : email = email
    let isResetPassword = new URLSearchParams(search).get('resetPass');
    isResetPassword === null ? isResetPassword = props?.props?.resetPass : isResetPassword = isResetPassword

    const initialPassword = { emailId: email, password: "", confirmPassword: "", token: token }
    const [password, setPassword] = useState(initialPassword);
    const [resetPasswordStatus, setResetPasswordStatus] = React.useState(false);
    const api = useSelector(state => state.api);
    const [iconToggle, setIconToggle] = React.useState(false)
    const [iconToggleConfirm, setIconToggleConfirm] = React.useState(false)
    const [loader, setLoader] = useState(false);
    const [isverified, setIsverified] = useState(null);
    const [message, setMessage] = useState("");

    const [validation, setValidation] = useState({
        minLength: false,
        number: false,
        upperAndLowerCase: false,
        nonAlphanumeric: false
    });

    const validatePassword = (newPassword) => {
        setValidation({
            minLength: newPassword.length >= 8 && newPassword.length <= 20,
            number: /[0-9]/.test(newPassword),
            upperAndLowerCase: /[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword),
            nonAlphanumeric: /[^A-Za-z0-9]/.test(newPassword)
        });
    };

    useEffect(() => {
        dispatch(logout());
        onVerifyToken()
    }, []);

    const onVerifyToken = () => {
        setLoader(true)
        dispatch(apiCall({
            urls: ["VERIFYTOKEN"], method: "POST", data: {
                refreshToken: token,
                emailId: email
            },
            onSuccess: (response) => {
                setMessage(response?.message)
                setIsverified(true)
            },
            onFinally: () => {
                setLoader(false)
            }, onFailure: (error) => {

                setMessage(error?.responseMessage)
                setIsverified(false)
            },
        }));
    }
    const dispatch = useDispatch();
    let history = useHistory();
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(commonFunctions.initializeSimpleValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))

    const onHandleChange = (e, { value, data }) => {
        setPassword({ ...password, [data]: value });

        if (e.target.name === "txtPassword")
            validatePassword(value);
    }

    const onDiscardClick = () => {
        setPassword(initialPassword)
        simpleValidator.current.hideMessages();
    }

    const onSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        dispatch(logout());
        if (isFormValid) {
            dispatch(apiCall({
                urls: ["UPDATEPASSWORD"], method: "POST",
                data: password,
                onSuccess: (response) => {
                    executeLogin(dispatch, history, {email: password.emailId, password: password.password});
                    setPassword(initialPassword);
                }, showNotification: true
            }))
        }
    }
    const passwordToggle = () => {
        setIconToggle(!iconToggle)
    }
    const newPasswordToggle = () => {
        setIconToggleConfirm(!iconToggleConfirm)
    }
    return (
        <div className="login">
            <div className="loginInner">
                <div className='loginInnerBox resetPassword'>
                    <Image className='logo' src={BlueLogo} />
                    <Form onSubmit={onSubmit}>
                        {isverified === null && commonFunctions.showLoader()}
                        <Grid>
                            <Grid.Column width={16}>
                                {isverified === false ?
                                    <Header className='resetHeading' as="h3">{
                                        isResetPassword === "true" ? "Reset Password" :
                                            isResetPassword === true ? "Reset Password" :
                                                "Set Password"}</Header> : isverified === null ?
                                        null :
                                        <Header className='resetHeading' as="h3">{isResetPassword === "true" ? "Reset Password" :
                                            isResetPassword === true ? "Reset Password" : "Set Password"}</Header>}
                            </Grid.Column>
                            {isverified &&
                                <>
                                    <Grid.Column width={16}>
                                        <div className="password">
                                            <Form.Input type={iconToggle ? "" : "password"}
                                                placeholder="Enter New Password"
                                                name="txtPassword" data="password" onChange={onHandleChange}
                                                error={simpleValidator.current.message('new password', password.password, 'required|min:8|max:20')}
                                                value={password.password} />

                                            {!iconToggle && <Icon title="Show password" name="eye" className="primary-color passwordIcon" onClick={passwordToggle} />}
                                            {iconToggle && <Icon title="Hide Password" name="eye slash" className="primary-color passwordIcon" onClick={passwordToggle} />}
                                            
                                            <ul className="password-criteria">
                                                <li className={validation.minLength ? 'valid' : 'invalid'}>
                                                    Length (8-20 characters)
                                                </li>
                                                <li className={validation.number ? 'valid' : 'invalid'}>
                                                    At least one number
                                                </li>
                                                <li className={validation.upperAndLowerCase ? 'valid' : 'invalid'}>
                                                    Upper and lowercase letters
                                                </li>
                                                <li className={validation.nonAlphanumeric ? 'valid' : 'invalid'}>
                                                    At least one special character (i.e., @, !, #, $, %, & or *)
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="password">
                                            <Form.Input type={iconToggleConfirm ? "" : "password"}
                                                placeholder="Confirm New Password"
                                                name="txtConfirmPassword" data="confirmPassword" onChange={onHandleChange}
                                                error={simpleValidator.current.message('confirm new Password', password.confirmPassword,
                                                    `required|validConfirmPassword:${password.password}`)}
                                                value={password.confirmPassword} />
                                            {!iconToggleConfirm && <Icon title="Show password" name="eye"
                                                className="primary-color passwordIcon" onClick={newPasswordToggle} />}
                                            {iconToggleConfirm && <Icon title="Hide Password" name="eye slash"
                                                className="primary-color passwordIcon" onClick={newPasswordToggle} />}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign="right">
                                        <Button className="secondaryBtn" type="reset"
                                            onClick={onDiscardClick}
                                        >Clear</Button>
                                        <Button className="primaryBtn" loading={api.isApiLoading}
                                        >Save</Button>
                                    </Grid.Column>
                                </>
                            }
                            {isverified === false &&
                                <Grid.Column width={16}>
                                    <Header as="h4">
                                        {message}
                                    </Header>
                                </Grid.Column>
                            }
                        </Grid>
                    </Form>
                </div>
                <div className='notAccount'>
                    <p>For support, please email<a className="mailFontColor" href={`mailto: info@myalee.com`}> info@myalee.com</a></p>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;