import React from "react";
import ResendEmailPage from "./verify-email";
import { useLocation } from "react-router-dom";

const ResendEmail = (props) => {
  const { state } = useLocation();

  return (
    <div>
      <ResendEmailPage props={state} />
    </div>
  );
};

export default ResendEmail;
