import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, Button, Form, Dimmer, Loader } from "semantic-ui-react";
import { Link } from "../../../../functional/global-import";
import { useSelector, useDispatch } from "react-redux";
import { apiCall } from "../../../../../store/actions/api.actions";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/global-import";
function AddCatergory(props) {
    const bookId = useSelector(state => state.myBookData.BookDetail.bookId)
    const initialValues = {
        aleeCoachCategoryId: null,
        aleeCoachCategoryName: "",
        isActive: true}
    const [catergory, setCatergory] = useState(initialValues);
    const api = useSelector((state) => state.api);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const dispatch = useDispatch();
    const onHandleChange = (e, { data, value, checked, type }) => {
                                                                  
        setCatergory({ ...catergory, [data]: value });
        if (type === "checkbox") {
         setCatergory({...catergory,[data]: checked})
        }


    };
    const onHandleSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            dispatch(
                apiCall({
                    urls: ["ADDUPDATECATEGORY"],
                    method: "POST",
                    data: catergory,
                    onSuccess: (response) => {
                        props.closeModal();
                        props.GridReload();
                        setCatergory(initialValues);
                    }, actionOfMethod: catergory.aleeCoachCategoryId > 0 ? "Edit" : "Add",
                    showNotification: true,
                })
            );
        }
    };
    const closeModal = () => {
        setCatergory(initialValues);
        simpleValidator.current.hideMessages();
        props.closeModal();
    }
    useEffect(() => {
        if (props.modalType === "EDIT") {
            editCatergorylist();
        }
    }, [props.editCatergory]);

    const editCatergorylist = () => {
                                                                  
        const { aleeCoachCategoryId, aleeCoachCategoryName, isActive, } = props.editCatergory;
        setCatergory({
            ...catergory, aleeCoachCategoryId: aleeCoachCategoryId, aleeCoachCategoryName: aleeCoachCategoryName, isActive: isActive
        });
    };
    let CatergoryViewModal = props.viewModal;
    return (
        <Modal open={props.openModal} onClose={closeModal} size="small">
            {api.isApiLoading && (
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>)}
            {CatergoryViewModal ? <Modal.Header>View Category</Modal.Header> :
                <Modal.Header>{catergory.aleeCoachCategoryId > 0 ? "Edit Category" : "Add Category"}</Modal.Header>}
            <Modal.Content scrolling>
                <Modal.Description>
                    <Form>
                        <Grid>
                            <Grid.Column width={8}>
                                <Form.Input label="Category Name *"
                               placeholder="Category name"
                                    disabled={CatergoryViewModal}
                                    data="aleeCoachCategoryName"
                                    value={catergory.aleeCoachCategoryName}
                                    onChange={onHandleChange}
                                    error={simpleValidator.current.message('catergoryName',
                                        catergory.aleeCoachCategoryName, "required|min:0|max:50")}
                                />
                            </Grid.Column>
                            <Grid.Column className="status">
                                <b>Status</b>
                                <div className="statusToggles">
                                    <span>Inactive</span>
                                    <Form.Checkbox
                                        label="Active"
                                        toggle
                                        disabled={CatergoryViewModal}
                                        className="commonToggle opacity-1"
                                        onChange={onHandleChange}
                                        data="isActive"
                                        checked={catergory.isActive}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {CatergoryViewModal ? <Button className="secondaryBtn" onClick={closeModal}>Cancel</Button> :
                    <>
                        <Button className="secondaryBtn" onClick={closeModal}>Cancel</Button>
                        <Button className="primaryBtn" onClick={onHandleSubmit} as={Link} to="catergory">
                            {catergory.aleeCoachCategoryId > 0 ? "Update" : "Confirm"}</Button>
                    </>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default AddCatergory;