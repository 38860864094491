import React from "react";
import { Modal, Button, Header } from "semantic-ui-react";
import { useSelector } from 'react-redux';
import { commonFunctions } from "../../../../functional/global-import";


function ConfirmModal(props) {

    const api = useSelector(state => state.api)
    return (
        <Modal size="tiny" open={props.open.modalStatus} dimmer="inverted" closeOnDimmerClick={false} 
        className="deleteModal" onClose={props.close}>
            
            {props.bookdeleteLoder === undefined && api.isApiLoading && commonFunctions.showLoader()}
            {props.bookdeleteLoder && commonFunctions.showLoader()}
            <Modal.Content>

                <Header as="h3">{props.message}</Header>
                {/* <p>  {props.message} </p> */}
                <div className="actionsBtn">
                    <Button className="secondaryBtn" onClick={props.close}>
                        Cancel
                    </Button>
                    <Button className="primaryBtn" onClick={props.onConfirm}  >
                        Confirm
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    )
}
export default ConfirmModal;
