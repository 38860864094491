import React from 'react';
import { Modal, Button } from "semantic-ui-react";
import AleeCoachSummary from "../../../../../pages/alee-coach-summary"


const AleeCoachModal = (props) => {
    return (
        <Modal size="large"  closeOnDimmerClick={false} open={props.open} onClose={props.close} className="aleeCoachModal">
            <Modal.Content className='modal-content' scrolling>
                <AleeCoachSummary
                    aleeCoach={props?.coachId}
                    close={props.close}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button className="secondaryBtn" onClick={props.close}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
export default AleeCoachModal;