import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Modal, Button, Form } from "semantic-ui-react";
import { commonFunctions } from '../../../../functional/common-functions';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const AddResourceModal = (props) => {
    const api = useSelector((state) => state.api);
    const { resources, resourceReload, simpleValidator, gradelist, booklist, chapterList, editorState } = props;
    return (
        <Modal centered size='fullscreen' closeOnDimmerClick={false} open={props.open} onClose={props.close} className='addAleeCoachModal'>
            <Modal.Header>{resources.aleeCoachId > 0 ? "Edit Resources" : "Add Resources"}</Modal.Header>
            <Modal.Content scrolling>
                <Grid.Column width={16}>
                    {/* {resourceReload && commonFunctions.showLoader()} */}
                    {api.isApiLoading && commonFunctions.showLoader()}
                    <Form>
                        <Grid columns="equal">
                            <Grid.Column>
                                <Form.Input
                                    label="Resource Name *"
                                    placeholder="Resource Name"
                                    data="aleeCoachName"
                                    value={resources.aleeCoachName}
                                    onChange={props?.onHandleChanged}
                                    error={simpleValidator.current.message('ResourceName', resources.aleeCoachName, 'required|min:0|max:100')}/>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label="Grade"
                                    placeholder="Select Grade"
                                    options={gradelist}
                                    data="grade"
                                    value={resources.grade}
                                    onChange={props?.onHandleChanged}

                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label="Book *"
                                    placeholder="Select Book"
                                    options={booklist}
                                    data="bookId"
                                    value={resources.bookId}
                                    onChange={props?.onHandleChange}
                                    error={simpleValidator.current.message('book', resources.bookId, 'required')}
                                />
                            </Grid.Column>
                        </Grid>
                        <Grid columns="equal">
                            <Grid.Column >
                                <Form.Select
                                    label="Chapter"
                                    placeholder="Select Chapter"
                                    options={chapterList}
                                    data="chapterId"
                                    value={resources.chapterId}
                                    onChange={props?.onHandleChange}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.TextArea
                                    label="Description *"
                                    placeholder="Description"
                                    data="description"
                                    value={resources.description}
                                    onChange={props?.onHandleChanged}
                                    rows="1"
                                    error={simpleValidator.current.message('ResourceDescription', resources.description, 'required')}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="Resource Type *"
                                    placeholder="Resource Type"
                                    data="resourceType"
                                    value={resources.resourceType}
                                    onChange={props?.onHandleChanged}
                                    error={simpleValidator.current.message('Resource type', resources.resourceType, 'required')}
                                />

                            </Grid.Column>

                        </Grid>
                        <Grid>

                            <Grid.Column width={16} >
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName  aleeCoachEditor"
                                    toolbar={{
                                        options: ["inline", "blockType", "fontSize", 'link', 'embedded', 'textAlign'],
                                        inline: {
                                            inDropdown: false,
                                            className: "test",
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ["bold", "italic", "underline"],
                                            bold: { className: "test", style: { color: "red" } },
                                            italic: { className: undefined },
                                            underline: { className: undefined },
                                        },
                                        embedded: {
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            embedCallback: undefined,
                                            defaultSize: {
                                                height: '300px',
                                                width: '550px',
                                            },
                                        },
                                    }}
                                    onEditorStateChange={props?.onEditorStateChange} />
                            </Grid.Column>

                            {/* <Grid.Column width="16" textAlign="right">
                                <Button className="secondaryBtn" onClick={props?.cancelClear}> Discard </Button>
                                <Button
                                    className="primaryBtn"
                                    onClick={props?.onHandleSubmit}
                                >{resources.aleeCoachId > 0 ? "Update" : "Save"}
                                </Button>
                            </Grid.Column> */}
                        </Grid>
                    </Form>
                </Grid.Column>

            </Modal.Content>
            <Modal.Actions>

                <Button className="secondaryBtn" onClick={props?.close}>
                    Cancel
                </Button>
                {resources.aleeCoachId === null &&
                    <Button className="secondaryBtn" onClick={props?.cancelClear}>Clear</Button>}
                <Button
                    className="primaryBtn"
                    onClick={props?.onHandleSubmit}
                    loading={resourceReload}>
                    {resources.aleeCoachId > 0 ? "Update" : "Save"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
export default AddResourceModal;