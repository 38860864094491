import * as env from "./env.config";
const API_URL = env.API_URL;

const API_ENDPOINTS = {
  LOGIN: "/Authentication/Login",
  TEACHERREGISTRATION: "/Authentication/TeacherRegistration",
  INTERNALTEACHERREGISTRATION: "/Authentication/InternalTeacherRegistration",
  GETTAGSLIST: "/Tags/GetTagsList",
  GETSTUDENTSLIST: "/Student/GetStudentsList",
  GETSUBSCRIPTIONPLANLIST: "/Subscription/GetSubscriptionPlanList",
  PRICES: "/billing/prices",
  BILLINGSTRIPESESSION: "/billing/stripe-session",
  STRIPECUSTOMERPORTAL: "/billing/customer-session",
  BILLINGSUBSCRIPTION: "/billing/subscription",
  GETTEACHERSLIST: "/Teacher/GetTeachersList",
  GETUSERMANAGEMENTLIST: "/Authentication/GetUserManagementList",
  SCHOOLREGISTRATION: "/Authentication/SchoolRegistration",
  APPROVEUSERMANAGEMENT: "/Authentication/ApproveUser",
  GETBOOKSLIST: "/Books/GetBooksList",
  DELETESTUDENT: "/Student/DeleteStudent",
  STUDENTTOGGLE: "/Student/ToggleIsActive",
  SUBSCRIPTIONTOGGLE: "/Subscription/ToggleIsActive",
  DELETESUBSCRIPTION: "/Subscription/DeleteSubscription",
  TAGSDELETETAG: "/Tags/DeleteTag",
  GETLESSONPLANLIST: "/Lesson/GetLessonPlanList",
  LESSONTOGGLEISACTIVE: "/Lesson/ToggleIsActive",
  GETUSERSUBSCRIPTIONLIST: "/Subscription/GetUserSubscriptionList",
  TAGTOGGLEISACTIVE: "/Tags/ToggleIsActive",
  DELETEUSERSUBSCRIPTION: "/Subscription/DeleteUserSubscription",
  USERSUBSCRIPTIONTOGGLE: "/Subscription/UserSubscriptionToggleIsActive",
  TEACHERTOGGLE: "/Teacher/ToggleIsActive",
  DELETETEACHER: "/Teacher/DeleteTeacher",
  GETSCHOOLSLIST: "/School/GetSchoolsList",
  DELETESCHOOL: "/School/DeleteSchool",
  SCHOOLTOGGLE: "/School/ToggleIsActive",
  GETRESOURCESLIST: "/Resource/GetResourcesList",
  DELETERESOURCES: "/Resource/DeleteResources",
  RESOURCESTOGGLE: "/Resource/ToggleIsActive",
  DELETEBOOKS: "/Books/DeleteBook",
  GETGLOBALCODESLIST: "/GlobalCodes/GetGlobalCodesList",
  ADDTAG: "/Tags/AddTag",
  GLOBALCODELIST: "/GlobalCodes/GetGlobalCodesList",
  STANDARDSGLOBALCODELIST: "/GlobalCodes/GetStandardsList",
  ADDSTUDENT: "Student/AddUpdateStudent",
  GETTAGLISTBYID: "/Tags/GetTagListByTagTypeId",
  ADDSTUDENTFORMEXCEL: "/Student/AddStudentFromExcel",
  POSTSUBSCRIPTION: "/Subscription/AddSubscription",
  ADDUPDATESTUDENT: "/Student/AddUpdateStudent",
  GETSUBADMINLIST: "/SubAdmin/GetSubAdminList",
  DELETESUBADMIN: "/SubAdmin/DeleteSubAdmin",
  SUBADMINTOGGLE: "/SubAdmin/ToggleIsActive",
  SUBADMINREGISTRATION: "/Authentication/SubAdminRegistration",
  UPDATESUBADMIN: "/SubAdmin/UpdateSubAdmin",
  UPLOADEXCEL: "/Teacher/UploadExcel",
  ADDTEACHEREXCEL: "/Teacher/AddTeacherFromExcel",
  ADDTEACHERBASICINFO: "/Teacher/AddTeacherBasicInfo",
  ADDUPDATERESOURCES: "/Resource/AddUpdateResources",
  GETEXCELTEMPLATE: "/Student/ExcelTemplate",
  GETEXCELTEMPLATETEACHER: "/Teacher/ExcelTemplate",
  GETBOOKPAGE: "/UploadPdf/GetPagesData",
  GETTAGCUSTOMFIELDS: "/Engagement/GetCustomFieldList",
  GETTAGCUSTOMFIELDSLIST: "/Engagement/GetCustomFieldDataList",
  GETADMINDASHBOARD: "/Dashboard/GetAdminDashboard",
  GETADMINDASHBOARDPERFORMANCE: "/Dashboard/GetAdminDashboardPerformance",
  ADDBOOKSUMMARY: "/Books/AddBookSummary",
  ADDTOPIC: "/Topics/AddTopic",
  GETCHAPTERSTOPIC: "/Topics/GetChaptersTopic",
  DELETETOPIC: "/Topics/DeleteTopic",
  GETBOOKSUMMARY: "/Books/GetBookSummary",
  ADDUPDATECHAPTER: "/Chapter/AddUpdateChapter",
  GETCHAPTERLIST: "/Chapter/GetChaptersList",
  ADDCHAPTERSUMMARY: "/Chapter/AddChapterSummary",
  GETCHAPTERSUMMARY: "/Chapter/GetChaptersSummary",
  DELETECHAPTER: "/Chapter/DeleteChapter",
  ADDGRADE: "/Grades/AddGrade",
  GETGRADESLIST: "/Grades/GetGradesList",
  DELETEGRADE: "/Grades/DeleteGrade",
  GRADESTOGGLE: "/Grades/ToggleIsActive",
  UPDATETAG: "/Tags/UpdateTag",
  ADDTEACHERQUALIFICATION: "/Teacher/AddTeacherQualification",
  GETTEACHERPROFILEDATA: "/Teacher/GetTeacherProfileData",
  GETCHAPTERPAGES: "/Chapter/GetChapterPages",
  ADDBOOKTAG: "/Engagement/AddEngagement",
  UPLOADPDF: "/UploadPdf/UploadBookPdf",
  ADDTEACHERWORKEXPERIENCE: "/Teacher/AddTeacherWorkExperience",
  ADDTEACHERSKILLS: "/Teacher/AddTeacherSkills",
  FORGOTPASSWORD: "/Authentication/ForgotPassword",
  GETTEMPLATELIST: "/Template/GetTemplateList",
  DELETETEMPLATE: "/Template/DeleteTemplate",
  TOGGLEISACTIVE: "/Template/ToggleIsActive",
  ADDTEMPLATEFIELDS: "/Template/AddTemplateFields",
  ADDUPDATETEMPLATE: "/Template/AddUpdateTemplate",
  GETBOOKTAGDATA: "/BookTag/GetBookTagList",
  BOOKSEARCHBYTAG: "/Books/BookSearch",
  GETASSIGNEDTEMPLATES: "/Template/GetAssignedTemplates",
  TOGGLEASSIGNEDTEMPPLATE: "/Template/ToggleAssignedTemplateIsActive",
  DELETEASSIGNEDTEMPLATE: "/Template/DeleteAssignedTemplate",
  POSTTEMPLATEASSIGNED: "/Template/Assigntemplates",
  GETASSIGNEDTEMPLATETEACHER: "/Template/GetAssignedTemplatesToTeacher",
  RESETPASSWORD: "/Authentication/ResetPassword",
  GETBOOKSLISTBYGRADEID: "/Books/GetBooksListByGradeId",
  DELETEPDFRESOURCES: "/Resource/DeletePdfResources",
  DELETEUSER: "/Authentication/DeleteUser",
  GETTEMPLATEFIELD: "/Template/GetTemplateFieldsList",
  CHANGEPASSWORD: "/Authentication/ChangePassword",
  UPDATESCHOOLPROFILE: "/School/UpdateSchoolProfile",
  GETLESSONPLANDATA: "/Lesson/GetLessonPlanData",
  GETTEMPLATEFIELDSLIST: "/Template/GetTemplateFieldsList",
  GETBOOKDETAIL: "/Books/GetBookDetail",
  GETBOOKBASICINFO: "/Books/GetBookBasicInfo",
  UPDATEBOOKBASICINFO: "/Books/UpdateBookBasicInfo",
  GETNOTIFICATIONS: "/Notifications/GetNotifications",
  ADDENGAGEMENTTAGS: "/BookTag/AddEngagementTags",
  GETALEECOACHRESOURCES: "/Resource/GetAleeCoachResources",
  DELETEALEECOACHRESOURCES: "/Resource/DeleteAleeCoachResources",
  GETSTANDARDFORBOOKTAG: "/Engagement/GetStandardForBookTag",
  ADDUPDATEALEECOACH: "/AleeCoach/AddUpdateAleeCoach",
  GETALEECOACHLIST: "/AleeCoach/GetAleeCoachList",
  GETALEECOACH: "/AleeCoach/GetAleeCoach",
  DELETEALEECOACH: "/AleeCoach/DeleteAleeCoach",
  DELETEDBOOKTAG: "/Engagement/DeleteEngagement",
  UPDATENOTIFICATIONISREAD: "/Notifications/UpdateNotificationIsRead",
  BOOKTEXTSEARCH: "/Books/BookTextSearch",
  DELETEALEECOACHCATEGORIES: "/AleeCoach/DeleteAleeCoachCategories",
  ALEECOACHFILTERLIST: "/AleeCoach/GetAleeCoachFilterList",
  ADDSCHOOLDETAIL: "/School/AddSchoolDetail",
  GETSCHOOLDETAILLIST: "/School/GetSchoolDetailList",
  DELETEPROMOCODE: "/Payment/DeletePromocode",
  GETPROMOCODE: "/Payment/GetPromocode",
  ADDUPDATEPROMOCODE: "/Payment/AddUpdatePromocode",
  ADDUPDATEDISTRICT: "/District/AddUpdateDistrict",
  GETDISTRICTLIST: "/District/GetDistrictList",
  GETSCHOOLTODISTRICTLIST: "/District/GetSchoolToDistrictList",
  DELETEDISTRICT: "/District/DeleteDistrict",
  UPDATEPROMOCODESTATUS: "/Payment/UpdatePromoCodeStatus",
  GETROLESANDPERMISSION: "/RoleAndPermission/GetRolesAndPermission",
  ADDROLES: "/RoleAndPermission/AddRoles",
  GETROLES: "/RoleAndPermission/GetRoles",
  DELETEROLE: "/RoleAndPermission/DeleteRole",
  ROLETOGGLE: "/RoleAndPermission/RoleToggle",
  VERIFYTOKEN: "/Authentication/ExpiryRefreshToken",
  UPDATESUBSCRIPTION: "/Subscription/UpdateSubscription",
  USERSUBCRIPTIONTOGGLEISACTIVE: "/Subscription/UserSubscriptionToggleIsActive",
  DELETEUSERSUBSCRIPTION: "/Subscription/DeleteUserSubscription",
  UPDATEPROMOCOSESTATUS: "/Payment/UpdatePromoCodeStatus",
  GETUNVERIFYUSERLIST: "/Authentication/GetUnverifyUserList",
  ADDUPDATECATEGORY: "/AleeCoach/AddUpdateAleeCoachCategory",
  CATERGORYTOGGLE: "/AleeCoach/ToggleIsActive",
  RESENDPAYMENTLINK: "/Authentication/ResendPaymentLink",
  //************************************ teacher api's****************************************************************//
  LOGOUT: "/Teacher/UpdateTeacherLogout",
  GETFEATUREREADINGLIST: "/FeatureReading/GetFeatureReadingList",
  GETFEATUREREADINGBOOK: "/FeatureReading/GetFeatureReadingBook",
  ADDFAVORITEBOOK: "/FavoriteBook/AddFavoriteBook",
  REFRESHTOKEN: "/Authentication/RefreshToken",
  GETPAGEDATA: "/UploadPdf/GetPageData",
  ADDUPDATEFEATUREREADING: "/FeatureReading/AddUpdateFeatureReading",
  ADDUPDATEFEATUREREADINGBOOK: "/FeatureReading/AddUpdateFeatureReadingBook",
  ADDLESSONPLANDATA: "/Lesson/AddLessonPlan",
  GETBOOKSDETAIL: "/Books/GetBooksDetail",
  GETLESSONVIEWLIST: "/Lesson/GetLessonPlanViewAllList",
  ADDUPDATELESSONPLANBLOCK: "/Lesson/AddUpdateLessonPlanBlock",
  DELETELESSONPLAN: "/Lesson/DeleteLessonPlan",
  GETLESSONPLANBLOCKLIST: "/Lesson/GetLessonPlanBlockList",
  DELETELESSONPLANBLOCK: "/Lesson/DeleteLessonPlanBlock",
  GETLESSONPLANBLOCK: "/Lesson/GetLessonPlanBlock",
  ADDTEACHER: "/Teacher/AddTeacher",
  GETTEACHER: "/Teacher/GetTeacher",
  GETTAG: "/Engagement/GetTag",
  GETSTANDARD: "/Grades/GetStandard",
  ADDTEACHERSTEP1: "/Teacher/AddTeacherStep1",
  ADDTEACHERSTEP2: "/Teacher/AddTeacherStep2",
  GETENGAGEMENT: "/Engagement/GetEngagement",
  GETTEACHERSTEP1: "/Teacher/GetTeacherStep1",
  GETTEACHERSTEP2: "/Teacher/GetTeacherStep2",
  ADDSTUDENTFORMEXCELV2: "/Student/AddStudentFromExcelV2",
  GETRESOURCES: "/Resource/GetResources",
  GETGRADESTANDARD: "/Grades/GetGradeStandard",
  ADDUPDATEBOOKMARK: "/Books/AddUpdateBookmark",
  BOOKSEARCH_v1: "/Books/BookSearch_v1",
  GETLESSONPLANMOVE: "/Lesson/GetLessonPlanMove",
  GETALEECOACHBYBOOK: "/AleeCoach/GetAleeCoachByBook",
  ADDDUPLICATELESSONPLAN: "/Lesson/AddDuplicateLessonPlan",
  GETALEECOACHCATEGORY: "/AleeCoach/GetAleeCoachCategory",
  ENGAGEMENTCUSTOMFIELDS: "/Engagement/GetCustomField",
  GETLESSONPLANPDF: "/Lesson/GetLessonPlanPDF",
  LESSONPLANEMAILPDF: "/Lesson/LessonPlanEmailPDF",
  ADDPAYMENT: "/Payment/AddPayment",
  VERIFYSIGNUPTOKEN: "/Authentication/VerifySignUpToken",
  GETTEACHERDASHBOARD: "/Dashboard/GetTeacherDashboard",
  //  common  api's 
  RESENDEMAIL: "/Authentication/ResendEmail",
  UPDATEPASSWORD: "/Authentication/UpdatePassword",
  ADDPROMOCODE: "/Payment/AddPaymentPromocode",
  VERIFYPAYMENTTOKEN: "/Payment/VerifyPaymentToken",
  VERIFYSETPASSWORDTOKEN: "/Payment/VerifySetPasswordToken",
  ADDEMAILRESPONSE: "/Payment/AddEmailResponse",
  DUPLICATELESSONBLOCK: "/Lesson/AddDuplicateLessonPlanBlock",
  RESENDSETPASSWORDEMAIL: "/Authentication/SetPasswordEmail",
  TEACHERPAYMENTSTATUS: "/Authentication/TeacherPaymentStatus",
  PAYMENTFLOWSTATUS: "/Authentication/PaymentFlowStatus",
  GETSHORTENGAGEMENTLIST: "/Engagement/GetShortEngagementList",
  ADDBOOKPAGEWIDTH: "/Books/AddPageWidth",
  DELETEMULTIPLESTUDENT: "/Student/DeleteMultipleStudent",

  //****************school api's**********************//
  ADDTEACHERTOSCHOOL: "/School/AddTeacherToSchool",
  GETSCHOOLTEACHER: "/School/GetSchoolsTeacherList",
  GETSCHOOL: "/School/GetSchool",
  UploadTecherExcel: "/Teacher/TeacherExcel",
  UploadTeacherFormExcel: "/Teacher/UploadTeacherFromExcel",
  ADDTEACHERFROMEXCEL: "/Teacher/AddTeacherFromExcel",
  UPDATETEACHER: "/Teacher/UpdateTeacher",
  GETSCHOOLDASHBOARD: "/Dashboard/GetSchoolDashboard",

  //***************************District api's****************//
  GETDISTRICT: "/District/GetDistrict",
  DELETETEACHER: "/Teacher/DeleteTeacher",
  GETDISTRICTDASHBOARD: "/Dashboard/GetDistrictDashboard",

  MESSAGELIST: "/Messages",
  ADDMESSAGE: "/Messages",
  MYMESSAGES: "/Messages/My",
  MARKMESSAGEASREAD: "/Messages/markAsRead",
  DELETEMESSAGE: "/Messages",

};

export const getApiUrl = (key) => {
  return API_URL + API_ENDPOINTS[key];
};
