import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Grid } from "semantic-ui-react";
import { apiCall } from "../../../../../store/actions/api.actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/global-import";

function AddIndividualTeacher(props) {
    const auth = useSelector((state) => state.auth);
    const globalCode = useSelector(state => state.global.codes);
    const [teacherForm, setTeacherForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        roleId: null,
        studentCount: null,
        schoolName: "",
        excelReferenceId: null,
        isIndividuallTeacher: false
    });

    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const api = useSelector((state) => state.api);
    const dispatch = useDispatch();
    const [stateData, setStateData] = useState([]);

    useEffect(() => {
        getGlobal();
    }, []);

    const getGlobal = () => {
        let roleCatergoryId = (commonFunctions.getGlobalCodeDetails(globalCode, "TeacherRoles", "Teacher")).categoryId
                                         
        dispatch(apiCall({
            urls: ["GLOBALCODELIST"], method: "GET", data: { categoryId: roleCatergoryId },
            onSuccess: (response) => {
                const role = [{ text: "Select your role", value: null }]
                response.map((singledata) => {
                    role.push({ text: singledata.codeName, value: singledata.globalCodeId });
                    return { ...singledata}});
                    
                setStateData(role)}
        }))
    }
    const onHandleChange = (e, { value, data }) => {
        if (value === -1) {
            setTeacherForm({ ...teacherForm, [data]: null })
        }
        else {
            setTeacherForm({ ...teacherForm, [data]: value })
        }
    }
    const onChangePrice = (e, { value, data }) => {
        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setTeacherForm({ ...teacherForm, [data]: e.target.value });
        } else {
            setTeacherForm({ ...teacherForm, [data]: "" });
        }
    };
    const onHandleRole = (e, { value, data }) => {

        setTeacherForm((prevstate) => ({ ...prevstate, [data]: value === "" ? null : value }))
    }

    const onHandleSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(
            e,
            simpleValidator,
            forceUpdate
        );
        if (isFormValid) {
            dispatch(
                apiCall({
                    urls: ["INTERNALTEACHERREGISTRATION"],
                    method: "POST",
                    data: teacherForm,
                    onSuccess: (response) => {
                        props?.closeModal()
                        props?.onGridReload()
                    },
                    showNotification: true,
                })
            );
        }
    }

    return (
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            closeOnDimmerClick={false}
            size="small"
        >
            {api.isApiLoading && commonFunctions.showLoader()}
            <Modal.Header> Add Teacher</Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <Form className="signUpForm">
                        <Grid columns="2">
                            <Grid.Column>
                                <Form.Input
                                    label="SCHOOL OR ORGANIZATION"
                                    data="schoolName"
                                    onChange={onHandleChange}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="PROFESSIONAL EMAIL"
                                    data="email"
                                    onChange={onHandleChange}
                                    error={simpleValidator.current.message(
                                        " email",
                                        teacherForm.email,
                                        "required|email"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="FIRST NAME"
                                    data="firstName"
                                    onChange={onHandleChange}
                                    error={simpleValidator.current.message("firstName", teacherForm.firstName, "required"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="LAST NAME"
                                    data="lastName"
                                    onChange={onHandleChange}
                                    error={simpleValidator.current.message(
                                        "lastName",
                                        teacherForm.lastName,
                                        "required"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label='ROLE'
                                    fluid
                                    options={stateData}
                                    data="roleId"
                                    value={teacherForm.roleId}
                                    onChange={onHandleRole}
                                    error={simpleValidator.current.message(
                                        "role",
                                        teacherForm.roleId,
                                        "required"
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="HOW MANY STUDENTS DO YOU HAVE?"
                                    data="studentCount"
                                    onChange={onChangePrice}
                                    value={teacherForm.studentCount}
                                    error={simpleValidator.current.message(
                                        "studentCount",
                                        teacherForm.studentCount,
                                        "required|min:1,num"
                                    )}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className="secondaryBtn" onClick={() => props?.closeModal()}>
                    {" "}
                    Cancel{" "}
                </Button>
                <Button
                    className="primaryBtn"
                    onClick={onHandleSubmit}
                    loading={api.isApiLoading}> Save</Button>
            </Modal.Actions>
        </Modal >
    );
}

export default AddIndividualTeacher;