import React, { useState } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import { Link } from "../../../functional/global-import";
import { env } from "../../../functional/global-import";
import { commonFunctions } from "../../../functional/global-import";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../../../store/actions/auth.actions";
import { apiCall } from "../../../../store/actions/api.actions";
import ChangePassword from "../../organisms/modal/change-password/change-password";
import { useHistory } from "react-router-dom";


const MyProfile = (props) => {
    // const {trigger, userRole} = props ;
    const dispatch = useDispatch();
    let history = useHistory();
    const image = useSelector(state => state.auth.userDetail.image);
    const userRole = useSelector(state => state.auth.userDetail.role);
    const userId = useSelector(state => state.auth.userDetail.userId);
    const isPaymentSkipped = useSelector(state => state.auth.userDetail.isPaymentSkipped);

    const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false)

    const r = /^teacher-Lesson-plan$/;
    const pathName = window.location.pathname.split("/")[1]
    let isToHide = pathName.startsWith("teacher-Lesson-plan");

    const trigger = (<span>{isToHide ? "" : "My Account"} <Image src={commonFunctions.concatenateImageWithAPIUrl(image)} /></span>)

    const onHandleLogout = () => {
        if (userId) {
            dispatch(apiCall({
                urls: ["LOGOUT"], method: "POST",
                data: {
                    userId: userId,
                    isLogIn: false
                },
                onSuccess: (response) => {
                    // dispatch(logout());
                    history.push(`${env.PUBLIC_URL}/`);
                }, showNotification: true
            }))
        }
        dispatch(logout());
    }

    const forgetPassword = () => {
        setForgotPasswordStatus(!forgotPasswordStatus);
    };

    const manageSubscription = () =>{
        dispatch(apiCall({
            urls: ["STRIPECUSTOMERPORTAL"], 
            method: "GET",
            onSuccess: (response) => {
                if (response?.url)
                    window.location.href=response.url;
            },
            onError: (e)=>{
                
            },
            onFinally: () => {

            }
        }));
    }

    return (
        <div className="profile">
            <Dropdown trigger={trigger} icon={null}>
                <Dropdown.Menu className="left setting-dropdown" >
                {userRole === "District" &&
                        <Dropdown.Item
                            as={Link}
                            to={`${env.PUBLIC_URL}/district-profile`}
                            icon="user"
                            text="Manage Profile"
                        />}
                    {userRole === "School" &&
                        <Dropdown.Item
                            as={Link}
                            to={`${env.PUBLIC_URL}/school-profile`}
                            icon="user"
                            text="Manage Profile"
                        />}
                    {userRole === "Teacher" &&
                        <Dropdown.Item
                            as={Link}
                            to={`${env.PUBLIC_URL}/create-profile`}
                            icon="user"
                            text=" My Profile"
                        /> 
                    } 
                    <Dropdown.Item
                        icon="key"
                        text="Change Password"
                        onClick={forgetPassword}
                    />
                    { userRole === "Teacher" && !isPaymentSkipped  &&
                    <Dropdown.Item
                        icon="gem outline"
                        text="Manage Subscription"
                        onClick={manageSubscription}
                    />
                    }
                    {userRole === "Teacher" &&
                    <Dropdown.Item
                        href="https://myalee.com/faq/"
                        target="_blank"
                        icon="question circle outline icon"
                        text="FAQ"
                    />
                    } 
                    <Dropdown.Item
                        // as={Link}
                        // to={`${process.env.REACT_APP_PUBLIC_URL === "" ? "/" : process.env.REACT_APP_PUBLIC_URL}`}
                        icon="log out"
                        text="Logout"
                        onClick={onHandleLogout}
                    />
                </Dropdown.Menu>
            </Dropdown>

            {forgotPasswordStatus &&
                <ChangePassword openModal={forgotPasswordStatus} closeModal={forgetPassword} />
            }

        </div>
    );
};



export default MyProfile;
