import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Modal, Button, Form, Icon } from "semantic-ui-react";
import { commonFunctions } from '../../../../functional/common-functions';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const AddAleeCoachModal = (props) => {
    const api = useSelector((state) => state.api);
    const { addAleeCoach, aleeCoachLoader, simpleValidator,
        grade, allStandard, booklisted, categoryList, selectedCoachCategory,
        editorState, editorStated } = props;
                                                                                                                                                   
    return (
        <Modal centered size='fullscreen' closeOnDimmerClick={false} open={props.open} onClose={props.close} className='addAleeCoachModal'>

            <Modal.Header>{addAleeCoach.aleeCoachId > 0 ? "Edit ALEE Coach" : "Add ALEE Coach"}</Modal.Header>
            <Modal.Content scrolling>
                <Grid.Column width={16}>
                    {/* {aleeCoachLoader && commonFunctions.showLoader()} */}
                    {api.isApiLoading && commonFunctions.showLoader()}
                    <Form>
                        <Grid columns="3">
                            <Grid.Column>
                                <Form.Input
                                    label="Title *"
                                    placeholder="Title"
                                    data="aleeCoachName"
                                    value={addAleeCoach.aleeCoachName}
                                    onChange={props?.onHandleChange}
                                    error={simpleValidator.current.message('Name',
                                        addAleeCoach.aleeCoachName, 'required|min:0|max:100')}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label="Grade"
                                    placeholder="Select Grade"
                                    options={grade}
                                    fluid multiple selection
                                    search
                                    data="grade"
                                    value={addAleeCoach.grade}
                                    noResultsMessage={props?.getExceptionMessage("NO_RECORDS_IN_DROPDOWN")}
                                    onChange={props?.onHandleChangegGrade}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label="Standard"
                                    placeholder=' Select Standard'
                                    fluid multiple selection
                                    search
                                    data="standarded"
                                    value={addAleeCoach.standarded}
                                    options={allStandard}
                                    noResultsMessage={props?.getExceptionMessage("NO_RECORDS_IN_DROPDOWN")}
                                    onChange={props?.onHandleStandard} />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Select
                                    label="Book"
                                    placeholder='Select Book'
                                    search
                                    data="bookId"
                                    value={addAleeCoach.bookId}
                                    options={booklisted}
                                    noResultsMessage={props?.getExceptionMessage("NO_RECORDS_IN_DROPDOWN")}
                                    onChange={props?.onHandleChange} />
                            </Grid.Column>
                            <Grid.Column >
                                <div className="addCategoriesToCoach">
                                    <Form.Dropdown search selection allowAdditions fluid
                                        label="Add categories to coach"
                                        placeholder="Select Categories"
                                        options={categoryList}
                                        data="aleeCoachCategoryName"
                                        value={addAleeCoach.aleeCoachCategoryName}
                                        id={categoryList}
                                        className="tagger"
                                        onChange={props?.onHandleCategoryChange}
                                        onAddItem={props?.onHandleAddition}
                                        name="ddlChooseTags" />
                                    {/* {selectedCoachCategory?.value === null ?
                                        null
                                        : selectedCoachCategory?.value !== "" ?
                                            <Icon name="close" title="Delete Categorie" onClick={() =>
                                                props?.confirmModalOpen(selectedCoachCategory.id, "delete")} /> : null} */}
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.TextArea
                                    label="Description *"
                                    placeholder='Description'
                                    rows={1}
                                    fluid
                                    search
                                    selection
                                    data="description"
                                    value={addAleeCoach.description}
                                    onChange={props?.onHandleChange}
                                    error={simpleValidator.current.message('Description', addAleeCoach.description, 'required')}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="Coach Type *"
                                    placeholder="Coach Type"
                                    data="aleeCoachType"
                                    value={addAleeCoach.aleeCoachType}
                                    onChange={props?.onHandleChange}
                                    error={simpleValidator.current.message('Coach type', addAleeCoach.aleeCoachType, 'required')}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="Media Type"
                                    placeholder="Media Type"
                                    data="mediaType"
                                    value={addAleeCoach.mediaType}
                                    onChange={props?.onHandleChange}
                                    // error={simpleValidator.current.message('Media type', addAleeCoach.mediaType, 'required')}
                                />
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column width={8} >
                                <Editor editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName  aleeCoachEditor"
                                    toolbar={{
                                        options: ["inline", "blockType", "fontSize", 'link', 'list', 'embedded', "textAlign"],
                                        inline: {
                                            inDropdown: false,
                                            className: "test",
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ["bold", "italic", "underline"],
                                            bold: { className: "test", style: { color: "red" } },
                                            italic: { className: undefined },
                                            underline: { className: undefined }
                                        },
                                        embedded: {
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            embedCallback: undefined,
                                            defaultSize: {
                                                height: '300px',
                                                width: '550px',
                                            },
                                        },
                                    }}
                                    onEditorStateChange={props?.onEditorStateChange} />
                            </Grid.Column>
                            <Grid.Column width={8} >
                                <Editor
                                    editorState={editorStated}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName  aleeCoachEditor"
                                    toolbar={{
                                        options: ["inline", "blockType", "fontSize", 'link', 'list', 'embedded', "textAlign"],
                                        inline: {
                                            inDropdown: false,
                                            className: "test",
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ["bold", "italic", "underline"],
                                            bold: { className: "test", style: { color: "red" } },
                                            italic: { className: undefined },
                                            underline: { className: undefined },
                                        },
                                        embedded: {
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            embedCallback: undefined,
                                            defaultSize: {
                                                height: '300px',
                                                width: '550px',
                                            },
                                        },
                                    }}
                                    onEditorStateChange={props?.onEditorState} />
                            </Grid.Column>
                            <Grid.Column width="16" textAlign="right">

                            </Grid.Column>
                        </Grid>

                    </Form>
                </Grid.Column>

            </Modal.Content>
            <Modal.Actions>

                <Button className="secondaryBtn" onClick={props?.close}>
                    Cancel
                </Button>
                {addAleeCoach.aleeCoachId === null &&
                    <Button className="secondaryBtn" onClick={props?.onHandleCancel} > Clear </Button>
                }
                <Button
                    className="primaryBtn"
                    onClick={props?.onHandleSubmit}
                    loading={aleeCoachLoader}>
                    {addAleeCoach.aleeCoachId > 0 ? "Update" : "Save"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
export default AddAleeCoachModal;