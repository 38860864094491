// inspired by https://leanpub.com/redux-book
import axios from "axios";
import * as types from "../types";
import * as actions from "../actions";
import * as env from '../../config/env.config';
import { getApiUrl } from '../../config/api.config';
import { Notifications } from '../../shared/functional/global-import';
import { NoTemplateRoutesConfing } from "../../config/routes";


const onCateringError = async (error, dispatch, showNotification, onSuccess) => {

    let APIErrorResut = axios(error.config);
    let result = await APIErrorResut
    // console.log(`result, , ${result}`)
    APIErrorResut.then(response => {
        if (showNotification) {
            dispatch(Notifications.success({ title: "Success", message: response.data.responseMessage, position: 'br', autoDismiss: 5 }));
        }
        onSuccess(response.data);
    }).catch(error => {
        dispatch(actions.accessDenied(window.location.pathname));
    });
}


const apiMiddleware = ({ dispatch, getState }) => next => action => {

    next(action);
    if (action.type !== types.API_CALL) return;

    let state = getState();
    const { urls, method, data, onSuccess, onFailure, onFinally, headers, showNotification, isFormData,
        actionOfMethod } = action.payload;


    const pathName = window.location.pathname.split("/")[1]

    let noTempleteRoute = NoTemplateRoutesConfing.filter((singleObj) => {
        return singleObj.path.split("/")[1] === pathName
    })
    // && pathName !== ""
    if (noTempleteRoute.length === 0 && state.auth.userDetail?.roleAndPermission?.roleAndPermission === null) {
        dispatch(actions.logout())
        return false;
    }
    if (noTempleteRoute.length === 0 && state.auth.userDetail?.roleAndPermission === undefined) {
        dispatch(actions.logout())
        return false;
    }

    // permision based action---------------------
    const oldUrlScreenNames = window.location.href.split("/");
    const oldUrlScreenName = oldUrlScreenNames[oldUrlScreenNames.length - 1];

    let moduleName = state.auth.userDetail?.roleAndPermission && state.auth.userDetail?.roleAndPermission.length > 0 && state.auth.userDetail?.roleAndPermission?.filter((item) => {
        return item.Access === 1 && item.permission
    });
    let allowedScreens = []
    moduleName && moduleName.length > 0 && moduleName?.map((item) => {
        item.permission.map((item2) => {
            if (item2.Access) {
                allowedScreens.push(item2)
                return item2
            }
            else {
                return item2
            }
        })
        return item
    })

    let allowedAction = []
    allowedScreens.map((item3) => {
        item3.action?.map((item4) => {
            if (oldUrlScreenName?.toLowerCase() === item3.ScreenName?.toLowerCase()) {
                allowedAction.push(item4)
                return item4
            }
            else {
                return item4
            }
        })
        return item3
    })


    let permissionAction = method === "POST" ? actionOfMethod : method === "PATCH" ? actionOfMethod
        : method === "PUT" ? "Edit" : method === "DELETE" ? "Delete"
            : null
    if (method !== "GET" && urls[0] !== "UPDATENOTIFICATIONISREAD" && allowedAction.filter((item) => item.Access === 0 && item.ActionName === permissionAction).length > 0) {
        dispatch(Notifications.error({ title: "Security", message: "Action is not accessible. ", position: 'br', autoDismiss: 5 }));
        onFinally();
        return false;
    }


    // axios default configs
    axios.defaults.headers.common["Content-Type"] = isFormData ? "multipart/form-data" : "application/json";

    if (state.auth && state.auth.userDetail && state.auth.userDetail.token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${state.auth.userDetail.token}`;
    }
    const dataOrParams = ["GET", "DELETE", "PATCH"].includes(method) ? "params" : "data";


    // Application level loading start

    urls[0] !== "GETNOTIFICATIONS" && dispatch(actions.apiStart());

    let APIResut;
    // checking is it multiple api call
    if (urls && urls.length > 1) {

        // Call multiple API call using axios via Promise.all
        const fetchURL = (url) => axios.get(getApiUrl(url));
        const promiseArray = urls.map(fetchURL);
        APIResut = Promise.all(promiseArray);
    }
    else {
        // Api Call for single api
        APIResut = axios.request({ url: getApiUrl(urls[0]), method, headers, [dataOrParams]: data, responseType: data?.IsDownload ? 'blob' : "" })
    }

    APIResut.then(response => {
        // Show notification
        if (showNotification) {
            dispatch(Notifications.success({ title: "Success", message: response.data.responseMessage, position: 'br', autoDismiss: 5 }));
        }
        // callback function
        onSuccess(response.data);
        // console sucess if environment is not in production
        if (env.NODE_ENV !== "production") {
            // if (response.length > 0) {
            //     response.map((response, index) => {
            //         return console.log('API : ' + urls[index] + ' Success response :' + JSON.stringify(response));
            //     });
            // } else {
            //     return console.log('API : ' + urls[0] + ' Success response :' + JSON.stringify(response));
            // }
        }
    }).catch(error => {
        const { response } = error;
        if (response) {
            const { status } = response;
            if (status === 401) {
                dispatch(actions.apiCall({
                    urls: ["REFRESHTOKEN"], method: "POST", data: {
                        userId: state?.auth?.userDetail?.userId,
                        refreshToken: state?.auth?.userDetail?.refreshtoken,
                    }
                    , onSuccess: (response) => {
                        const { userDetail } = state.auth;
                        userDetail.token = response.token
                        dispatch(actions.loginSuccess({ ...userDetail }));
                        error.config.headers.Authorization = `Bearer    ${response.token}`
                        onCateringError(error, dispatch, showNotification, onSuccess)
                    },
                }))
            }
            else if (status === 420) {
                dispatch(actions.logout())
                dispatch(Notifications.error({ title: "Error", message: error.response.data.responseMessage, position: 'br', autoDismiss: 5 }));
            }
            else if (error.response && showNotification) {
                onFailure(error.response.data);
                dispatch(Notifications.error({ title: "Error", message: error.response.data.responseMessage, position: 'br', autoDismiss: 5 }));
            }
            else if (error.response) {
                onFailure(error.response.data);
                // onFailure(error);
            }
        }
        else {
            // Show notification
            if (error.response && showNotification) {
                dispatch(Notifications.error({ title: "Error", message: error.response.data.responseMessage, position: 'br', autoDismiss: 5 }));
            }
            // Application level error handling 


            dispatch(actions.apiError(error));
            // callback function
            onFailure(error);
            // console error if environment is not in production
            if (env.NODE_ENV !== "production") {
                console.log('API : Error response :' + JSON.stringify(error));
            }
            if (error.response && error.response.status === 403) {
                dispatch(actions.accessDenied(window.location.pathname));
            }
        }
    }).finally(() => {
        // Application level loading end
        dispatch(actions.apiEnd());
        onFinally();
    });
};

export default apiMiddleware;
