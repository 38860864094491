import { Link } from "../../../../functional/global-import";
import { Editor } from "react-draft-wysiwyg";
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Grid, Dimmer, Loader, Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { apiCall } from "../../../../../store/actions/api.actions";
import { commonFunctions } from "../../../../functional/global-import";
import SimpleReactValidator from 'simple-react-validator';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddMessage = (props) => {
	const initialValues = {
		id: 0,
		title: '',
		body: '',
		isActive: true,
		users: []
	};
	const dispatch = useDispatch();

	const [message, setMessage] = useState(initialValues);
	const [users, setUsers] = useState([]);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
	
	const onHandleChanged = (e, { data, value, checked, type }) => {
		setMessage({ ...message, [data]:value });
	};

	const onEditorStateChange = (editorState) => {
        message.body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        setEditorState(editorState);
        setMessage(message);
    }

	let viewModal = props.viewModal;
    const api = useSelector((state) => state.api);

	useEffect(() => {
		if (props.modalType == "ADD") {
			if (props.setUsers && props.setUsers.length > 0)
				setMessage({ ...message, users : props.setUsers.map(x=> x.value) });
		}
		else if (props.modalType === "EDIT") {
			editMessageList();
		}
	}, [props.editMessage]);

	useEffect(() => {
        loadTeachers();
    }, []);

	const editMessageList = () => {
		const { id, title, body, isActive, users } = props.editMessage;
		setMessage({ ...message, id: id, body:body, isActive: isActive, title:title, users:users });
		setEditorState(isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(body))) : EditorState.createEmpty());
	};
	
	const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

	const closeModal = () => {
		setMessage(initialValues);
		setEditorState((EditorState.createEmpty()));
		simpleValidator.current.hideMessages();
		props.closeModal(false);
	}

	const onHandleRecipients = (e, { data, value }) => {
		if (value[value.length - 1]) {
		  setMessage({ ...message, [data]: value });
		} else if (value.length === 0) {
		  setMessage({ ...message, [data]: value });
		}
	};

	const onHandleSubmit = (e) => {
		const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
		if (isFormValid) {

			dispatch(
				apiCall({
					urls: ["ADDMESSAGE"],
					method: "POST",
					data: message,
					onSuccess: (response) => {
						closeModal();
						props.GridReload();
						setMessage(initialValues);
					}, actionOfMethod: message?.id > 0 ? "Edit" : "Add",
					showNotification: true
				})
			);
		}
	};

	const loadTeachers = () =>{
		dispatch(apiCall({
			urls: ["GETTEACHERSLIST"], method: "GET", data: { pageNo: 1, pageSize: 1000, schoolId:-1 }, onSuccess: (response) => {
				const teachers = response.map((teacherData) => {
					return { 
						value: teacherData.teacherId,
						text: `${teacherData.firstName} ${teacherData.lastName}`
					}
				});
				setUsers(teachers);
			}
		}))
	}

    return (
		
        <Modal centered size='fullscreen' closeOnDimmerClick={false} open={props.openModal} onClose={closeModal} className='addAleeCoachModal'>
			{viewModal
				? <Modal.Header>View Message</Modal.Header>
                : <Modal.Header>{message.id > 0 ? "Edit Message" : "Add Message"}</Modal.Header>
			}
            <Modal.Content scrolling>
                <Grid.Column width={16}>
                    {api.isApiLoading && commonFunctions.showLoader()}
                    <Form>
                        <Grid columns="equal">
                            <Grid.Column>
                                <Form.Input
                                    label="Title *"
                                    placeholder="Title"
                                    data="title"
                                    value={message.title}
                                    onChange={onHandleChanged}
									readOnly={viewModal}
                                    error={simpleValidator.current.message('Title', message.title, 'required|min:0|max:512')}/>
                            </Grid.Column>
							<Grid.Column>
								
								{ !viewModal ?
									<div className="field">
										<b>Recipients *</b>
										<Dropdown
											disabled={viewModal}
											className="errorDropdown"
											placeholder="Recipients"
											fluid
											multiple
											selection
											search
											onChange={onHandleRecipients}
											value={message.users}
											data="users"
											options={users}
											error={simpleValidator.current.message(
											"users",
											message.users,
											"required"
											)}
										/>
										{simpleValidator.current.message(
											"Recipients",
											message.users,
											"required"
										)}
									</div>
									: 
									<Form.Input
										label="Recipients *"
										placeholder="Recipients"
										value={message.users.map(x=> x.name).join(', ')}
										readOnly={true} />
									
								}

							</Grid.Column>
                        </Grid>

                        <Grid>

                            <Grid.Column width={16} >
                                <Editor
									readOnly={viewModal}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName  aleeCoachEditor"
                                    toolbar={{
                                        options: ["inline", "blockType", "fontSize", 'link', 'embedded', 'textAlign'],
                                        inline: {
                                            inDropdown: false,
                                            className: "test",
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ["bold", "italic", "underline"],
                                            bold: { className: "test", style: { color: "red" } },
                                            italic: { className: undefined },
                                            underline: { className: undefined },
                                        },
                                        embedded: {
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            embedCallback: undefined,
                                            defaultSize: {
                                                height: '300px',
                                                width: '550px',
                                            },
                                        },
                                    }}
                                    onEditorStateChange={onEditorStateChange} />
                            </Grid.Column>

                        </Grid>
                    </Form>
                </Grid.Column>

            </Modal.Content>
			<Modal.Actions>
				{viewModal ? <Button className="secondaryBtn" onClick={closeModal}>Cancel</Button> :
                    <>
                        <Button className="secondaryBtn" onClick={closeModal}>Cancel</Button>
                        <Button className="primaryBtn" onClick={onHandleSubmit} as={Link} to="message">
                            {message.id > 0 ? "Update" : "Confirm"}</Button>
                    </>
                }
			</Modal.Actions>
        </Modal>
    );
}
export default AddMessage;