import React from 'react'
import AleeCoachSummaryPage from './alee-coach-summary';
import { useLocation } from 'react-router-dom';

const AleeCoachSummary = (detail) => {
  const { state } = useLocation();
  return (
    <div><AleeCoachSummaryPage props={state} detail={detail} /></div>
  )
}

export default AleeCoachSummary