import React from 'react';
import ResetPassword from './reset-password';
import { useLocation } from 'react-router-dom';


const Reset = () => {
    const { state } = useLocation();
    return (
        <ResetPassword props={state} />
    );
}
export default Reset;
