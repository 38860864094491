import React, { useState, useEffect } from "react";
import { Header, Image } from 'semantic-ui-react';
import { Close } from "../../../functional/global-image-import";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { commonFunctions } from "../../../functional/global-import";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../store/actions";

const ChapterSummaryDetail = (props) => {
    
    const [resourcesLoader, setResourcesLoader] = useState(false);
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        getChapterSummary();
    }, []);

    const getChapterSummary = () => {
        
        setResourcesLoader(true);
        dispatch(
            apiCall({
                urls: ["GETCHAPTERSUMMARY"],
                method: "GET",
                data: { chapterId: props.chapterData.ChapterId },
                onSuccess: (response) => {
                                              
                    setEditorState(isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.chapterSummary))) : []);
                }, onFinally: () => {
                    setResourcesLoader(false)
                },
            })
        );
    };

    return (
        <>
            <div className={`bookSummary`}>
                <div className='bookSummaryContent'>
                    <Header as="h3">{props.chapterData.ChapterName}</Header>
                    <div className='closeSummary' onClick={props.handleToggle} ><Image src={Close} /></div>
                    <div className='summaryDetails'>
                        {resourcesLoader && commonFunctions.showLoader()}
                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChapterSummaryDetail;
