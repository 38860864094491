import React from 'react';
import { Table, Icon } from "semantic-ui-react";

function TableHeader(props) {

    return (
        <Table.Header>
            <Table.Row>
                {props.columns.map((singleData, index) => {
                    let isPointer = singleData.isSorting
                    // let headerClass = singleData.headerName === "Action" ? "actionHeader" : singleData.headerCellClassName
                    let headerClass =   singleData.headerCellClassName
                    return (
                        <>
                            <Table.HeaderCell
                                className={headerClass === undefined ?
                                    isPointer ? "pointer" : ""
                                    : isPointer ? `pointer ${headerClass}` : `${headerClass}`
                                }
                                key={index} onClick={() => { singleData.isSorting && props.onHandleSorting(singleData.fieldName) }}>

                                {singleData.headerName}

                                {singleData.isSorting &&
                                    <Icon id={index} name={singleData.fieldName === props.gridObjects.heading ? props.gridObjects.sortArrow : "sort"}
                                        className="primary-color" />
                                }
                            </Table.HeaderCell>
                        </>
                    )
                }
                )}
            </Table.Row>
        </Table.Header>
    );
};

export default TableHeader;