import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, Button, Form, Dimmer, Loader } from "semantic-ui-react";
import { apiCall } from "../../../../../store/actions/api.actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/global-import";

const AddLessonPlan = (props) => {
    useEffect(() => {
        if (props?.editLessonDetails?.LessonPlanName) {
            setLessonPlanName(props?.editLessonDetails?.LessonPlanName)
            setLessonPlanId(props?.editLessonDetails?.LessonPlanId)
        }
    }, [props.editLessonDetails?.LessonPlanName]);
    const teacherId = useSelector(state => state.auth.userDetail.teacherId)
    const userId = useSelector(state => state.auth.userDetail.userId)
    const [lessonPlanName, setLessonPlanName] = useState("");
    const [lessonPlanNameId, setLessonPlanId] = useState(null);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))

    const api = useSelector((state) => state.api);
    const dispatch = useDispatch();

    const onHandleChange = (e, { value }) => {
        setLessonPlanName(value)
    };

    const onDuplicateLesson = (e) => {
        dispatch(apiCall({
            urls: ["ADDDUPLICATELESSONPLAN"], method: "POST",
            data: {
                userId: userId,
                lessonPlanId: lessonPlanNameId,
                teacherId: teacherId,
                // lessonPlanName: lessonPlanName
                lessonPlanName: lessonPlanName.charAt(0).toUpperCase() + lessonPlanName.slice(1)
            },
            onSuccess: (response) => {

                let lessonObj = {
                    LessonPlanId: response.id,
                    LessonPlanName: response.lessonPlanName
                }
                props?.setLessonDetails?.({})
                props?.setIsToShowNewBlock?.(false)
                props?.onLessonClick(lessonObj)

                props?.onSaveLessonPlan(response, false, true)
                closeModal()
            }, showNotification: true,
        }))
    }
    const onHandleSubmit = (e) => {
        
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            dispatch(
                apiCall({
                    urls: ["ADDLESSONPLANDATA"],
                    method: "POST",
                    data: {
                        userId: userId,
                        lessonPlanId: lessonPlanNameId,
                        teacherId: teacherId,
                        bookId: props.bookId,
                        chapterId: null,
                        // lessonPlanName: lessonPlanName
                        lessonPlanName: lessonPlanName.charAt(0).toUpperCase() + lessonPlanName.slice(1),
                    },
                    onSuccess: (response) => {


                        props?.setLessonDetails?.({})
                        props?.setIsToShowNewBlock?.(false)
                        let boleanValue = props?.editLessonDetails?.LessonPlanId === undefined ? false : true
                        props?.setOpenLessonBlockModal?.(false)

                        props.onSaveLessonPlan(response, true, boleanValue)
                        closeModal();
                    },
                    showNotification: true,
                })
            );
        }
    };

    const closeModal = () => {
        simpleValidator.current.hideMessages();
        props?.setEditLessonDetails?.({})
        props?.setFullState?.({ ...props?.fullState, isDuplicate: false })
        props.closeModal();
    };

    const handleKeyPress = (e) => {
        
        if (e.key === 'Enter') {
            onHandleSubmit(e)
        }
    }

    return (
        <Modal 
            open={props.openModal}
            onClose={props.closeModal}
            closeOnDimmerClick={false}
            size="small">
            {api.isApiLoading && (
                <Dimmer active inverted>
                    <Loader />
                </Dimmer>
            )}
            <Modal.Header>
                {props.isDuplicate ? "Duplicate Lesson Plan" :
                    props?.editLessonDetails?.LessonPlanId === undefined ?
                        "Add Lesson Name" :
                        "Edit Lesson Name"}
            </Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <Form>
                        <Grid>
                            <Grid.Column>
                                <Form.Input
                                    label="Lesson Name"
                                    data="lessonPlanName"
                                    value={lessonPlanName}
                                    onChange={onHandleChange}
                                    onKeyPress={handleKeyPress}
                                    error={simpleValidator.current.message('lessonPlanName', lessonPlanName, 'required')}
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className="secondaryBtn"
                    onClick={() => closeModal()}>
                    {" "}
                    Cancel{" "}
                </Button>
                {props?.isDuplicate ?
                    <Button
                        className="primaryBtn"
                        onClick={onDuplicateLesson}
                        loading={api.isApiLoading}>
                        Save
                    </Button>
                    :
                    <Button
                        className="primaryBtn"
                        onClick={onHandleSubmit}
                        loading={api.isApiLoading}>
                        Save
                    </Button>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default AddLessonPlan;