import React, { useState, useEffect } from "react";
import { Button, Header, Image } from 'semantic-ui-react'
import { WhiteLogo } from "../../shared/functional/global-image-import"
import { useDispatch } from 'react-redux';
import { apiCall } from "../../store/actions/api.actions";
import { commonFunctions } from "../../shared/functional/common-functions";
import { Link } from "../../shared/functional/global-import";

const VerifyMeassge = () => {
    const [message, setMessage] = useState("");
    const [isverified, setIsverified] = useState(null);
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();
    const [verifyResponse, setVerifyResponse] = useState();


    useEffect(() => {
        veryfySignUpToken();
    }, []);

    const veryfySignUpToken = () => {
        let refreshToken = window.location.search.replaceAll('?Token=', "");
        dispatch(apiCall({
            urls: ["VERIFYSIGNUPTOKEN"], method: "POST", data: {
                refreshToken: refreshToken,
                isEmailVerified: true
            },
            onSuccess: (response) => {
                window.location.href = `/reset-password?Token=${response?.resetPasswordToken}&emailId=${response?.email}`;
            },
            onFinally: () => {
                setLoader(false)
            }, onFailure: (error) => {
                setMessage(error?.responseMessage)
                setIsverified(false)
            }
        }));
    }

    return (
        <div className='signUpWrapper'>
            <div className='signUpNew'>
                <Image className='logo' src={WhiteLogo} />
                <div className='signUpNewInner verifyEmail'>
                    {loader && commonFunctions.showLoader()}
                    {!loader &&
                        <>
                            <Header as="h2">{message}</Header>
                            <br /><br />
                            { verifyResponse?.resetPasswordToken != null &&
                            <div>
                                <Button className="primaryBtn" as={Link} to={`/reset-password?Token=${verifyResponse?.resetPasswordToken}&emailId=${verifyResponse?.email}`}>Continue</Button>
                            </div>
                            }
                        </>
                    }
                </div>
                <div className='alreadyAccount'>For any questions, please email <a className="mailFontColor" href={`mailto: info@myalee.com`}> info@myalee.com</a></div>

            </div>
        </div >
    )
}

export default VerifyMeassge