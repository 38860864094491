export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const IMG_URL = process.env.REACT_APP_IMG_URL;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY;
export const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;
export const ENABLE_PENDO = process.env.REACT_APP_ENABLE_PENDO;
export const ENABLE_GTM = process.env.REACT_APP_ENABLE_GTM;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const PAYMENT_BUTTON_ANNUALY = process.env.REACT_APP_PAYMENT_BUTTON_ANNUALY;
export const PAYMENT_BUTTON_MONTHLY = process.env.REACT_APP_PAYMENT_BUTTON_MONTHLY;