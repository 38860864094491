import React, { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { NoTemplateRoutesConfing, AuthenticateTemplateRoutesConfing } from "./config/routes";
import PrivateRoute from "./shared/functional/private-route";
import 'semantic-ui-css/semantic.min.css';
import { NotificationAlert, AuthenticateUserTemplate } from './shared/components';
import AppContainer from './app-container';
import { Loader } from 'semantic-ui-react';
import { Redirect } from "react-router-dom";
import { env } from "./shared/functional/global-import";
import { logout } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';

function App(props) {
  const auth = useSelector(state => state.auth);
  const userRole = auth?.userDetail?.role;
  const r = /^complete-your-payment$/;
  const pathName = window.location.pathname.split("/")[1]
  const dispatch = useDispatch();

  useEffect(() => {
    let noTempleteRoute = NoTemplateRoutesConfing.filter((singleObj) => {
      return singleObj.path.split("/")[1] === pathName
    })
    noTempleteRoute.length === 0 && auth.userDetail?.roleAndPermission === undefined && dispatch(logout());
    noTempleteRoute.length === 0 && auth.userDetail?.roleAndPermission?.roleAndPermission === null && dispatch(logout());
  }, []);

  const roleAndPermission = auth?.userDetail?.roleAndPermission;

  let roleBasedFilteredRoutes = userRole && AuthenticateTemplateRoutesConfing?.filter((singleRoute) => singleRoute.role.includes(userRole))

  let rolepermission = userRole && userRole === "SubAdmin" ?
    roleBasedFilteredRoutes.filter((permission) => {
      let data = roleAndPermission && roleAndPermission.length > 0 && roleAndPermission?.filter((item) => {
        return item.Access === 1 && item.ModuleName?.toLowerCase() === permission.moduleName?.toLowerCase()
      });
      if (data?.length > 0 && data[0].permission !== null) {
        if (data[0].permission.filter((item) =>
          item.Access === 1 && item.ScreenName.toLowerCase() === permission.screenName?.toLowerCase()).length > 0) {
          return permission
        }
      }
    }
    )
    :
    roleBasedFilteredRoutes

  return (
    <div className="app">

      <Router>
        <AppContainer>
          <Suspense fallback={<></>}>
            <Switch>
              {
                NoTemplateRoutesConfing.map((route, i) => {
                  const Component = route.component;
                  return route.private ?
                    // handle private routes of the application   userTypes={route.userTypes}
                    <PrivateRoute key={i} exact={route.exact} path={route.path} render={(props) => <Component {...props} />} /> :
                    // handle public routes of the application
                    <Route key={i} exact={route.exact} path={route.path} render={(props) => <Component {...props} />} />
                })}
              {!r.test(pathName) &&
                <AuthenticateUserTemplate>
                  <Suspense fallback={<Loader active />}>
                    <Switch>
                      {/* {userRole && rolepermission?.filter((singleRoute) => singleRoute.role.includes(userRole)).map((route, i) => { */}

                      {userRole && rolepermission && rolepermission.length > 0 && rolepermission.map((route, i) => {
                        const Component = route.component;
                        return route.private ?
                          // handle private routes of the application   userTypes={route.userTypes}
                          <PrivateRoute key={i} exact={route.exact} path={route.path} render={(props) => <Component {...props} />} />
                          :
                          // handle public routes of the application
                          <Route key={i} exact={route.exact} path={route.path} render={(props) => <Component {...props} />} />
                      })
                      }
                      <Route
                        render={(props) => {
                          return <Redirect to={`${env.PUBLIC_URL}/`} />
                        }
                        }
                      />
                    </Switch>
                  </Suspense>
                </AuthenticateUserTemplate>
              }

              {/* </Route>  */}
            </Switch>
          </Suspense>
        </AppContainer>
      </Router>
      <NotificationAlert></NotificationAlert>

    </div >
  );

}

export default App;
