import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Header, Icon, Search} from 'semantic-ui-react';
import { env, commonFunctions } from "../../shared/functional/global-import";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../store/actions/api.actions";

const AleeCoachSummaryPage = (props) => {
    const dispatch = useDispatch();
    const [aleeCoachSummary, setAleeCoachSummary] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStated, setEditorStated] = useState(EditorState.createEmpty());
    const api = useSelector((state) => state.api);
    useEffect(() => {
        getAleeCoach();
    }, []);
    const getAleeCoach = () => {
        dispatch(
            apiCall({
                urls: ["GETALEECOACH"],
                method: "GET",
                data: { AleeCoachId: props?.props?.aleeCoach === undefined ? props?.detail?.aleeCoach : props?.props?.aleeCoach },
                onSuccess: (response) => {
                    response.detailDescription === "" ? setEditorStated(commonFunctions.isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.detailDescription1))) : [])
                        : setEditorState(commonFunctions.isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.detailDescription))) : [])
                    response.detailDescription1 === "" ? setEditorState(commonFunctions.isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.detailDescription))) : [])
                        : setEditorStated(commonFunctions.isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.detailDescription1))) : [])
                    setAleeCoachSummary(response)},
            })
        );
    };
    const onSearchClick = (e) => {
        window.find(searchValue);
        return true;
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearchClick()}}
    const onSearchHandleChange = (e, { value }) => {
        setSearchValue(value)
    }
    return (
        <div className="aleeCoachPage">
            {api.isApiLoading && commonFunctions.showLoader()}
            <Grid className="aleeLibrary">
                <Grid.Column width={8} verticalAlign="middle">
                    <Header as="h3" className="liberaryHeading">ALEE Coach</Header>
                </Grid.Column>
                <Grid.Column width={6} textAlign="right">
                    <Search showNoResults={false} className="searchInput" onSearchChange={onSearchHandleChange}
                        value={searchValue} onKeyPress={handleKeyPress}
                        placeholder="Search Coaching" input={{ icon: 'search', iconPosition: 'left' }} fluid />
                    {/* <Button className="iconSearch" icon="search" onClick={onSearchClick}></Button> */}
                </Grid.Column>
                <Grid.Row id="aleeCoach">
                    <Grid.Column width={16}>
                        <div className='backtoAlee'>
                            {props?.props?.aleeCoach === undefined ?
                                <span className='blue-color pointer' onClick={() => props?.detail?.close()}><Icon name='arrow left' />Back</span>:
                                <Link to={`${env.PUBLIC_URL}/alee-coach`}>
                                    <Icon name='arrow left' />
                                    Back to ALEE Coach</Link>
                            }
                        </div>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Header as="h4" className="liberaryHeading titleHead">
                            {aleeCoachSummary.aleeCoachName}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <pre className='summaryText left' dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}>
                        </pre>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <pre className='summaryText right' dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorStated.getCurrentContent())) }}></pre>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div >
    )
}
export default AleeCoachSummaryPage