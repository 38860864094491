// import React, { useState, useRef, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { Header, Image } from "semantic-ui-react";
import { WhiteLogo } from "../../shared/functional/global-image-import";
import { useDispatch } from "react-redux";
import { apiCall } from "../../store/actions/api.actions";

const ResendEmailPage = (props) => {
  const dispatch = useDispatch();

  const onResendEmail = () => {
    dispatch(
      apiCall({
        urls: ["RESENDEMAIL"],
        method: "POST",
        data: {
          emailId: props?.props?.email,
          firstName: props?.props?.firstName,
        },
        onSuccess: (response) => {},
        showNotification: true,
      })
    );
  };

  return (
    <div className="signUpWrapper">
      <div className="signUpNew">
        <Image className="logo" src={WhiteLogo} />
        <div className="signUpNewInner verifyEmail">
          <Header as="h2">Please verify your email</Header>
          <p>
            Once you verify your email address, you can get started.
            <br />
            Didn't receive an email?{" "}Resend
            <span onClick={onResendEmail}> email</span>
          </p>
        </div>
        <div className="alreadyAccount">
          By signing up, I agree to the <a href="https://myalee.com/privacy-policy/" target="_blank">ALEE Privacy Policy</a>{" "}
          and <a href="https://myalee.com/terms-of-service/" target="_blank">Terms of Service</a>. Already have an account?{" "}
          <Link to="/">Log in</Link>
        </div>
      </div>
    </div>
  );
};

export default ResendEmailPage;
