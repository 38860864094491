import React, { useState, useEffect } from "react";
import { Header, Image } from 'semantic-ui-react';
import { Close } from "../../../functional/global-image-import";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { commonFunctions } from "../../../functional/global-import";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../store/actions";
import { EditorState, convertFromRaw } from 'draft-js';

const BookSummaryDetailPage = (props) => {
                              
    const [resourcesLoader, setResourcesLoader] = useState(false);
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        getBookSummary();
    }, []);

    const getBookSummary = () => {
        setResourcesLoader(true);
        dispatch(
            apiCall({
                urls: ["GETBOOKSUMMARY"],
                method: "GET",
                data: { bookId: props.bookId },
                onSuccess: (response) => {
                    setEditorState(isJsonString ? EditorState.createWithContent(convertFromRaw(JSON.parse(response.bookSummary))) : []);
                }, onFinally: () => {
                    setResourcesLoader(false)
                },
            })
        );
    };

    return (
        <>
            <div className={`bookSummary`}>
                <div className='bookSummaryContent'>
                    <Header as="h3">Book Summary</Header>
                    <div className='closeSummary' onClick={props.bookSummay}><Image src={Close} /></div>
                    <div className='summaryDetails'>
                        {props.resourcesLoader && commonFunctions.showLoader()}
                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookSummaryDetailPage;