import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Header, Image } from 'semantic-ui-react'
import { BlueLogo } from '../../shared/functional/global-image-import'
import { apiCall, logout } from '../../store/actions';
import moment from 'moment';
import {  commonFunctions } from "../../shared/functional/global-import";

const PaymentMessagePage = (props) => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  let token = new URLSearchParams(search).get('Token');
  let email = new URLSearchParams(search).get('emailId');
  let userSubscriptionId = new URLSearchParams(search).get('userSusbcriptionId');
  const [isverified, setIsverified] = useState(null);
  const api = useSelector(state => state.api);
  const [message, setMessage] = useState("");

  useDispatch(logout());

  useEffect(() => {
    onCheckPayment()
  }, []);

  const onCheckPayment = () => {
    
    let currentDate = moment(new Date()).format("YYYY-MM-DD");

    dispatch(apiCall({
      urls: ["ADDEMAILRESPONSE"], method: "POST", data: {
        userSubscriptionId: parseInt(userSubscriptionId),
        willContinue: false,
        emailId: email,
        token: token,
        emailResponseDate: currentDate
      },
      onSuccess: (response) => {
        setMessage(response?.responseMessage)
        setIsverified(true)
      }, onFailure: (error) => {
        setMessage(error?.responseMessage)
        setIsverified(false)
      },
    }));
  }


  return (
    <div className="login">
      <div className="loginInner">
        <div className='loginInnerBox'>
          <Image className='logo' src={BlueLogo} />
          {api.isApiLoading && commonFunctions.showLoader()}
          <Header as="h4">{message}</Header>
        </div>
        <div className='notAccount'>For any questions, please email<a className="mailFontColor" href={`mailto: info@myalee.com`}> info@myalee.com</a></div>
      </div>
    </div>
  )
}

export default PaymentMessagePage