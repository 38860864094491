import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Form, Grid, Header, Modal, Input } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { DateInput } from "semantic-ui-calendar-react";
import { useDispatch } from 'react-redux';
import { commonFunctions, Notifications } from "../../../../functional/global-import";
import { apiCall } from "../../../../../store/actions/api.actions";
import { PhoneNumberInput } from "../../../../components";
import moment from 'moment';

function AddDistrictModal(props) {
    const initivalue = ({
        districtId: null,
        districtName: null,
        numberOfSchool: null,
        adminName: null,
        adminEmail: null,
        adminPhoneNumber: null,
        accountManagerName: null,
        accountManagerEmail: null,
        accountManagerPhoneNumber: null,
        districtPrice: null,
        paymentTerms: null,
        accountExpiration: null,
    });
    const [districtDetail, setDistrictDetail] = useState(initivalue);
    const [districtLoader, setDistrictLoader] = useState(false);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const onClose = () => { props.closeModal(false) }
    const onHandleChange = (e, { data, value }) => {
        setDistrictDetail({ ...districtDetail, [data]: value })
    };

    const dispatch = useDispatch();
    let districtDisable = props.viewModal

    const onHandleSubmit = (e) => {
        var selectedDate = new Date(districtDetail.expiryDate);
        var today = new Date();
        let newFormatedDate = districtDetail.expiryDate && commonFunctions.saveFormattedDate(districtDetail.expiryDate);
        let accountExpirationString = newFormatedDate === "" ? null : newFormatedDate;
        districtDetail.accountExpiration = accountExpirationString;
        let newTitle = districtDetail.districtName === null ? null : districtDetail.districtName.charAt(0).toUpperCase() + districtDetail.districtName.slice(1);
        districtDetail.districtName = newTitle
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            // if (today > selectedDate) {
            //     dispatch(
            //         Notifications.show(
            //             {
            //                 title: "Error",
            //                 message: "Can not select expiration date which is passed away.",
            //                 position: "br",
            //                 autoDismiss: 2,
            //             },
            //             "error"
            //         )
            //     );
            //     return true;
            // }
            setDistrictLoader(true);
            dispatch(apiCall({
                urls: ["ADDUPDATEDISTRICT"], method: "POST", data: districtDetail,
                onSuccess: (response) => {
                    props.closeModal(false);
                    setDistrictDetail(initivalue);
                    props.GridReload();
                    simpleValidator.current.hideMessages();
                }, onFinally: () => {setDistrictLoader(false)},
                 actionOfMethod: districtDetail?.districtId > 0 ? "Edit" : "Add",
                showNotification: true
            }))
        }
    }


    useEffect(() => {
        if (props.modalType === "EDIT") {
            editDistrictDetail()
        }
    }, [props.openModal]);

    const editDistrictDetail = () => {
        if (props.editData) {
            const { districtId, districtName, adminName, adminEmail, adminPhoneNumber, accountManagerName, accountManagerEmail,
                accountManagerPhoneNumber, districtPrice, paymentTerms, accountExpiration, numberOfSchool } = props.editData;
            setDistrictDetail({
                ...districtDetail,
                districtId: districtId, districtName: districtName,
                adminName: adminName,
                adminEmail: adminEmail,
                adminPhoneNumber: adminPhoneNumber,
                accountManagerName: accountManagerName,
                accountManagerEmail: accountManagerEmail,
                accountManagerPhoneNumber: accountManagerPhoneNumber,
                districtPrice: districtPrice,
                paymentTerms: paymentTerms,
                expiryDate: accountExpiration === null ? null : commonFunctions.formattedDate(accountExpiration),
                numberOfSchool: numberOfSchool
            });
        }
    }
    const onHandleChanged = (e, { value, type, checked, data }) => {
        var Forms = commonFunctions.onHandleChange(e,
            { value, type, checked, data }, districtDetail);
        setDistrictDetail(Forms);
    }
    const onHandlePriceChange = (e, { value, data }) => {
        let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
        if (e.target.value.match(re)) {
            setDistrictDetail({ ...districtDetail, [data]: e.target.value })
        }
        else {
            setDistrictDetail({ ...districtDetail, [data]: "" })
        }
    }
    const onHandleChangeDate = (event, { name, value, data }) => {
                                                                   
       
                setDistrictDetail({ ...districtDetail, [data]: value });
           
        
    }
    const priceValidationError = simpleValidator.current.message("Price", districtDetail.districtPrice, "required");
                                                                  
    return (
        <Modal open={props.openModal} onClose={props.closeModal} closeOnDimmerClick={false}>
            {districtDisable ? <Modal.Header>View District</Modal.Header> :
                <Modal.Header>{districtDetail.districtId ? "Edit District" : "Add District"}</Modal.Header>}
            <Modal.Content>
                <Modal.Description>
                    <div className="common-shadow">
                        {districtLoader && commonFunctions.showLoader()}
                        <Form>
                            <Grid>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Form.Input
                                            className="opacity-1"
                                            placeholder="District name"
                                            label="District name *"
                                            data="districtName"
                                            disabled={districtDisable}
                                            value={districtDetail.districtName}
                                            onChange={onHandleChange}
                                            error={simpleValidator.current.message('districtName', districtDetail.districtName, 'required')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Input
                                            className="opacity-1"
                                            placeholder="Number of school"
                                            label="Number of school *"
                                            data="numberOfSchool"
                                            disabled={districtDisable}
                                            value={districtDetail.numberOfSchool}
                                            onChange={onHandlePriceChange}
                                            error={simpleValidator.current.message('numberOfSchool', districtDetail.numberOfSchool, 'required')}

                                        />

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Column width={16}>
                                    <Header as="h4">District Account Manager Detail</Header>
                                </Grid.Column>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Form.Input
                                            className="opacity-1"
                                            placeholder="Name"
                                            label=" Name *"
                                            data="accountManagerName"
                                            value={districtDetail.accountManagerName}
                                            onChange={onHandleChange}
                                            disabled={districtDisable}
                                            error={simpleValidator.current.message('accountName', districtDetail.accountManagerName, 'required')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Input
                                            className="opacity-1"
                                            placeholder="Email"
                                            label="Email *"
                                            disabled={districtDetail.districtId ? true : false}
                                            data="accountManagerEmail"
                                            // disabled={districtDisable}
                                            value={districtDetail.accountManagerEmail}
                                            onChange={onHandleChange}
                                            error={simpleValidator.current.message('accountEmail', districtDetail.accountManagerEmail, 'required|email')} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="customPhoneInput">
                                            <PhoneNumberInput
                                                onChange={(value, country, e, formattedValue) => onHandleChanged(e, {
                                                    name: "phoneNumber", value: formattedValue, type: "phoneNumber", data: "accountManagerPhoneNumber"})}
                                                value={districtDetail.accountManagerPhoneNumber}
                                                disabled={districtDisable}
                                                error={simpleValidator.current.message(`PhoneNumber`, districtDetail.accountManagerPhoneNumber, 'required|phone')}/>
                                            <label>Phone number *</label>
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Column width={16}>
                                    <Divider fitted />
                                    <Header as="h4">District Admin Detail</Header>
                                </Grid.Column>
                                <Grid.Row columns={3}>
                                    <Grid.Column>
                                        <Form.Input
                                            placeholder="Admin name"
                                            label=" Name *"
                                            data="adminName"
                                            disabled={districtDisable}
                                            value={districtDetail.adminName}
                                            onChange={onHandleChange}
                                            error={simpleValidator.current.message('adminName', districtDetail.adminName, 'required')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column >
                                        <Form.Input
                                            className="opacity-1"
                                            placeholder="Email"
                                            label="Email *"
                                            data="adminEmail"
                                            disabled={districtDetail.districtId}
                                            value={districtDetail.adminEmail}
                                            onChange={onHandleChange}
                                            error={simpleValidator.current.message('adminEmail', districtDetail.adminEmail, 'required|email')}

                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="customPhoneInput">

                                            <PhoneNumberInput
                                                onChange={(value, country, e, formattedValue) => onHandleChanged(e, {
                                                    name: "phoneNumber", value: formattedValue, type: "phoneNumber", data: "adminPhoneNumber"
                                                })}
                                                value={districtDetail.adminPhoneNumber}
                                                disabled={districtDisable}
                                                error={simpleValidator.current.message('PhoneNumber', districtDetail.adminPhoneNumber, 'required|phone')} />
                                            <label>Phone number *</label>
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row columns={3}>
                                    <Grid.Column >
                                        <div className={!priceValidationError ? "customPhoneInputField" : "customPhoneInputField error"}>
                                            <label>Price per student  *</label>
                                            <Input
                                                placeholder="Price per student" label={{ basic: true, content: '$' }} labelPosition='left'
                                                data="districtPrice"
                                                disabled={districtDisable}
                                                value={districtDetail.districtPrice}
                                                onChange={onHandlePriceChange}
                                            />
                                            {simpleValidator.current.message('Price', districtDetail.districtPrice, 'required')}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column >
                                        <Form.Input
                                            placeholder="Payment terms or duration"
                                            label="Payment terms or duration *"
                                            data="paymentTerms"
                                            disabled={districtDisable}
                                            value={districtDetail.paymentTerms}
                                            onChange={onHandleChange}
                                            error={simpleValidator.current.message('payment', districtDetail.paymentTerms, 'required')}
                                        />
                                    </Grid.Column>
                                    <Grid.Column >
                                        <Form.Field>
                                            <DateInput
                                                minDate={districtDetail.districtId ? false : moment().toDate()}
                                                disabled={districtDisable}
                                                placeholder="Expiration Date"
                                                label="Expiration date *"
                                                name="txtStartDate"
                                                dateFormat="MMM-DD-YYYY"
                                                data="expiryDate"
                                                value={districtDetail.expiryDate}
                                                iconPosition="right"
                                                onChange={onHandleChangeDate}
                                                closable={true}
                                                fluid
                                                error={simpleValidator.current.message('Expiration', districtDetail.expiryDate, 'required')}/>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {districtDisable ? <Button className="secondaryBtn" onClick={onClose} >Cancel</Button> :
                    <>
                        <Button className="secondaryBtn" onClick={onClose} >Cancel</Button>
                        <Button className="primaryBtn" onClick={onHandleSubmit} >{districtDetail.districtId ? "Update" : "Save"}</Button>
                    </>}
            </Modal.Actions>
        </Modal>
    );
}

export default AddDistrictModal;