import React, { useState, useEffect } from "react";
import { Modal, Button, Header } from 'semantic-ui-react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { apiCall } from "../../../../../store/actions";
import { useSelector, useDispatch } from "react-redux";

import draftToHtml from 'draftjs-to-html';

function ShowMessage({ reload }) {
	const [message, setMessage] = useState(null);
	const [messages, setMessages] = useState([]);
	const [openModal, setOpenModal] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const dispatch = useDispatch();
    
	const loadMessages = () =>{
		dispatch(apiCall({
			urls: ["MYMESSAGES"], method: "GET",
            onSuccess: (response) => {
                setMessages(response);
			}
		}))
	}

    const loadFirst = ()=> {
        if (messages.length > 0) {
            setOpenModal(true);
            setMessage(messages[0]);
            setEditorState(isJsonString 
                ? EditorState.createWithContent(convertFromRaw(JSON.parse(messages[0].body))) 
                : EditorState.createEmpty());
        }
        else{
            setOpenModal(false);
            setMessage(null);
        }
    };

    const onClose = ()=> {
		dispatch(apiCall({
			urls: ["MARKMESSAGEASREAD"], method: "PATCH", data : { id: message.id },
            onSuccess: (response) => {
                messages.shift();
                setMessages(messages);
                loadFirst();
			}
		}));
    };

    useEffect(()=>{
        loadFirst();
    }, [messages]);

    useEffect(() => {
        loadMessages();
    }, [reload]);

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <>
            { openModal ?
            <Modal open={openModal}>
                <Modal.Content>
                    <Header as="h3">{message?.title}</Header>
                    <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></div>
                </Modal.Content>
                <Modal.Actions>
                    <Button className='primaryBtn' onClick={onClose}>{messages.length > 1 ? "Next Message" : "Close"}</Button>
                </Modal.Actions>
            </Modal>
            : <></>
            }
        </>
    )
}
export default ShowMessage;