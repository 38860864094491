import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Form, Grid, Header, Popup, Modal, Input, Checkbox } from "semantic-ui-react";
import { apiCall } from "../../../../../store/actions/api.actions";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { commonFunctions, Notifications, } from "../../../../functional/global-import";
import { DateInput } from "semantic-ui-calendar-react";
import { PhoneNumberInput } from "../../../../components";
import moment from "moment";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import DatePicker from "react-datepicker";
function AddSchoolPage(props) {
  const dispatch = useDispatch();
  const [grade, setGradeList] = useState([]);
  const [schoolLoader, setSchoolLoader] = useState(false);
  const [onPhoneChanged, setOnPhoneChanged] = useState(false);
  let schoolDisable = props.viewModal
  const today = new Date()
  const initialValues = {
    schoolId: null,
    schoolName: null,
    schoolEmail: null,
    districtId: null,
    numberofSeatsForLicense: null,
    numberOfStudentsforLicense: null,
    numberOfStudents: null,
    grade: [],
    isAdminAdded: true,
    adminDetails: [{ adminName: null, adminEmail: null, adminPhoneNumber: null, adminHiddenValue: false }],
    numberOfAdmin: null,
    accountManagerName: null,
    accountManagerEmail: null,
    accountManagerPhoneNumber: null,
    schoolAddress: null,
    districtAndNetworkName: null,
    notes: null,
    studentPercentageIEPs: null,
    studentPercentageENL: null,
    studentPriceNegotiated: null,
    studentsFreeAndReducedLunch: null,
    totalPricePerYear: null,
    paymentTerms: null,
    expiryDate: null,
  };
  const [schoolForm, setSchoolForm] = useState(initialValues);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  useEffect(() => {
    getGradeList();
  }, []);
  const getGradeList = () => {
    dispatch(
      apiCall({
        urls: ["GETGRADESLIST"],
        method: "GET",
        data: {
          ActiveGrades: true,
          OrderBy: "GradeName",
          isActive: true,
          OrderByDescending: false,
          pageNo: 1,
          pageSize: 1000,
        },
        onSuccess: (response) => {
          const grade = [{ text: "Select Grade", value: null, disable: true }];
          response.map((singledata, index) => {
            grade.push({
              text: singledata.gradeName,
              value: singledata.gradeId,
            });
            return { ...singledata };
          });
          setGradeList(grade);
        },
      })
    );
  };
  const onHandleChange = (e, { value, data }) => {
    setSchoolForm({ ...schoolForm, [data]: value });
  };

  const onHandleChangeDate = (event, { value, data }) => {
    if (moment(value, 'MMM-DD-YYYY', true).isValid()) {
      setSchoolForm({ ...schoolForm, [data]: value });
    }
  }
  const onHandleGrade = (e, { data, value }) => {
    if (value[value.length - 1]) {
      setSchoolForm({ ...schoolForm, [data]: value });
    } else if (value.length === 0) {
      setSchoolForm({ ...schoolForm, [data]: value });
    }
  };
  const handleAddRow = () => {
    const addRow = {
      adminName: null,
      adminEmail: null,
      adminPhoneNumber: null,
      adminHiddenValue: false
    };
    const { adminDetails } = schoolForm;
    let addValue = adminDetails.concat(addRow);
    setSchoolForm({ ...schoolForm, adminDetails: addValue })
  };

  const initializeSimpleValidator = () => {
    return new SimpleReactValidator({
      validators: {
        validConfirmPassword: {
          message: "The Password and Confirm Password doesn't match.",
          rule: (val, params, validator) => {
            if (params[0] !== "undefined" && params[0].length > 1) {
              return val === params[0];
            }
          },
          required: true, // optional
        },
      },
    });
  };

  const handleRemoveSpecificRow = (index) => {
    if (schoolForm.adminDetails.length > 1) {
      const adminDetails = schoolForm.adminDetails;
      adminDetails.splice(index, 1);
      setSchoolForm({ ...schoolForm, adminDetails });
    }
  };
  const onChangeAdmin = (e, { data, value, index }) => {
    const adminName = [...schoolForm.adminDetails];
    adminName[index][data] = value;
    setSchoolForm({ ...schoolForm });
  };
  const onHandleChanged = (e, { value, type, checked, data }) => {
    var Forms = commonFunctions.onHandleChange(e, { value, type, checked, data }, schoolForm);
    setSchoolForm(Forms);
  };
  const onHandlePrice = (e, { value, type, checked, data, index }) => {
    setOnPhoneChanged(true)
    const adminPhoneNumber = [...schoolForm.adminDetails];
    adminPhoneNumber[index][data] = value;
    setSchoolForm({ ...schoolForm });
  };
  const onHandleSubmit = (e) => {


    var selectedDate = new Date(schoolForm.expiryDate);
    var today = new Date();
    let gradeSelected = [];
    schoolForm.grade &&
      schoolForm.grade.map((singleObj) => {
        gradeSelected.push({
          gradeId: singleObj
        });
        return { ...singleObj }
      });
    let newFormatedDate = schoolForm.expiryDate && commonFunctions.saveFormattedDate(schoolForm.expiryDate);

    let accountExpirationString = newFormatedDate === "" ? null : newFormatedDate;
    let newTitle = schoolForm.schoolName === null ? null : schoolForm.schoolName.charAt(0).toUpperCase() + schoolForm.schoolName.slice(1);
    schoolForm.schoolName = newTitle;
    schoolForm.grades = gradeSelected;
    schoolForm.accountExpiration = accountExpirationString;
    schoolForm.expiryDate = null
    const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
    if (isFormValid) {

      const isAdminTrue = !schoolForm.isAdminAdded ? [] : schoolForm.adminDetails
      schoolForm.adminDetails = isAdminTrue;
      let isEmailMatched = false;
      schoolForm.adminDetails.map((singleEmail, parentIndex) => {
        let singleEmails = singleEmail.adminEmail === "" ? "" : singleEmail.adminEmail
        schoolForm.adminDetails.map((singleObjEmail, childIndex) => {
          let singleObjEmails = singleObjEmail.adminEmail === "" ? "" : singleObjEmail.adminEmail
          if (
            parentIndex !== childIndex &&
            singleEmails === singleObjEmails
          ) {
            isEmailMatched = true;
          }
          return singleObjEmail;
        });
        return singleEmail;
      });
      if (isEmailMatched) {
        dispatch(
          Notifications.show(
            {
              title: "Error",
              message: "Same Email used, please check email's.",
              position: "br",
              autoDismiss: 2,
            },
            "error"
          )
        );
        return true;
      }
      if (!isEmailMatched) {
        schoolForm.adminDetails.map((singleEmail) => {
          if (singleEmail.adminEmail === schoolForm.accountManagerEmail) {
            isEmailMatched = true;
          }
          return singleEmail;
        });
      }
      if (isEmailMatched) {
        dispatch(
          Notifications.show(
            {
              title: "Error",
              message: "Same Email used, please check email's.",
              position: "br",
              autoDismiss: 2,
            },
            "error"
          )
        );
        return true;
      }

      setSchoolLoader(true);
      dispatch(
        apiCall({
          urls: ["ADDSCHOOLDETAIL"],
          method: "POST",
          data: schoolForm,
          onSuccess: (response) => {
            props.closeModal();
            props.GridReload();
            setSchoolForm(initialValues);
            simpleValidator.current.hideMessages();
          },
          onFinally: () => {
            setSchoolLoader(false);
          },
          actionOfMethod: schoolForm?.schoolId > 0 ? "Edit" : "Add",
          showNotification: true,
        })
      );
    }
  };
  const onClose = () => {
    onPhoneChanged && props?.GridReload();
    props.closeModal(false);
  };
  useEffect(() => {
    if (props.modalType === "EDIT") {
      editdistrictDetail()
    }
  }, [props.openModal]);

  const editdistrictDetail = () => {
    if (props.districtEditDetail) {

      const {
        schoolId,
        schoolName,
        schoolEmail,
        numberofSeatsForLicense,
        numberOfStudentsforLicense,
        numberOfStudents,
        grade,
        adminDetails,
        numberOfAdmin,
        accountManagerName,
        accountManagerEmail,
        accountManagerPhoneNumber,
        schoolAddress,
        districtAndNetworkName,
        notes,
        studentPercentageIEPs,
        studentPercentageENL,
        studentPriceNegotiated,
        studentsFreeAndReducedLunch,
        totalPricePerYear,
        paymentTerms,
        accountExpiration,
        isAdminAdded,
      } = props.districtEditDetail;
      setSchoolForm({
        ...schoolForm,
        schoolName: schoolName,
        schoolEmail: schoolEmail,
        schoolId: schoolId,
        numberOfStudentsforLicense: numberOfStudentsforLicense,
        numberofSeatsForLicense: numberofSeatsForLicense,
        numberOfStudents: numberOfStudents,
        adminDetails: adminDetails,
        grade: grade,
        numberOfAdmin: numberOfAdmin,
        accountManagerName: accountManagerName,
        accountManagerEmail: accountManagerEmail,
        accountManagerPhoneNumber: accountManagerPhoneNumber,
        schoolAddress: schoolAddress,
        districtAndNetworkName: districtAndNetworkName,
        notes: notes,
        isAdminAdded: isAdminAdded,
        studentPercentageIEPs: studentPercentageIEPs,
        studentPercentageENL: studentPercentageENL,
        studentPriceNegotiated: studentPriceNegotiated,
        studentsFreeAndReducedLunch: studentsFreeAndReducedLunch,
        totalPricePerYear: totalPricePerYear,
        paymentTerms: paymentTerms,
        expiryDate: moment(accountExpiration),

      });

    }
  };
  const onHandlePriceChange = (e, { data }) => {
    let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (e.target.value.match(re)) {
      setSchoolForm({ ...schoolForm, [data]: e.target.value });
    } else {
      setSchoolForm({ ...schoolForm, [data]: "" });
    }
  };
  const priceValidationError = simpleValidator.current.message(
    "PercentageIEPs",
    schoolForm.studentPercentageIEPs,
    "required|max:100,num"
  );
  const priceValidationstudentPercentageENL = simpleValidator.current.message(
    "PercentageENL",
    schoolForm.studentPercentageENL,
    "required|max:100,num"
  );
  const priceValidationstudentPercentage = simpleValidator.current.message(
    "FreeAndReducedLunch",
    schoolForm.studentsFreeAndReducedLunch,
    "required|max:100,num"
  );
  const priceValidationstudentDollar = simpleValidator.current.message(
    "studentPriceNegotiated",
    schoolForm.studentPriceNegotiated,
    "required"
  );
  const priceValidationstudent = simpleValidator.current.message(
    "totalPricePerYear",
    schoolForm.totalPricePerYear,
    "required"
  );
  const priceValidationExpiration = simpleValidator.current.message(
    "expiryDate",
    schoolForm.expiryDate,
    "required"
  );


  // const onHandleShowAdmin = () => {
  //                                                            
  //   setisAdminAdded(!isAdminAdded);
  // }
  const onHandleChangeShowAdmin = (e, { data, value, checked, type }) => {
    if (type === "checkbox") {
      setSchoolForm({ ...schoolForm, [data]: checked })
    }
  };
  simpleValidator?.current?.purgeFields();
  return (
    <Modal
      open={props.openModal}
      onClose={props.closeModal}
      size="large"
      closeOnDimmerClick={false}>
      {schoolDisable ? <Modal.Header>View School</Modal.Header> :
        <Modal.Header>{" "}{schoolForm.schoolId ? "Edit School" : "Add School"}
        </Modal.Header>}
      <Modal.Content scrolling>
        <Modal.Description>
          <div className="common-shadow">
            {schoolLoader && commonFunctions.showLoader()}
            <Form>
              <Grid columns={3}>
                <Grid.Column>
                  <Form.Field >
                    <Form.Input
                      className="opacity-1"
                      label="Name of school *"
                      placeholder="Name of school"
                      data="schoolName"
                      disabled={schoolDisable}
                      value={schoolForm.schoolName}
                      onChange={onHandleChange}
                      error={simpleValidator.current.message(
                        "schoolName",
                        schoolForm.schoolName,
                        "required"
                      )}
                    />

                  </Form.Field>

                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    className="opacity-1"
                    placeholder="Number of seats/license"
                    label="Number of seats/License *"
                    data="numberofSeatsForLicense"
                    disabled={schoolDisable}
                    value={schoolForm.numberofSeatsForLicense}
                    onChange={onHandlePriceChange}
                    error={simpleValidator.current.message(
                      "number of Seats/License",
                      schoolForm.numberofSeatsForLicense,
                      "required")}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    className="opacity-1"
                    placeholder="Number of students for the license"
                    label="Number Of students for License *"
                    data="numberOfStudentsforLicense"
                    value={schoolForm.numberOfStudentsforLicense}
                    disabled={schoolDisable}
                    onChange={onHandlePriceChange}
                    error={simpleValidator.current.message(
                      "numberOfStudents forLicense",
                      schoolForm.numberOfStudentsforLicense,
                      "required"
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Dropdown
                    className="opacity-1"
                    placeholder="Grade"
                    label="Grade *"
                    fluid
                    multiple
                    selection
                    options={grade}
                    data="grade"
                    disabled={schoolDisable}
                    value={schoolForm.grade}
                    onChange={onHandleGrade}
                    error={simpleValidator.current.message(
                      "grade",
                      schoolForm.grade,
                      "required"
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    className="opacity-1"
                    placeholder="Number of admins"
                    label="Number of admins"
                    disabled={schoolDisable}
                    data="numberOfAdmin"
                    value={schoolForm.adminDetails.length}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                  <div className="showAdminCheckbox">
                    <Header as="h4">Admin Details</Header>
                  <Checkbox
                disabled={schoolForm.schoolId}
                      onChange={onHandleChangeShowAdmin}
                      data="isAdminAdded"
                      checked={schoolForm.isAdminAdded}
                      label='Make admin details required' />

                  </div>
                </Grid.Column>
                {schoolForm?.adminDetails?.map((item, index) => {
                  const schoolAdminValidation = schoolForm.isAdminAdded ? simpleValidator.current.message(`admin ${index + 1}Name`, schoolForm.adminDetails[index].adminName, "required") : "";
                  const schoolEmailValidation = schoolForm.isAdminAdded ? simpleValidator.current.message(`admin ${index + 1}Email `, schoolForm.adminDetails[index].adminEmail, "required") : "";
                  return (
                    <>
                      <Grid.Column >
                        <Form.Field className={schoolForm.isAdminAdded ? !schoolAdminValidation ?
                          "amountInput customPhoneInputField" : "amountInput customPhoneInputField error" : null}>
                          <Form.Input
                            className="opacity-1"
                            placeholder="Name"
                            label="Name "
                            data="adminName"
                            disabled={schoolDisable}
                            index={index}
                            onChange={onChangeAdmin}
                            value={item.adminName}
                          // error={simpleValidator.current.message(`admin ${index + 1}Name`, schoolForm.adminDetails[index].adminName, "required")}
                          />
                          {schoolForm.isAdminAdded ? <>
                            {simpleValidator.current.message(`admin ${index + 1}Name`, schoolForm.adminDetails[index].adminName, "required")}
                          </> : ""}
                        </Form.Field>


                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field
                          className={schoolForm.isAdminAdded ? !schoolEmailValidation ?
                            "amountInput customPhoneInputField" : "amountInput customPhoneInputField error" : null}>
                          <Form.Input
                            placeholder="Email"
                            label="Email "
                            data="adminEmail"
                            disabled={item.adminHiddenValue === true || schoolDisable}
                            index={index}
                            onChange={onChangeAdmin}
                            value={item.adminEmail}
                            className="opacity-1"
                          //   error={simpleValidator.current.message(`admin ${index + 1} Email `,
                          //   schoolForm.adminDetails[index].adminEmail,
                          //   "required|email"
                          // )}
                          />
                          {schoolForm.isAdminAdded ?
                            <>
                              {simpleValidator.current.message(`admin ${index + 1} Email `,
                                schoolForm.adminDetails[index].adminEmail,
                                "required|email"
                              )}
                            </> : ""}

                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <div className="customPhoneInput">
                          <PhoneNumberInput
                            className="opacity-1"
                            onChange={(value, country, e, formattedValue) =>
                              onHandlePrice(e, {
                                name: "phoneNumber",
                                value: formattedValue,
                                type: "phoneNumber",
                                data: "adminPhoneNumber",
                                index
                              })}
                            value={item.adminPhoneNumber}
                            disabled={schoolDisable}
                            error={schoolForm.isAdminAdded ? simpleValidator.current.message(
                              `admin  ${index + 1} Phone Number`,
                              schoolForm.adminDetails[index].adminPhoneNumber,
                              "required|phone"
                            ) : ""}
                          />
                          <label>Phone number </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <div className="mt-24 adminActionBtn">
                          {schoolForm.schoolId === null ? (index !== 0 && (
                            <Popup
                              content="Delete"
                              position="top center"
                              trigger={
                                <Button
                                  className="deleteBtn"
                                  icon="trash"
                                  onClick={() =>
                                    handleRemoveSpecificRow(index)
                                  }
                                />
                              }
                            />)) : (
                            <Popup
                              content="Delete"
                              position="top center"
                              trigger={
                                <Button disabled={schoolDisable}
                                  className="deleteBtn"
                                  icon="trash"
                                  onClick={() => handleRemoveSpecificRow(index)}
                                />
                              }
                            />
                          )}
                          {schoolForm?.adminDetails.length - 1 === index && (
                            <Popup
                              content="Add"
                              position="top center"
                              trigger={
                                <Button disabled={schoolDisable}
                                  icon="plus"
                                  onClick={handleAddRow}
                                  className="addBtn"
                                />
                              }
                            />
                          )}
                        </div>
                      </Grid.Column>
                    </>

                  );
                })}
                <Grid.Column width={16}>
                  <Divider fitted />
                  <Header as="h4">Account Manager Details</Header>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Name"
                    className="opacity-1"
                    label="Name *"
                    disabled={schoolDisable}
                    data="accountManagerName"
                    value={schoolForm.accountManagerName}
                    error={simpleValidator.current.message("name", schoolForm.accountManagerName, "required")}
                    onChange={onHandleChange} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Email"
                    label="Email *"
                    data="accountManagerEmail"
                    disabled={schoolForm.schoolId ? true : false}
                    value={schoolForm.accountManagerEmail}
                    error={simpleValidator.current.message(
                      "email",
                      schoolForm.accountManagerEmail,
                      "required|email"
                    )}
                    onChange={onHandleChange}
                    className="opacity-1" />
                </Grid.Column>
                <Grid.Column>
                  <div className="customPhoneInput">
                    <PhoneNumberInput
                      label="Phone number"
                      className="opacity-1"
                      onChange={(value, country, e, formattedValue) =>
                        onHandleChanged(e, {
                          name: "phoneNumber",
                          value: formattedValue,
                          type: "phoneNumber",
                          data: "accountManagerPhoneNumber",
                        })}
                      value={schoolForm.accountManagerPhoneNumber}
                      disabled={schoolDisable}
                      error={simpleValidator.current.message(
                        "Phone",
                        schoolForm.accountManagerPhoneNumber,
                        "required|phone")}
                    />
                    <label>Phone number *</label>
                  </div>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                  <Header as="h4">School Information</Header>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    className="opacity-1"
                    placeholder="School address"
                    label="School address *"
                    disabled={schoolDisable}
                    data="schoolAddress"
                    value={schoolForm.schoolAddress}
                    onChange={onHandleChange}
                    error={simpleValidator.current.message(
                      "schoolAddress",
                      schoolForm.schoolAddress,
                      "required"
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    className="opacity-1"
                    placeholder="District/Network name"
                    label="District/Network name *"
                    disabled={schoolDisable}
                    data="districtAndNetworkName"
                    value={schoolForm.districtAndNetworkName}
                    onChange={onHandleChange}
                    error={simpleValidator.current.message(
                      "districtAndNetworkName",
                      schoolForm.districtAndNetworkName,
                      "required"
                    )}
                  />
                </Grid.Column>

                <Grid.Column width={16}>
                  <Form.TextArea
                    // className="opacity-1"
                    placeholder="Notes"
                    disabled={schoolDisable}
                    data="notes"
                    label="Notes"
                    value={schoolForm.notes}
                    onChange={onHandleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <div className={
                    !priceValidationError
                      ? "customPhoneInputField"
                      : "customPhoneInputField error"
                  }>
                    <label>Percentage of students with IEP *</label>
                    <Input
                      placeholder="Students with IEP "

                      label={{ basic: true, content: "%" }}
                      disabled={schoolDisable}
                      labelPosition="right"
                      data="studentPercentageIEPs"
                      value={schoolForm.studentPercentageIEPs}
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "studentPercentage",
                      schoolForm.studentPercentageIEPs,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={
                    !priceValidationstudentPercentageENL
                      ? "customPhoneInputField"
                      : "customPhoneInputField error"
                  }>
                    <label>Percentage of ENL students *</label>
                    <Input

                      placeholder="Students of ENL"
                      label={{ basic: true, content: "%" }}
                      labelPosition="right"
                      disabled={schoolDisable}
                      data="studentPercentageENL"
                      value={schoolForm.studentPercentageENL}
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "studentPercentage",
                      schoolForm.studentPercentageENL,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={!priceValidationstudentPercentage ? "amountInput customPhoneInputField" : "amountInput customPhoneInputField error"}>
                    <label>Percentage free and reduced lunch *</label>
                    <Input

                      label={{ basic: true, content: "%" }}
                      labelPosition="right"
                      disabled={schoolDisable}
                      placeholder="Free  and reduced lunch"
                      data="studentsFreeAndReducedLunch"
                      value={schoolForm.studentsFreeAndReducedLunch}
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "FreeAndReducedLunch",
                      schoolForm.studentsFreeAndReducedLunch,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                </Grid.Column>
                <Grid.Column>
                  <div className={!priceValidationstudentDollar ? "amountInput customPhoneInputField"
                    : "amountInput customPhoneInputField error"
                  }>
                    <label>Price/Student negotiated *</label>
                    <Input

                      label={{ basic: true, content: "$" }}
                      labelPosition="left"
                      disabled={schoolDisable}
                      placeholder="Price/Student negotiated"
                      data="studentPriceNegotiated"
                      value={schoolForm.studentPriceNegotiated}
                      onChange={onHandlePriceChange}
                      fluid
                      step="any"
                    />
                    {simpleValidator.current.message(
                      "studentPriceNegotiated",
                      schoolForm.studentPriceNegotiated,
                      "required"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={!priceValidationstudent ? "amountInput customPhoneInputField" : "amountInput customPhoneInputField error"}>
                    <label>Total price per year *</label>
                    <Input
                      label={{ basic: true, content: "$" }}
                      labelPosition="left"
                      disabled={schoolDisable}
                      placeholder="Total price per year"
                      data="totalPricePerYear"
                      value={schoolForm.totalPricePerYear}
                      onChange={onHandlePriceChange} />

                    {simpleValidator.current.message(
                      "totalPricePerYear",
                      schoolForm.totalPricePerYear,
                      "required"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Payment terms or duration *"
                    placeholder="Payment terms or duration"
                    data="paymentTerms"
                    disabled={schoolDisable}
                    value={schoolForm.paymentTerms}
                    onChange={onHandleChange}
                    error={simpleValidator.current.message(
                      "paymentTerms",
                      schoolForm.paymentTerms,
                      "required"
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <DateInput
                    className="opacity-1"
                    minDate={schoolForm.schoolId ? false : moment().toDate()}
                    disabled={schoolDisable}
                    placeholder="Account Expiration"
                    label="Account expiration *"
                    dateFormat="MMM-DD-YYYY"
                    name="txtStartDate"
                    data="expiryDate"
                    value={schoolForm.expiryDate}
                    iconPosition="right"
                    onChange={onHandleChangeDate}
                    closable={true} fluid
                    error={simpleValidator.current.message(
                      "accountExpiration",
                      schoolForm.expiryDate,
                      "required"
                    )}
                  />
                </Grid.Column>
                {/* <Grid.Column>
                  <Form.Field className={!priceValidationExpiration ? "amountInput customPhoneInputField" : "amountInput customPhoneInputField error"}>
                    <SemanticDatepicker
                      label="Account expiration *"
                      data="expiryDate"
                      //name="txtStartDate"
                      value={schoolForm.expiryDate}
                      onChange={onHandleChangeDate}
                      minDate={schoolForm.schoolId ? false : moment().toDate()}
                      //format="MMM-DD-YYYY"
                      showToday={true}/>
                    {simpleValidator.current.message("accountExpiration",
                      schoolForm.expiryDate,
                      "required"
                    )}
                  </Form.Field>
                </Grid.Column> */}
              </Grid>
            </Form>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {schoolDisable ? <Button className="secondaryBtn" onClick={onClose}>
          Cancel
        </Button> :
          <><Button className="secondaryBtn" onClick={onClose}>
            Cancel
          </Button>
            <Button className="primaryBtn" onClick={onHandleSubmit}>
              {schoolForm.schoolId ? "Update" : "Save"}
            </Button>
          </>}

      </Modal.Actions>
    </Modal>
  );
}
export default AddSchoolPage;
