import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'semantic-ui-react';
import { Link, env } from "../../../functional/global-import";


const BreadCrumb = (props) => {
    const [activeItem, setActiveItem] = useState()
    const isTeacherLogin = useSelector(state => state.auth.userDetail.isTeacher);


    useEffect(() => {
        if (props?.isActive) {
            setActiveItem(props?.isActive)
        }
    });

    return (
        <>
            <List.Item name="Library" active={activeItem === 'Library'} as={Link} to={`${env.PUBLIC_URL}/teacher-library`}
            >ALEE Library</List.Item>
            {isTeacherLogin &&
                <>
                    <List.Item name="Mylibrary" active={activeItem === 'Mylibrary'} as={Link} to={`${env.PUBLIC_URL}/my-library`}
                    >My Library</List.Item>
                    <List.Item name="curatedForYou" active={activeItem === 'curatedForYou'} as={Link} to={`${env.PUBLIC_URL}/curatedForYou`}
                    >Curated For you</List.Item>
                </>
            }
        </>
    );
}
export default BreadCrumb;