import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header, Image, Grid, Dimmer, Loader, Button } from 'semantic-ui-react'
import { WhiteLogo } from "../../shared/functional/global-image-import"
import { logout } from "../../store/actions/auth.actions";
import { apiCall } from "../../store/actions/api.actions";
import { useHistory } from "react-router-dom";
import {env} from "../../shared/functional/global-import";

const CompleteYourPaymentPage = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const email = useSelector(state => state.auth.userDetail.email);
    const userId = useSelector(state => state.auth.userDetail.userId);
    const [customerPortalData, setCustomerPortalData] = useState(null);

    const getCustomerPortal = ()=>{
        dispatch(apiCall({
            urls: ["STRIPECUSTOMERPORTAL"], 
            method: "GET",
            onSuccess: (response) => {
                console.log('success', response);
                setCustomerPortalData(response);
            },
            onError: (e)=>{
                console.log('error', e);
                
            },
            onFinally: () => {
                setLoading(false);
            }
        }));
    };

    useEffect(()=>{
        getCustomerPortal();
    },[]);

    const logoutClick = ()=>{
        dispatch(apiCall({
            urls: ["LOGOUT"], method: "POST",
            data: {
                userId: userId,
                isLogIn: false
            },
            onSuccess: (response) => {
                history.push(`${env.PUBLIC_URL}/`);
            }, showNotification: true
        }));
        dispatch(logout());
    };

    if (!email)
        history.push(`${env.PUBLIC_URL}/`);

    return (
        <>
            <div className='signUpWrapper'>
                <div className='signUpNew'>
                    <Image className='logo' src={WhiteLogo} />
                    <div className={`${"signUpNewInner completePayment"}`}>
                        {loading && (
                            <Dimmer active inverted>
                                <Loader />
                            </Dimmer>
                        )}
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width={16}  textAlign="center">
                                <Header as="h2">Complete Sign-up</Header>
                                <h3>No credit card required to take advantage of your free trial.</h3>
                                <h4>Click "Start Trial" below and enter promo code on the next page.</h4>
                                </Grid.Column>
                            </Grid.Row>
                            { customerPortalData?.hasSubscriptionExpired  &&
                                <div className="plan-selection-button">
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign="center">
                                            <h2>120 Days Free</h2>
                                            <br />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign="center">
                                            <stripe-buy-button
                                                buy-button-id={env.PAYMENT_BUTTON_MONTHLY}
                                                publishable-key={env.STRIPE_PUBLISHABLE_KEY}
                                                customer-email={email}
                                            ></stripe-buy-button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </div>
                            }
                            <Grid.Row>
                                <Grid.Column width={16}  textAlign="center">
                                    { customerPortalData && !customerPortalData.hasSubscriptionExpired &&
                                        <Button className="primaryBtn" onClick={()=>{ window.location.href = '/teacher-dashboard'; }}>Go back to My ALEE</Button>
                                    }
                                    { customerPortalData?.url &&
                                        <Button className="primaryBtn" onClick={()=>{ window.location.href = customerPortalData?.url; }}>Manage Subscription</Button>
                                    }
                                    <br /><br /><br />
                                    <div>
                                        Having trouble signing up? Email <a className="pointer" href="mailto:info@myalee.com">info@myalee.com</a>.
                                    </div>
                                    <br />
                                    <div>
                                        <a className="pointer" onClick={logoutClick}>Click here</a> to use a different account.
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div className='alreadyAccount'>By signing up, I agree to the <a href="https://myalee.com/privacy-policy/" target="_blank">ALEE Privacy Policy</a> and <a href="https://myalee.com/terms-of-service/" target="_blank">Terms of Service</a>.</div>
                </div>
            </div>

        </>
    )
}

export default CompleteYourPaymentPage