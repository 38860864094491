import { withRouter } from 'react-router';
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function AppContainer(props) {

  let history = useHistory();

  useEffect(() => {

    history.listen((location, action) => {

      window.scroll(0, 0);
    });

  }, []);

  return (
    props.children
  )
}

export default withRouter(AppContainer);