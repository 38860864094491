import * as types from "../types"

const initialState = { BookDetail: {}, isMenuCollapsed: true, isFullEngOpen: false };

const myBookDetail = (state = initialState, actions) => {

    switch (actions.type) {
        case types.STORE_MY_BOOK_DATA:
            return { ...state, BookDetail: actions.payload }
        case types.STORE_MENU_COLLAPSE:
            return { ...state, isMenuCollapsed: actions.payload }
        case types.STORE_FULL_ENGAGEMENT_OPEN:
            return { ...state, isFullEngOpen: actions.payload }
        default:
            return state;
    }
}
export default myBookDetail;
