import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, Button, Form } from "semantic-ui-react";
import { apiCall } from "../../../../../store/actions/api.actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/global-import";

function  AddStudent(props) {
  const auth = useSelector((state) => state.auth);
  const [grade, setGradeList] = useState([]);
  // const [expand, setExpand] = useState(false);
  // const [allStandard, setAllStandard] = useState([]);
  const initialValues = {
    teacherId: auth?.userDetail?.teacherId,
    studentId: null,
    firstName: "",
    lastName: "",
    registrationNumber: "",
    email: "",
    isActive: true,
    gradeId: null,
    standardId: null,

  };
  const [addStudent, setAddStudent] = useState(initialValues);
  const [, forceUpdate] = useState()
  const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
  const api = useSelector((state) => state.api);
  const dispatch = useDispatch();


  const onHandleChange = (e, { data, value, checked, type }) => {
    setAddStudent({ ...addStudent, [data]: value });
    if (type === "checkbox") {
      setAddStudent({ ...addStudent, [data]: checked });
    }
  };
  const onHandleChangegGrade = (e, { data, value, checked, type }) => {
    setAddStudent({ ...addStudent, [data]: value });
    // getStandards(value);
  };
  const onHandleSubmit = (e) => {
    const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
    if (isFormValid) {
      dispatch(
        apiCall({
          urls: ["ADDUPDATESTUDENT"],
          method: "POST",
          data: addStudent,
          onSuccess: (response) => {
            closeModal();
            props.gridReload();
            setAddStudent(initialValues);
          },
          showNotification: true,
        })
      );
    }
  };
  useEffect(() => {
    if (props.editStudent) {
      editStudentlist()
    }
    getGradeList();
  }, [props.editStudent]);


  const editStudentlist = () => {
    if (props.editStudent) {
                                               
      let gradeData = grade;
      const { FirstName, LastName, Email, IsActive, GradeId, StudentId, RegistrationNumber, StandardId, GradeName } =props.editData;
      let updatedArray = commonFunctions.dropdownDeletedValue(gradeData, GradeId, GradeName);
      setGradeList(updatedArray);
      setAddStudent({
        ...addStudent,
        studentId: StudentId,
        firstName: FirstName,
        lastName: LastName,
        email: Email,
        isActive: IsActive,
        gradeId: GradeId,
        registrationNumber: RegistrationNumber,
        standardId: StandardId
      });
    }
  };
  const getGradeList = () => {
    dispatch(
      apiCall({
        urls: ["GETGRADESLIST"],
        method: "GET",
        data: ({ ActiveGrades: true, OrderBy: "GradeName", OrderByDescending: false, isActive: true }),
        onSuccess: (response) => {
          const grade = [{ text: "Select Grades", value: null }]
          response.map((singledata) => {
            grade.push({
              text: singledata.gradeName,
              value: singledata.gradeId
            })
            return { ...singledata }
          });
          setGradeList(grade);
        },
      })
    );
  };

  const closeModal = () => {
    simpleValidator.current.hideMessages();
    setAddStudent(initialValues);
    props.closeModal();
  };
 

  return (
    <Modal
      open={props.openModal}
      onClose={props.closeModal}
      closeOnDimmerClick={false}
      size="small">
        
      {api.isApiLoading && commonFunctions.showLoader()}
      <Modal.Header> {addStudent.studentId > 0 ? "Edit Student" : "Add Student"}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <React.Fragment>
            <Form>
              <Grid columns="2">
                <Grid.Column>
                  <Form.Input
                    placeholder="First Name"
                    label="First Name"
                    data="firstName"
                    value={addStudent.firstName}
                    onChange={onHandleChange}
                    fluid
                    error={simpleValidator.current.message('firstName', addStudent.firstName, 'required|min:0|max:50')}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Last Name"
                    label="Last Name"
                    data="lastName"
                    value={addStudent.lastName}
                    onChange={onHandleChange}
                    fluid
                    error={simpleValidator.current.message('lastName', addStudent.lastName, 'required|min:0|max:50')}

                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Student ID Number"
                    label="Student ID Number"
                    data="registrationNumber"
                    onChange={onHandleChange}
                    value={addStudent.registrationNumber}
                    fluid
                    error={simpleValidator.current.message('Student Id Number', addStudent.registrationNumber, 'required')} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Select
                  className="addStudentGrade"
                    placeholder="Select Grades"
                    label="Grade"
                    options={grade}
                    data="gradeId"
                    value={addStudent.gradeId}
                    onChange={onHandleChangegGrade}
                    fluid
                    error={simpleValidator.current.message('grade', addStudent.gradeId, 'required')}/>
                </Grid.Column>
                {/* <GridColumn>
                  <Form.Select
                    label="Standard"
                    placeholder='Standard'
                    fluid
                    search
                    selection
                    data="standardId"
                    value={addStudent.standardId}
                    options={allStandard}
                    onChange={onHandleChange}
                    clearable
                  />
                </GridColumn> */}
                <Grid.Column className="status">
                  <p className="boldStatus">Status</p>
                  <div className="statusToggle">
                    <span>Inactive</span>
                    <Form.Checkbox
                      label="Active"
                      toggle
                      className="commonToggle"
                      onChange={onHandleChange}
                      data="isActive"
                      checked={addStudent.isActive}
                      value={addStudent.isActive}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </Form>
          </React.Fragment>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button className="secondaryBtn" onClick={() => closeModal()}>
          {" "}
          Cancel{" "}
        </Button>
        <Button
          className="primaryBtn"
          onClick={onHandleSubmit}
          loading={api.isApiLoading}>
          {addStudent.studentId > 0 ? "Update" : "Save"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddStudent;
