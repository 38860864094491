import React from "react";
import { Modal, Button } from "semantic-ui-react";

function LessonBlockModal(props) {
    const { isLessonPlanOrBlock } = props;
    return (
        <Modal size={"small"} open={props.open}  onClose={() => props.close()}>
            <Modal.Content>
                <p style={{ fontSize: 17, textAlign: "center" }}>  {props.message} </p>
                <div className="text-center">
                    {!isLessonPlanOrBlock ? <Button className='secondaryBtn' onClick={props?.addNewLesson}>
                        Add New Lesson Plan</Button>
                        :
                        <Button className='secondaryBtn' onClick={props?.addNewBlock}>Add New Block</Button>
                    }
                </div>
            </Modal.Content>

        </Modal>
    )
}
export default LessonBlockModal;