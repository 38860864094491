import React, { useState,useEffect, useRef, } from "react";
import { Button, Form, Grid, Modal } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { commonFunctions, Notifications } from "../../../../functional/global-import";
import SimpleReactValidator from 'simple-react-validator';
import { apiCall } from "../../../../../store/actions/api.actions";

const AddBookWidthModal = (props) => {
    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const [bookWidthValue, setBookWidthValue] = useState(null);
    const [pageWidthOption] = useState([
        { text: "Select Width Ratio", value: null },
        { text: "1:1", value: 8 },
        { text: "4:3", value: 9 },
        { text: "5:3", value: 10 },
    ])
    const api = useSelector(state => state.api);
    const onErrorOfSameWidth = () => {
        dispatch(Notifications.show({
            title: "Error",
            message: 'Same width already exists, Please choose another.',
            position: 'br', autoDismiss: 5
        }, "error"))
    }
    useEffect(() => {
              
        setBookWidthValue(props?.pageWidth)
    }, []);

    const onHandleChangeRole = (e, { data, value }) => {
                                                                   
        if (props?.pageWidth === value) {
            onErrorOfSameWidth()
        }
        else {
            setBookWidthValue(value)
        }
    };
    const onHandleSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            dispatch(
                apiCall({
                    urls: ["ADDBOOKPAGEWIDTH"],
                    method: "PUT",
                    data: {
                        bookId: props?.bookId,
                        pageWidth: bookWidthValue
                    },
                    onSuccess: (response) => {
                        props.closeModal()
                        props.onPageLoad(true)
                    }, actionOfMethod: "Edit",
                    // showNotification: true,
                })
            );
        }
    };
    return (
        <Modal open={props.openModal} onClose={props.closeModal} size="tiny" closeOnDimmerClick={false}>
            <Modal.Header>Set Book Width </Modal.Header>
            {api.isApiLoading && commonFunctions.showLoader()}
            <Modal.Content>
                <Form>
                    <Grid>
                        <Grid.Column>
                            <Form.Select
                                className="opacity-1"
                                label="Book Width Ratio"
                                placeholder="Select Width Ratio"
                                options={pageWidthOption}
                                data="applicationRoleId"
                                value={bookWidthValue}
                                onChange={onHandleChangeRole}
                                fluid
                                error={simpleValidator.current.message('width', bookWidthValue, 'required')}
                            />

                        </Grid.Column>

                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button className='secondaryBtn' onClick={props.closeModal}> Cancel </Button>
                <Button className='primaryBtn' onClick={onHandleSubmit}>Save</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddBookWidthModal