import React from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Dropdown } from "semantic-ui-react";
import { commonFunctions } from "../../../../functional/global-import";

function FullEngagementModal(props) {
    const { bookPageData, fullEngData, fullEngagementLoader, engagementModalDetail, selectedContentEng } = props;
    // const api = useSelector(state => state.api)
    const onReadFullEng = (singleFullEngObj) => {
        props?.close()
        props.showEngagement(singleFullEngObj.EngagementId, true)
    }
    const onEditbtnClick = (singleFullEngObj) => {
        props?.close()
        props?.getTag(singleFullEngObj.EngagementId, false)
    }
    const onDuplicateClick = (singleFullEngObj) => {
        props?.close()
        props.getTag(singleFullEngObj.EngagementId, true)
    }
    const onDeleteBtnClick = (singleFullEngObj) => {
        props?.close()
        props?.confirmModalOpen(singleFullEngObj.EngagementId)
    }

    return (
        <Modal size="tiny" open={props?.open} dimmer="inverted"
            className="deleteModal"
            onClose={props?.close}>
            <Modal.Content scrolling>
                <div class="dropbtn" style={{ backgroundColor: `rgba(193, 151, 73, 0.25)`, display: `inline-block` }}>{selectedContentEng} </div>
                <br></br>
                <br></br>
                {/* {bookPageData?.fullPageEngagement
                    && fullEngData && fullEngData.length > 0
                    && JSON.parse(bookPageData?.fullPageEngagement)?.map((singleFullEngObj) => { */}
                {engagementModalDetail && engagementModalDetail.length > 0 && engagementModalDetail?.map((singleFullEngObj) => {
                    return (
                        <>
                            <div className='engagementData'>
                                <Dropdown.Menu>
                                    <div className='engagement-tag'>
                                        {fullEngagementLoader && commonFunctions.showLoader()}
                                        Engagement {singleFullEngObj.EngagementName}  - {singleFullEngObj.Tag}</div>
                                    <p className='engagement-tag-para'>{singleFullEngObj?.EngagementTagDescription.length > 164 ?
                                        commonFunctions.truncateText(singleFullEngObj.EngagementTagDescription, 164) :
                                        singleFullEngObj?.EngagementTagDescription}</p>
                                    <span className='fullmoveToEngagement' onClick={() => onReadFullEng(singleFullEngObj)}>
                                      View </span>
                                    <span className='fullmoveToEngagement'
                                        onClick={() => onEditbtnClick(singleFullEngObj)}
                                    >Edit</span>
                                    <span className='fullmoveToEngagement'
                                        onClick={() => onDuplicateClick(singleFullEngObj)}
                                    >Duplicate</span>
                                    <span className='fullmoveToEngagement'
                                        onClick={() => onDeleteBtnClick(singleFullEngObj)}
                                    >Delete </span>
                                </Dropdown.Menu>
                            </div>
                            <br></br>
                        </>
                    )
                })}
            </Modal.Content>
            <Modal.Actions>
                <div className="actionsBtn">
                    <Button className="secondaryBtn" onClick={props?.close}>
                        Back
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    )
}
export default FullEngagementModal;
