import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, Button, Form } from "semantic-ui-react";
import { apiCall } from "../../../../../store/actions/api.actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions } from "../../../../functional/global-import";
// import { GlobalCodeSelect } from "../../../atoms";

function AddTeacherModal(props) {
    const auth = useSelector((state) => state.auth);
    const globalCode = useSelector(state => state.global.codes);
    const initialValues = {
        schoolId: auth?.userDetail?.schoolId,
        email:"",
        firstName:"",
        studentCount:"",
        lastName: "",
        roleId: (commonFunctions.getGlobalCodeDetails(globalCode, "TeacherRoles", "Teacher")).globalCodeId,
        isActive: true,
        excelReferenceId: null,
    };
    const [addTeacher, setAddTeacher] = useState(initialValues);
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const api = useSelector((state) => state.api);
    const dispatch = useDispatch();
    const onHandleChange = (e, { data, value, checked, type }) => {
        setAddTeacher({ ...addTeacher, [data]: value });
        if (type === "checkbox") {
            setAddTeacher({ ...addTeacher, [data]: checked });
        }
    };

    const onHandleSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            let newFirstName = addTeacher.firstName.charAt(0).toUpperCase() + addTeacher.firstName.slice(1)
            addTeacher.firstName = newFirstName
            addTeacher.studentCount = parseInt(addTeacher.studentCount)
            dispatch(
                apiCall({
                    urls: ["ADDTEACHERTOSCHOOL"],
                    method: "POST",
                    data: addTeacher,
                    onSuccess: (response) => {
                        closeModal();
                        props.gridReload();
                        setAddTeacher(initialValues)},
                    showNotification: true,
                })
            );
        }
    };

    const onHandleSubmitUpdate = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {

            let newFirstName = addTeacher.firstName.charAt(0).toUpperCase() + addTeacher.firstName.slice(1)
            addTeacher.firstName = newFirstName
            dispatch(
                apiCall({
                    urls: ["UPDATETEACHER"],
                    method: "PUT",
                    data: addTeacher,
                    onSuccess: (response) => {
                        closeModal();
                        props.gridReload();
                        setAddTeacher(initialValues);
                    },
                    showNotification: true,
                })
            );
        }
    };
    useEffect(() => {

        // var person1 = { firstName: 'Jon', lastName: 'Kuperman' };
        // var person2 = { firstName: 'Kelly', lastName: 'King' };

        // function say(greeting) {
        //     
        //     console.log(greeting + ' ' + this.firstName + ' ' + this.lastName);
        // }
        // 
        // say.apply(person1, ['Hello']); // Hello Jon Kuperman
        // say.apply(person2, ['Hello']); // Hello Kelly King




        // for (var i = 0; i < 5; i++) {
        //     
        //     let w = i
        //     setTimeout(() => {
        //         
        //         console.log(w)
        //     }, 1000);
        // }

        // console.log(a);
        // var a = 10
        // console.log(b);
        // let b = 10
        // console.log(c);
        // const c = 10

        if (props.editStudent) {
            editStudentlist()
        }

    }, [props.editStudent]);


    const editStudentlist = () => {
        if (props.editStudent) {

            const { FirstName, LastName, StudentCount, Email, IsActive, TeacherId } = props.editData;

            setAddTeacher({
                ...addTeacher, teacherId: TeacherId,
                firstName: FirstName,
                lastName: LastName,
                email: Email,
                studentCount: StudentCount,
                isActive: IsActive,
            });
        }
    };


    const closeModal = () => {
        simpleValidator.current.hideMessages();
        setAddTeacher(initialValues);
        props.closeModal();
    };


    return (
        <Modal
            open={props.openModal}
            onClose={props.closeModal}
            closeOnDimmerClick={false}
            size="small"
        >
            {api.isApiLoading && commonFunctions.showLoader()}
            <Modal.Header> {addTeacher.teacherId > 0 ? "Edit Teacher" : "Add Teacher"}</Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <React.Fragment>
                        <Form>
                            <Grid columns="2">
                                <Grid.Column>
                                    <Form.Input
                                        placeholder="First Name"
                                        label="First Name"
                                        data="firstName"
                                        value={addTeacher.firstName}
                                        onChange={onHandleChange}
                                        fluid
                                        error={simpleValidator.current.message('firstName', addTeacher.firstName, 'required|min:0|max:50')}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        placeholder="Last Name"
                                        label="Last Name"
                                        data="lastName"
                                        value={addTeacher.lastName}
                                        onChange={onHandleChange}
                                        fluid
                                        error={simpleValidator.current.message('lastName', addTeacher.lastName, 'required|min:0|max:50')}

                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        placeholder="Email"
                                        type="email"
                                        label="Email"
                                        disabled={addTeacher.teacherId > 0}
                                        data="email"
                                        value={addTeacher.email}
                                        onChange={onHandleChange}
                                        error={simpleValidator.current.message('email', addTeacher.email, 'required|email')}
                                        fluid
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <div className='formGroup'>
                                        <Form.Input
                                            placeholder="How many students do you have?"
                                            label="How many students do you have?" type="number" data="studentCount" value={addTeacher.studentCount} onChange={onHandleChange}
                                            error={simpleValidator.current.message('studentCount', addTeacher.studentCount, 'required|min:1,num')}
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column className="status">
                                    <b>Status</b>
                                    <div className="statusToggle">
                                        <span>Inactive</span>
                                        <Form.Checkbox
                                            label="Active"
                                            toggle
                                            className="commonToggle"
                                            onChange={onHandleChange}
                                            data="isActive"
                                            checked={addTeacher.isActive}
                                            value={addTeacher.isActive}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </React.Fragment>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className="secondaryBtn" onClick={() => closeModal()}>
                    {" "}
                    Cancel{" "}
                </Button>
                {addTeacher.teacherId > 0 ? <Button
                    className="primaryBtn"
                    onClick={onHandleSubmitUpdate}
                    loading={api.isApiLoading}>
                    Update
                </Button> : <Button
                    className="primaryBtn"
                    onClick={onHandleSubmit}
                    loading={api.isApiLoading}> Save</Button>
                }
            </Modal.Actions>
        </Modal>
    );
}

export default AddTeacherModal;