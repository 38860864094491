import React, { useState, useEffect } from "react";
import { Button, Header, Image } from 'semantic-ui-react';
import { Close } from "../../../functional/global-image-import";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../store/actions";
import draftToHtml from 'draftjs-to-html';
import { commonFunctions, } from "../../../functional/global-import";
const ResourcesPage = (props) => {
    const [resourcesLoader, setResourcesLoader] = useState(false);
    const [resources, setResources] = useState(null);
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        getAleeCoach();
    }, []);
    const getAleeCoach = () => {
        setResourcesLoader(true);

        dispatch(
            apiCall({
                urls: ["GETALEECOACH"],
                method: "GET",
                data: { AleeCoachId: props.editData },
                onSuccess: (response) => {                    
                    setEditorState(isJsonString ?
                        EditorState.createWithContent(convertFromRaw(JSON.parse(response.detailDescription))) : []);
                        setResources(response.detailDescription)
                }, onFinally: () => {

                    setResourcesLoader(false)
                },
            })
        );
    };
    return (
        <>

            <div className={`bookSummary`}>
                <div className='bookSummaryContent'>
                    <Header as="h3">Resources </Header>
                    <div className='closeSummary' onClick={props.handleToggles}><Image src={Close} /></div>
                    <div className='summaryDetails'>
                        {resourcesLoader && commonFunctions.showLoader()}
                        {<div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></div>}
                    </div>

                </div>
            </div>

        </>
    )
};

export default ResourcesPage;
