import React, { useState, useEffect } from "react";
import { apiCall } from "../../store/actions/api.actions";
import { useDispatch } from "react-redux";
import { Header, Image, Grid, Button, Dimmer, Loader } from 'semantic-ui-react'
import { WhiteLogo } from "../../shared/functional/global-image-import"
import { useHistory } from "react-router-dom";
import {env} from "../../shared/functional/global-import";

const PaymentCompletedPage = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();

    let sessionId = props.sessionId;
  
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const checkStripeSession = ()=> {

        dispatch(apiCall({
            urls: ["BILLINGSTRIPESESSION"], method: "POST",
            data: {
                sessionId: sessionId
            },
            onSuccess: (response) => {
                setMessage('Welcome to the ALEE family!');
                window.location.href=`${env.PUBLIC_URL}/create-profile`;
            },
            onError: (e)=>{
                setMessage('Error occurred while processing the payment');
            },
            onFinally: () => {
                setLoading(false);
            }
        }));
    };

    useEffect(()=>{

        checkStripeSession();

    },[sessionId]);

    return (
        <>
            <div className='signUpWrapper'>
                <div className='signUpNew'>
                    <Image className='logo' src={WhiteLogo} />
                    <div className={`${"signUpNewInner completePayment"}`}>

                        {loading && (
                            <Dimmer active inverted>
                                <Loader />
                            </Dimmer>
                        )}

                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                <Header as="h2">{message}</Header>
                                <br /><br />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <br /><br /><br />
                                    <Button className="primaryBtn" onClick={()=>{ window.location.href=`${env.PUBLIC_URL}/create-profile`; }}>Continue</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        
                    </div>
                    <div className='alreadyAccount'>By signing up, I agree to the <a href="https://myalee.com/privacy-policy/" target="_blank">ALEE Privacy Policy</a> and <a href="https://myalee.com/terms-of-service/" target="_blank">Terms of Service</a>.</div>
                </div>
            </div>
        </>
    )
}

export default PaymentCompletedPage