import React from "react";
import { Table, Header } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";

function TableRow(props) {
   
    return (
        <>
            <InfiniteScroll
                dataLength={props.gridData.length}
                scrollableTarget={`${"scrollable" + props.gridName}`}
                next={props.fetchMoreData}
                hasMore={props.hasMore}
            >
                <Table.Body id={`${"scrollable" + props.gridName}`} style={props.tableHeight !== undefined ? { maxHeight: props.tableHeight } : { Height: "200px" }}>
                    {props.gridData && props.gridData.length === 0 &&
                        <Table.Row><Table.Cell colSpan="5">
                            <Header as='h5' className="forCommonTable">No record</Header>
                        </Table.Cell>
                        </Table.Row>}
                    {props.gridData.map((gridSingleObj, parentIndex) => {
                        const checkIsActive = gridSingleObj?.isActive
                        return(
                            <Table.Row className={`${checkIsActive === undefined ? "" : !checkIsActive ? "inActiveRecords" : ""}`} 
                            key={parentIndex}>
                                {props.columns.map((singleField, index) => {
                                    //const cellClass = singleField.headerName === "Action" ? "actionCell" : singleField.cellClassName === undefined ? "" : singleField.cellClassName
                                    const cellClass = singleField.cellClassName === undefined ? "" : singleField.cellClassName
                                    const fieldValue = singleField.fieldName;
                                    const fieldTitle = gridSingleObj[fieldValue] !== null ? gridSingleObj[fieldValue] : "";
                                    const showFieldTitle = singleField.toolTip === null ? "" : fieldTitle
                                    return (
                                        <>
                                            <Table.Cell key={index}
                                                className={cellClass}
                                                title={[`${showFieldTitle}`,
                                                ].join(' ')}
                                            >
                                                {singleField.Cell ? singleField.Cell(gridSingleObj, props.confirmModalOpen, parentIndex) :
                                                    gridSingleObj[fieldValue] !== null ? gridSingleObj[fieldValue] : "-"}
                                            </Table.Cell>
                                        </>
                                    );
                                })}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </InfiniteScroll>
        </>
    );
}

export default TableRow;

