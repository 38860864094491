import React, { useState, useEffect } from "react";
import { Dropdown, Image, Card, Feed } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../../../store/actions/api.actions";
import {
  commonFunctions,
  Notifications,
} from "../../../functional/global-import";
import ChangePassword from "../modal/change-password/change-password";
import { useHistory } from "react-router-dom";
import { env } from "../../../functional/global-import";
import { storeMenuCollapse } from "../../../../store/actions";
import MyProfile from "../../atoms/my-profile";
import {
  Menu,
  LeftArrow,
  RightArrow,
} from "../../../functional/global-image-import";

const { forwardRef, useImperativeHandle } = React;
const Header = forwardRef((props, ref) => {
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [notification, setNotification] = useState([]);
  const userId = useSelector((state) => state.auth.userDetail.userId);
  const userRole = useSelector((state) => state.auth.userDetail.role);
  const [interval, setInterval] = useState(null);
  const roleAndPermission = useSelector(
    (state) => state.auth.userDetail.roleAndPermission
  );

  let isToShowHeader =
    userRole === "Admin" ? true : userRole === "SubAdmin" ? true : false;

  useImperativeHandle(ref, () => ({
    GetNotification() {
      GetNotification();
    },
  }));

  useEffect(() => {
    if (isToShowHeader) {
      GetNotification();
    }
  }, []);

  const dispatch = useDispatch();
  let history = useHistory();

  const forgetPassword = () => {
    setForgotPasswordStatus(!forgotPasswordStatus);
  };
  let interval1;
  const GetNotification = () => {
    // dispatch(
    //   apiCall({
    //     urls: ["GETNOTIFICATIONS"],
    //     method: "GET",
    //     data: { userId: userId },
    //     onSuccess: (response) => {
    //       let time = 600 * 1000;
    //       setNotification(response);
    //       interval1 = setTimeout(() => GetNotification(), time);
    //       setInterval(interval1);
    //     },
    //   })
    // );
  };

  const onReadNotification = (id) => {
    dispatch(
      apiCall({
        urls: ["UPDATENOTIFICATIONISREAD"],
        method: "PUT",
        data: {
          NotificationId: id,
          UserId: userId,
        },
        onSuccess: (response) => {
          clearTimeout(interval);
          setTimeout(() => GetNotification(), 1000);

          // setNotification(response)
        },
      })
    );
  };

  const onErrorOfNoScreen = () => {
    dispatch(
      Notifications.show(
        {
          title: "Error",
          message: "This screen access is not allowed to you",
          position: "br",
          autoDismiss: 5,
        },
        "error"
      )
    );
  };

  const onScreenRoute = (type) => {
    let screenAllowed = true;
    if (userRole === "SubAdmin") {
      screenAllowed = commonFunctions.onCheckScreenAllowed(
        roleAndPermission,
        type
      );
      if (screenAllowed) {
        type !== "book-flip" && history.push(`${env.PUBLIC_URL}/${type}`);
      } else {
        onErrorOfNoScreen();
      }
    } else {
      type !== "book-flip" && history.push(`${env.PUBLIC_URL}/${type}`);
    }
  };

  // const onScreenRoute = (type) => {
  //
  //   let screenAllowed = true
  //   if (type === "Grades") {
  //     if (userRole === "SubAdmin") {
  //       screenAllowed = commonFunctions.onCheckScreenAllowed(roleAndPermission, "add-grades")
  //       screenAllowed && history.push(`${env.PUBLIC_URL}/add-grades`);
  //     }
  //     else {
  //       history.push(`${env.PUBLIC_URL}/add-grades`);
  //     }
  //   }
  //   else if (type === "User-management") {
  //     history.push(`${env.PUBLIC_URL}/user-management`);
  //   }
  //   else if (type === "Manage-teachers") {
  //     history.push(`${env.PUBLIC_URL}/manage-teachers`);
  //   }
  //   else if (type === "Alee Library" || type === "Book") {
  //     history.push(`${env.PUBLIC_URL}/my-books`);
  //   }
  //   else if (type === "Resources" || type === "Resource") {
  //     history.push(`${env.PUBLIC_URL}/resources`);
  //   }
  //   else if (type === "Alee Coach") {
  //     history.push(`${env.PUBLIC_URL}/alee-coachs`);
  //   }
  //   else if (type === "Sub-Admin") {
  //     history.push(`${env.PUBLIC_URL}/sub-admin`);
  //   }
  //   else if (type === "Tags") {
  //     history.push(`${env.PUBLIC_URL}/add-tags`);
  //   }
  //   else if (type === "School") {
  //     history.push(`${env.PUBLIC_URL}/add-schools`);
  //   }
  //   else if (type === "Promo-code") {
  //     history.push(`${env.PUBLIC_URL}/add-promo-codes`);
  //   }
  //   else if (type === "Role") {
  //     history.push(`${env.PUBLIC_URL}/roles-permission`);
  //   }
  //   else if (type === "District") {
  //     history.push(`${env.PUBLIC_URL}/add-districts`);
  //   }
  // }

  const onClickOfMessage = (data) => {
    let type = data.notificationType;
    onReadNotification(data.notificationId);
    // props?.onSideBarOptionClick(type)
    onScreenRoute(type);
  };

  const onSetName = (type) => {
    // const sideBarName =
  };

  const onMenuClick = () => {
    dispatch(storeMenuCollapse(!props?.isActive));
    props?.onMenuClick();
  };

  return (
    <React.Fragment>
      <div className="Header">
        <div
          className="menuBar"
          // onClick={props.onMenuClick}
          onClick={onMenuClick}
        >
          <Image className="left" src={LeftArrow} />
          <Image className="right" src={RightArrow} />
        </div>
        <div className="headerRightcontainer">
          {isToShowHeader && (
            <>
              <Dropdown
                text={notification.length > 9 ? "9+" : notification.length}
                multiple
                icon="bell"
                className="notificationDropdown"
                direction="left"
              >
                <Dropdown.Menu>
                  <Card className="Headernotification">
                    <Card.Content>
                      <Card.Header>Notifications</Card.Header>
                    </Card.Content>
                    <Card.Content className="body">
                      <Feed>
                        {notification.map((item) => {
                          return (
                            <Feed.Event onClick={() => onClickOfMessage(item)}>
                              <Feed.Label>
                                <Image
                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                    item.image
                                  )}
                                />
                              </Feed.Label>
                              <Feed.Content>
                                <Feed.Date content={item.time} />
                                <Feed.Summary>{item.message}</Feed.Summary>
                              </Feed.Content>
                            </Feed.Event>
                          );
                        })}
                      </Feed>
                    </Card.Content>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
              <MyProfile />
            </>
          )}
        </div>
      </div>
      <ChangePassword
        openModal={forgotPasswordStatus}
        closeModal={forgetPassword}
      />
    </React.Fragment>
  );
});

export default Header;
