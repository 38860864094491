import React from 'react';
import LoginPage from './login-page';

const Login = () => {

    return (
        <LoginPage />
    );
}

export default Login;