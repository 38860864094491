import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Header, Image } from 'semantic-ui-react'
import { WhiteLogo, DownArrow } from "../../shared/functional/global-image-import"
import { apiCall } from "../../../src/store/actions/api.actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { commonFunctions, env } from "../../shared/functional/global-import";

const trigger = (
  <span className="text">
    Select Your Role <Image className="downArrow" src={DownArrow} /> </span>)
const SignUpNewPage = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [stateData, setStateData] = useState([]);
  const [globalCodeData, setGlobalCodeData] = useState([]);
  const [, forceUpdate] = useState();
  const api = useSelector((state) => state.api);
  const globalCode = useSelector(state => state.global.codes);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const [teacherForm, setTeacherForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    roleId: null,
    schoolName: "",
    excelReferenceId: null,
    IsIndividualTeacher: true,
  });
  useEffect(() => {
    getGlobalCode();
  }, []);
  const onHandleSubmit = (e) => {
    const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
    if (isFormValid) {
      dispatch(
        apiCall({
          urls: ["TEACHERREGISTRATION"],
          method: "POST",
          data: teacherForm,
          onSuccess: (response) => {
            history.push({
              pathname: "resend-email",
              state: {
                email: teacherForm.email,
                firstName: teacherForm.firstName,
              },
            });
          },
          showNotification: true,
        })
      );
    }
  }
  const getGlobalCode = () => {
    dispatch(apiCall({
      urls: ["GLOBALCODELIST"], method: "GET", data: { "categoryId": -1 }, onSuccess: (response) => {
        getGlobal(response);
      }, showNotification: false
    }))
  }

  const getGlobal = (GlobalData) => {
    let roleCatergoryId = (commonFunctions.getGlobalCodeDetails(GlobalData, "TeacherRoles", "Teacher")).categoryId
    dispatch(apiCall({
      urls: ["GLOBALCODELIST"], method: "GET", data: { categoryId: roleCatergoryId },
      onSuccess: (response) => {
        const role = [{ text: "Select your role", value: null }]
        
        response
          .sort((a, b) => a.codeName.localeCompare(b.codeName.trim()))
          .map((singledata) => {
            role.push({ text: singledata.codeName, value: singledata.globalCodeId });
            return { ...singledata }
          });
        
        setStateData(role);
      }
    }))
  }

  const onHandleChange = (e, { value, data }) => {
    if (value === -1) {
      setTeacherForm({ ...teacherForm, [data]: null })
    }
    else {
      setTeacherForm({ ...teacherForm, [data]: value })
    }
  }

  const onHandleRole = (e, { value, data }) => {
    setTeacherForm((prevstate) => ({ ...prevstate, [data]: value === "" ? null : value }))
  }

  return (
    <div className="signUpWrapper">
      <div className="signUpNew">
        <Image className="logo" src={WhiteLogo} />
        <div className="signUpNewInner">
          <Header as="h2">Sign Up For ALEE</Header>
          <Form className="signUpForm ui grid">
            <div class="stackable wide two column row">

              <div className="column formGroup">
                <Form.Input
                  label="first name"
                  data="firstName"
                  onChange={onHandleChange}
                  error={simpleValidator.current.message("firstName", teacherForm.firstName, "required"
                  )}
                />
              </div>
              <div className="column formGroup">
                <Form.Input
                  label="last name"
                  data="lastName"
                  onChange={onHandleChange}
                  error={simpleValidator.current.message(
                    "lastName",
                    teacherForm.lastName,
                    "required"
                  )}
                />
              </div>
              <div className="column formGroup">
                <Form.Input
                  label="professional email"
                  data="email"
                  onChange={onHandleChange}
                  error={simpleValidator.current.message(
                  "email",teacherForm.email,"required|email")}/>
              </div>
            </div>
  
            <Button
              loading={api.isApiLoading}
              onClick={onHandleSubmit}
              className="signUpBtn">Sign Up</Button>
          </Form>
        </div>
        <div className="alreadyAccount">
          By signing up, I agree to the <a href="https://myalee.com/privacy-policy/" target="_blank">ALEE Privacy Policy</a>
          &nbsp;and <a href="https://myalee.com/terms-of-service/" target="_blank">Terms of Service</a>. Already have an account?
          <></>  <Link to={`${env.PUBLIC_URL}`}>Log in</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpNewPage;
