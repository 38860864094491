import React, { useState, useRef, useEffect } from "react";
import { BlueLogo, GoogleIcon } from "../../shared/functional/global-image-import";
import { Button, Form, Image, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { storeGlobalCodes, storeTags, apiCall, storeUserDetail, storeIsFullEngOpen, logout } from "../../store/actions";
import { useDispatch, useSelector } from 'react-redux';
import ForgotPasswordModal from "../../shared/components/organisms/modal/forgot-password/forgot-password";
import SimpleReactValidator from 'simple-react-validator';
import { commonFunctions, Notifications, Link, env } from "../../shared/functional/global-import";
import { useLocation } from "react-router-dom";
import SideBarDetailConfing from "../../config/sideBar.config"
import { executeLogin, onCheckRoleAndRoute, getGlobalCode } from "./login"


const LoginPage = () => {
    const history = useHistory();
    const [forgotPasswordStatus, setForgotPasswordStatus] = React.useState(false)
    const [fieldOptions, setFieldOptions] = useState([]);
    const [iconToggle, setIconToggle] = React.useState(false)
    const [, forceUpdate] = useState()
    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const [logInForm, setLogInForm] = useState({ email: "", password: "", rememberMe: false })
    const dispatch = useDispatch();
    const api = useSelector(state => state.api);
    const userDetail = useSelector(state => state.auth.userDetail);
    const search = useLocation().search;

    useEffect(() => {
        dispatch(storeIsFullEngOpen(false));

        if (userDetail?.token) {
            let roleOfUser = userDetail.role
            let isNotAdmins = roleOfUser === "Teacher" ? true : roleOfUser === "School" ? true :
                roleOfUser === "District" ? true : false;

            onCheckRoleAndRoute(dispatch, history, roleOfUser, userDetail, isNotAdmins);
        }
        else {
            getGlobalCode(dispatch);
        }
    }, []);

    const onHandleChange = (e, { value, data }) => {
        setLogInForm({ ...logInForm, [data]: value });
    }

    const onCheckBoxChange = (e, { data, checked }) => {
        setLogInForm({ ...logInForm, [data]: checked });
    }

    const onSubmit = (e) => {
        const isFormValid = commonFunctions.onHandleFormSubmit(e, simpleValidator, forceUpdate);
        if (isFormValid) {
            executeLogin(dispatch, history, logInForm);
        }
    }

    const forgetPassword = () => {
        setForgotPasswordStatus(!forgotPasswordStatus);
    };

    const passwordToggle = () => {
        setIconToggle(!iconToggle);
    }

    return (
        <div className="login">
            <div className="loginInner">
                <div className='loginInnerBox'>
                    <Image className='logo' src={BlueLogo} />
                    <Form onSubmit={onSubmit}>
                        <Form.Input placeholder="Email" data="email" type="email" name="email"
                            onChange={onHandleChange}
                            error={simpleValidator.current.message('email', logInForm.email, 'required|email')} fluid />
                        <div className="password">
                            <Form.Input type={iconToggle ? "" : "password"} placeholder='Password' data="password" onChange={onHandleChange}
                                error={simpleValidator.current.message('password', logInForm.password, 'required|password')}
                                fluid />
                            {!iconToggle && <Icon title="Show password" name="eye" className="primary-color passwordIcon" onClick={passwordToggle} />}
                            {iconToggle && <Icon title="Hide Password" name="eye slash" className="primary-color passwordIcon" onClick={passwordToggle} />}
                        </div>
                        <div className="forgotPassword">
                            <Form.Checkbox label='Remember me' data="rememberMe" onChange={onCheckBoxChange} />
                            <Link to="#" onClick={forgetPassword}>Forgot Password</Link>
                        </div>
                        <Button className="loginBtn" disabled={api.isApiLoading} loading={api.isApiLoading} fluid>Login</Button>
                    </Form>

                    {/* will do it later don't remove it  */}
                    {/* <p className='or'>Or</p>
                    <Button className='googleBtn' fluid><Image src={GoogleIcon} /><span>Log in with Google</span></Button> */}
                </div>
                <div className='notAccount'>Don't have an account?  <Link className='pointer' to={`${env.PUBLIC_URL}/sign-up-new`} >Sign up</Link></div>
            </div>
            {forgotPasswordStatus &&
                <ForgotPasswordModal openModal={forgotPasswordStatus} closeModal={forgetPassword} />
            }
        </div>
    )
}

export default LoginPage