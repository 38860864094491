import * as types from "../types"

export const storeMyBookData = (data) => {
    return { type: types.STORE_MY_BOOK_DATA, payload: data }
}

export const storeMenuCollapse = (data) => {
    return { type: types.STORE_MENU_COLLAPSE, payload: data }
}

export const storeIsFullEngOpen = (data) => {
    return { type: types.STORE_FULL_ENGAGEMENT_OPEN, payload: data }
}