//*************Configure all Sidebar **************
import { ManageTeacherGold, ManageTeacherWhite, PdfBookWhite, PdfBookGold, ResourceWhite, ResourceGold, SubAdminGold, SubAdminWhite, MyBookWhite, MyBookGold, AddTagListingGold, AddTagListingWhite,  UserManagementGold, UserManagementWhite, AddGradeWhite, AddGradeGold, SubscriptionGold, SubscriptionWhite, SchoolGold, SchoolWhite,
    LibraryWhite, LibraryGold, LessonWhite, LessonGold, StudentWhite, StudentGold,
    CoachWhite, CoachGold, PersonGold, PersonWhite, AddDistrictGold, AddDistrictWhite,
    RolesPermissionGold, RolesPermissionWhite, PromoCodeGold, PromoCodeWhite, DashboardGoldSvg, DashboardWhiteSvg, SubscribedUserGold, SubscribedUserWhite,CategoryWhite,CategoryGold,
    MessagesGold, MessagesWhite} from "../shared/functional/global-image-import";

const SideBarDetailConfing = [
    {   name: "Dashboard",
        route: "dashboard",
        role: ["Admin", "SubAdmin",],
        whiteImage: DashboardWhiteSvg,
        goldImage: DashboardGoldSvg,
        testPathName: /^dashboard$/
    },
    {
        name: "User Management",
        route: "user-management",
        role: ["Admin", "SubAdmin"],
        whiteImage: UserManagementWhite,
        goldImage: UserManagementGold,
        testPathName: /^user-management$/
    },
    {
        name: "Manage Teachers",
        route: "manage-teachers",
        role: ["Admin", "SubAdmin"],
        whiteImage: ManageTeacherWhite,
        goldImage: ManageTeacherGold,
        testPathName: /^manage-teachers$/
    },
    {
        name: "Upload Book",
        route: "upload-books",
        role: ["Admin", "SubAdmin",],
        whiteImage: PdfBookWhite,
        goldImage: PdfBookGold,
        testPathName: /^upload-books$/
    },
    {
        name: "ALEE Library",
        route: "my-books",
        role: ["Admin", "SubAdmin",],
        whiteImage: MyBookWhite,
        goldImage: MyBookGold,
        testPathName: /^my-books$/
    },
    {
        name: "Resources",
        route: "resources",
        role: ["Admin", "SubAdmin",],
        whiteImage: ResourceWhite,
        goldImage: ResourceGold,
        testPathName: /^resources$/
    },
    {
        name: "ALEE Coach",
        route: "alee-coachs",
        role: ["Admin", "SubAdmin",],
        whiteImage: CoachWhite,
        goldImage: CoachGold,
        testPathName: /^alee-coachs$/
    },
    {
        name: "Category",
        route: "add-category",
        role: ["Admin",],
        whiteImage: CategoryWhite,
        goldImage: CategoryGold,
    },
    {
        name: "Sub-Admin",
        route: "sub-admin",
        role: ["Admin", "SubAdmin",],
        whiteImage: SubAdminWhite,
        goldImage: SubAdminGold,
        testPathName: /^sub-admin$/
    },
    {
        name: "Tags",
        route: "add-tags",
        role: ["Admin", "SubAdmin",],
        whiteImage: AddTagListingWhite,
        goldImage: AddTagListingGold,
        testPathName: /^add-tags$/
    },
    {
        name: "Grades",
        route: "add-grades",
        role: ["Admin", "SubAdmin"],
        whiteImage: AddGradeWhite,
        goldImage: AddGradeGold,
        testPathName: /^add-grades$/
    },
    {
        name: "Subscription-Plan",
        route: "subscription-plan",
        role: ["Admin", "SubAdmin"],
        whiteImage: SubscriptionWhite,
        goldImage: SubscriptionGold,
        testPathName: /^subscription-plan$/
    },
    {
        name: "Subscribed-User",
        route: "subscribed-user",
        role: ["Admin", "SubAdmin"],
        whiteImage: SubscribedUserWhite,
        goldImage: SubscribedUserGold,
        testPathName: /^subscribed-user$/
    },

    // School Dashboard 
    {
        name: "Dashboard",
        route: "school-dashboard",
        role: ["School"],
        whiteImage: DashboardWhiteSvg,
        goldImage: DashboardGoldSvg,
        testPathName: /^school-dashboard$/
    },
    
    // District Dashboard 
    {
        name: "Dashboard",
        route: "district-dashboard",
        role: ["District"],
        whiteImage: DashboardWhiteSvg,
        goldImage: DashboardGoldSvg,
        testPathName: /^district-dashboard$/
    },
    // teacher

    {
        name: "Dashboard",
        route: "teacher-dashboard",
        role: ["Teacher"],
        whiteImage: DashboardWhiteSvg,
        goldImage: DashboardGoldSvg,
        testPathName: /^teacher-dashboard$/
    },
    {
        name: "Library",
        route: "teacher-library",
        role: ["Teacher", "School", "District"],
        whiteImage: LibraryWhite,
        goldImage: LibraryGold,
        testPathName: /^teacher-library$/
    },
    {
        name: "Lessons",
        route: "teacher-lesson",
        role: ["Teacher",],
        whiteImage: LessonWhite,
        goldImage: LessonGold,
        testPathName: /^teacher-lesson$/
    },
    {
        name: "Students",
        route: "teacher-student",
        role: ["Teacher",],
        whiteImage: StudentWhite,
        goldImage: StudentGold,
        testPathName: /^teacher-student$/
    },
    // School
    {
        name: "Add-teacher",
        route: "school-teacher",
        role: ["School"],
        whiteImage: StudentWhite,
        goldImage: StudentGold,
        testPathName: /^school-teacher$/
    },
    {
        name: "My-teachers",
        route: "my-teachers",
        role: ["School"],
        whiteImage: PersonWhite,
        goldImage: PersonGold,
        testPathName: /^my-teachers$/
    },
    //  district 

    {
        name: "My-schools",
        route: "my-schools",
        role: ["District"],
        whiteImage: SchoolWhite,
        goldImage: SchoolGold,
        testPathName: /^my-schools$/
    },
    {
        name: "ALEE Coach",
        route: "alee-coach",
        role: ["Teacher", "School", "District",],
        whiteImage: CoachWhite,
        goldImage: CoachGold,
        testPathName: /^alee-coach$/
    },
   
    // Dummy //
    {
        name: "Schools",
        route: "add-schools",
        role: ["Admin", "SubAdmin"],
        whiteImage: SchoolWhite,
        goldImage: SchoolGold,
        testPathName: /^add-schools$/
    },
    {
        name: "Districts",
        route: "add-districts",
        role: ["Admin", "SubAdmin"],
        whiteImage: AddDistrictWhite,
        goldImage: AddDistrictGold,
        testPathName: /^add-districts$/
    },

    {
        name: "Promo Code",
        route: "add-promo-codes",
        role: ["Admin", "SubAdmin"],
        whiteImage: PromoCodeWhite,
        goldImage: PromoCodeGold,
        testPathName: /^add-promo-codes$/
    },
    {
        name: "Roles & Permissions",
        route: "roles-permission",
        role: ["Admin",],
        whiteImage: RolesPermissionWhite,
        goldImage: RolesPermissionGold,
        testPathName: /^roles-permission$/
    },

    {
        name: "Messages",
        route: "messages",
        role: ["Admin","SubAdmin"],
        whiteImage: MessagesWhite,
        goldImage: MessagesGold,
        testPathName: /^messages$/
    },
];


export default SideBarDetailConfing;
