import React, { useState, useRef } from "react";
import { Grid, Modal, Form, Button, } from "semantic-ui-react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../../store/actions/api.actions";
import { Notifications, commonFunctions } from "../../../../functional/global-import";
// import SimpleReactValidator from 'simple-react-validator';
import "react-multi-email/style.css";

const AddLessonEmail = (props) => {
    const [emails, setEmailId] = useState([]);
    const [emailLoader, setEmailLoader] = useState(false);
    const dispatch = useDispatch();
    // const [, forceUpdate] = useState();
    // const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }))
    const onHandleSubmit = (e) => {

        if (emails.length === 0) {
            dispatch(Notifications.show({
                title: "Error",
                message: 'Email Field is required.',
                position: 'br', autoDismiss: 5
            }, "error"))
            return
        }
        else {
            setEmailLoader(true);
            let emailPdf = []
            emails?.map((emailObject) => {
                emailPdf.push({
                    teacherEmail: emailObject
                })
                return { ...emailObject }
            })
            dispatch(
                apiCall({
                    urls: ["LESSONPLANEMAILPDF"],
                    method: "POST",
                    data: { teacherEmails: emailPdf, lessonPlanId: props.lessonPdfId },
                    onSuccess: (response) => {
                        props.closeModal();
                    }, onFinally: () => {
                        setEmailLoader(false);
                    }, showNotification: true

                })
            );

        }

    };
    const closeModal = () => {
        props.closeModal();
    }
    const showErrorMassage = () => {
        dispatch(Notifications.show({
            title: "Error",
            message: 'Please enter correct email.',
            position: 'br', autoDismiss: 2
        }, "error"))
        return;
    }
    return (


        <Modal
            open={props.open}
            closeIcon
            onClose={closeModal}
            closeOnDimmerClick={false}
            size={"mini"}>
            <Modal.Header>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <>
                        {emailLoader && commonFunctions.showLoader()}
                        <Form>
                            <Grid>
                                <Grid.Column>
                                    <h3>Email</h3>
                                    <ReactMultiEmail
                                        placeholder="Email"
                                        emails={emails}
                                        validateEmail={(emails) => {

                                            const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                            if (!emailReg.test(emails)) {
                                                showErrorMassage()
                                            }
                                            return isEmail(emails)

                                        }}

                                        onChange={(emails) => {
                                            setEmailId([...emails])
                                        }}
                                        getLabel={(email, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {email}
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>  ×</span>
                                                </div>
                                            );
                                        }}

                                    />
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>

                <Button className="primaryBtn" onClick={onHandleSubmit} > Send </Button>
            </Modal.Actions>
        </Modal>
    );
}
export default AddLessonEmail;