import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Modal,
  Popup,
  Input,
  Checkbox
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../../store/actions/api.actions";
import SimpleReactValidator from "simple-react-validator";
import {
  commonFunctions,
  Notifications,
} from "../../../../functional/global-import";
import { PhoneNumberInput } from "../../../../components";



function AddDistrictSchool(props) {
  const initialValues = {
    schoolId: null,
    schoolName: "",
    schoolEmail: null,
    districtId: props.districtId,
    numberofSeatsForLicense: null,
    numberOfStudentsforLicense: null,
    numberOfStudents: null,
    isAdminAdded:true,
    grade: [],
    adminDetails: [{adminName: "",
      adminEmail: "",
      adminPhoneNumber: null,
      adminHiddenValue: false,
    }],
    numberOfAdmin: null,
    accountManagerName: null,
    accountManagerEmail: null,
    accountManagerPhoneNumber: null,
    schoolAddress: null,
    districtAndNetworkName: null,
    notes: null,
    studentPercentageIEPs: null,
    studentPercentageENL: null,
    studentsFreeAndReducedLunch: null,
    studentPriceNegotiated: null,
    totalPricePerYear: null,
    paymentTerms: null,
    accountExpiration: null,
  };
  const [schoolDetail, setSchoolDetail] = useState(initialValues);
  const [schoolLoader, setSchoolLoader] = useState(false);
  const [onPhoneChanged, setOnPhoneChanged] = useState(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  let schoolDistrict = props.viewModal
  const onHandleChange = (e, { value, data }) => {
    setSchoolDetail({ ...schoolDetail, [data]: value });
  };
  const [grade, setGradeList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getGradeList();
  }, []);
  const getGradeList = () => {
    dispatch(
      apiCall({
        urls: ["GETGRADESLIST"],
        method: "GET",
        data: {
          ActiveGrades: true,
          OrderBy: "GradeName",
          isActive: true,
          OrderByDescending: false,
          pageNo: 1,
          pageSize: 1000,
        },
        onSuccess: (response) => {
          const grade = [{ text: "Select Grade", value: null }];
          response.map((singledata) => {
            grade.push({
              text: singledata.gradeName,
              value: singledata.gradeId,
            });
            return { ...singledata };
          });
          setGradeList(grade);
        },
      })
    );
  };
  const onClose = () => {

    onPhoneChanged && props?.GridReload()
    props.closeModal(false);
  };

  const handleAddRow = () => {
    const addRow = { adminName: "", adminEmail: "", adminPhoneNumber: "" };
    const { adminDetails } = schoolDetail;
    let addValue = adminDetails.concat(addRow);
    setSchoolDetail({ ...schoolDetail, adminDetails: addValue });
  };
  const handleRemoveSpecificRow = (index) => {
    if (schoolDetail.adminDetails.length > 1) {
      const adminDetails = [...schoolDetail.adminDetails];
      adminDetails.splice(index, 1);
      setSchoolDetail({ ...schoolDetail, adminDetails });
    }
  };
  const onChangeAdmin = (e, { data, value, index }) => {
    const adminName = [...schoolDetail.adminDetails];
    adminName[index][data] = value;
    setSchoolDetail({ ...schoolDetail });
  };
  const onHandleSubmit = (e) => {
    let gradeSelected = [];
    schoolDetail.grade &&
      schoolDetail.grade.map((singleObj) => {
        gradeSelected.push({
          gradeId: singleObj,
        });
        return { ...singleObj };
      });
    schoolDetail.grades = gradeSelected;
    let newTitle =
      schoolDetail.schoolName === null
        ? null
        : schoolDetail.schoolName.charAt(0).toUpperCase() +
        schoolDetail.schoolName.slice(1);
    schoolDetail.schoolName = newTitle;
    const isFormValid = commonFunctions.onHandleFormSubmit(
      e,
      simpleValidator,
      forceUpdate
    );
    if (isFormValid) {
      let isEmailMatched = false;
      schoolDetail.adminDetails.map((singleEmail, parentIndex) => {
        schoolDetail.adminDetails.map((singleObjEmail, childIndex) => {
          if (
            parentIndex !== childIndex &&
            singleEmail.adminEmail === singleObjEmail.adminEmail
          ) {
            isEmailMatched = true;
          }
          return singleObjEmail;
        });
        return singleEmail;
      });
      if (isEmailMatched) {
        dispatch(
          Notifications.show(
            {
              title: "Error",
              message: "Same Email used, please check email's.",
              position: "br",
              autoDismiss: 2,
            },
            "error"
          )
        );
        return true;
      }
      setSchoolLoader(true);
       const isAdminTrue = !schoolDetail.isAdminAdded ? [] : schoolDetail.adminDetails
      schoolDetail.adminDetails = isAdminTrue;
      dispatch(
        apiCall({
          urls: ["ADDSCHOOLDETAIL"],
          method: "POST",
          data: schoolDetail,
          onSuccess: (response) => {
            onClose();
            setSchoolDetail(initialValues);
            props.GridReload();
          },
          onFinally: () => {
            setSchoolLoader(false)
          },
          actionOfMethod: schoolDetail.schoolId > 0 ? "Edit" : "Add",
          showNotification: true,
        })
      );
    }
  };
  useEffect(() => {
    if (props.modalType === "EDIT") {
      editdistrictDetail();
    }
  }, [props.openModal]);

  const editdistrictDetail = () => {
    if (props.districtEditDetail) {
      const {
        schoolId,
        schoolName,
        schoolEmail,
        districtId,
        numberofSeatsForLicense,
        numberOfStudentsforLicense,
        numberOfStudents,
        grade,
        adminDetails,
        numberOfAdmin,
        accountManagerName,
        accountManagerEmail,
        accountManagerPhoneNumber,
        schoolAddress,
        districtAndNetworkName,
        notes,
        studentPercentageIEPs,
        studentPercentageENL,
        studentPriceNegotiated,
        studentsFreeAndReducedLunch,
        totalPricePerYear,
        paymentTerms,
      } = props.districtEditDetail;

      setSchoolDetail({
        ...schoolDetail,
        districtId: districtId,
        schoolName: schoolName,
        schoolEmail: schoolEmail,
        schoolId: schoolId,
        numberOfStudentsforLicense: numberOfStudentsforLicense,
        numberofSeatsForLicense: numberofSeatsForLicense,
        numberOfStudents: numberOfStudents,
        adminDetails: adminDetails,
        grade: grade,
        numberOfAdmin: numberOfAdmin,
        accountManagerName: accountManagerName,
        accountManagerEmail: accountManagerEmail,
        accountManagerPhoneNumber: accountManagerPhoneNumber,
        schoolAddress: schoolAddress,
        districtAndNetworkName: districtAndNetworkName,
        notes: notes,
        studentPercentageIEPs: studentPercentageIEPs,
        studentPercentageENL: studentPercentageENL,
        studentPriceNegotiated: studentPriceNegotiated,
        studentsFreeAndReducedLunch: studentsFreeAndReducedLunch,
        totalPricePerYear: totalPricePerYear,
        paymentTerms: paymentTerms,
      });
    }
  };
  const onHandlePrice = (e, { value, type, checked, data, index }) => {

    setOnPhoneChanged(true)
    schoolDetail.adminDetails[index][data] = value;
    setSchoolDetail({ ...schoolDetail });
  };

  const onHandlePriceChange = (e, { value, data }) => {
    let re = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (e.target.value.match(re)) {
      setSchoolDetail({ ...schoolDetail, [data]: e.target.value });
    } else {
      setSchoolDetail({ ...schoolDetail, [data]: "" });
    }
  };
  const onHandleGrade = (e, { data, value }) => {
    if (value[value.length - 1]) {
      setSchoolDetail({ ...schoolDetail, [data]: value });
    } else if (value.length === 0) {
      setSchoolDetail({ ...schoolDetail, [data]: value });
    }
  };
  const priceValidationError = simpleValidator.current.message(
    "PercentageIEPs",
    schoolDetail.studentPercentageIEPs,
    "required|max:100,num"
  );
  const priceValidationstudentPercentageENL = simpleValidator.current.message(
    "PercentageENL",
    schoolDetail.studentPercentageENL,
    "required|max:100,num"
  );
  const priceValidationstudentPercentage = simpleValidator.current.message(
    "FreeAndReducedLunch",
    schoolDetail.studentsFreeAndReducedLunch,
    "required|max:100,num"
  );
  const onHandleChangeShowAdmin = (e, { data, value, checked, type }) => {

    if (type === "checkbox") {
      setSchoolDetail({ ...schoolDetail, [data]: checked })
    }
  };
  simpleValidator?.current?.purgeFields();
  return (
    <Modal
      open={props.openModal}
      onClose={props.closeModal}
      size="large"
      closeOnDimmerClick={false}
    >
      {schoolDistrict ? <Modal.Header> View  School Detail</Modal.Header> :
        <Modal.Header>
          {schoolDetail.schoolId ? "Edit School Detail" : "School Detail"}{" "}
        </Modal.Header>}
      <Modal.Content scrolling>
        <Modal.Description>
          {schoolLoader && commonFunctions.showLoader()}
          <div className="common-shadow">
            <Form>
              <Grid columns={3}>
                <Grid.Column>
                  <Form.Input
                    placeholder="Name of schools"
                    label="Name of schools *"
                    data="schoolName"
                    disabled={schoolDistrict}
                    value={schoolDetail.schoolName}
                    onChange={onHandleChange}
                    error={simpleValidator.current.message(
                      "schoolName",
                      schoolDetail.schoolName,
                      "required"
                    )}
                  />
                </Grid.Column>

                <Grid.Column width={16}>
                  <Divider fitted />
                  <Header as="h3">For each school</Header>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Number of seats/license"
                    label="Number of seats/license *"
                    data="numberofSeatsForLicense"
                    disabled={schoolDistrict}
                    value={schoolDetail.numberofSeatsForLicense}
                    onChange={onHandlePriceChange}
                    error={simpleValidator.current.message(
                      "Seats For License",
                      schoolDetail.numberofSeatsForLicense,
                      "required"
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Number of students for the license *"
                    placeholder="Number of students for the license"
                    disabled={schoolDistrict}
                    data="numberOfStudentsforLicense"
                    value={schoolDetail.numberOfStudentsforLicense}
                    onChange={onHandlePriceChange}
                    error={simpleValidator.current.message(
                      "Students for License",
                      schoolDetail.numberOfStudentsforLicense,
                      "required"
                    )}
                  />
                </Grid.Column>

                <Grid.Column>
                  <Form.Dropdown
                    placeholder="Grade"
                    label="Grade(s) *"
                    fluid
                    multiple
                    selection
                    options={grade}
                    disabled={schoolDistrict}
                    data="grade"
                    value={schoolDetail.grade}
                    onChange={onHandleGrade}
                    error={simpleValidator.current.message("grade", schoolDetail.grade, "required")}
                  />
                </Grid.Column>

                <Grid.Column>
                  <div className={
                    !priceValidationError
                      ? "customPhoneInputField"
                      : "customPhoneInputField error"
                  }>
                    <label>Percentage of students with IEP *</label>
                    <Input
                      disabled={schoolDistrict}
                      placeholder="Students with IEP"
                      data="studentPercentageIEPs"
                      value={schoolDetail.studentPercentageIEPs}
                      label={{ basic: true, content: "%" }}
                      labelPosition="right"
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "studentPercentage",
                      schoolDetail.studentPercentageIEPs,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={
                    !priceValidationstudentPercentageENL
                      ? "customPhoneInputField"
                      : "customPhoneInputField error"
                  }>
                    <label>Percentage of ENL students *</label>
                    <Input
                      placeholder="Students of ENL "
                      label={{ basic: true, content: "%" }}
                      labelPosition="right"
                      data="studentPercentageENL"
                      disabled={schoolDistrict}
                      value={schoolDetail.studentPercentageENL}
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "studentPercentage",
                      schoolDetail.studentPercentageENL,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div className={
                    !priceValidationstudentPercentage
                      ? "customPhoneInputField"
                      : "customPhoneInputField error"
                  }>
                    <label>Percentage free and reduced lunch *</label>
                    <Input
                      label={{ basic: true, content: "%" }}
                      labelPosition="right"
                      placeholder="Free and reduced lunch"
                      disabled={schoolDistrict}
                      data="studentsFreeAndReducedLunch"
                      value={schoolDetail.studentsFreeAndReducedLunch}
                      onChange={onHandlePriceChange}
                    />
                    {simpleValidator.current.message(
                      "Percentage free",
                      schoolDetail.studentsFreeAndReducedLunch,
                      "required|min:1,num|max:100,num"
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Number of admin accounts"
                    placeholder="Number of admin accounts"
                    data="numberOfAdmin"
                    disabled={schoolDistrict}
                    value={schoolDetail.adminDetails.length}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                  <div className="showAdminCheckbox">
                    <Header as="h4">Admin Details</Header>
                    {/* <Checkbox
                      onChange={onHandleChangeShowAdmin}
                      data="isAdminAdded"
                      checked={schoolDetail.isAdminAdded}
                      label='Make admin details required' /> */}

                  </div>
                </Grid.Column>
                {schoolDetail?.adminDetails?.map((item, index) => {
                  const schoolAdminValidation = schoolDetail.isAdminAdded ? simpleValidator.current.message(`admin ${index + 1}Name`, schoolDetail.adminDetails[index].adminName, "required") : "";
                  const schoolEmailValidation = schoolDetail.isAdminAdded ? simpleValidator.current.message(`admin ${index + 1}Email `, schoolDetail.adminDetails[index].adminEmail, "required") : "";
                  return (
                    <>
                      <Grid.Column>
                        <Form.Field className={schoolDetail.isAdminAdded ? !schoolAdminValidation ?
                          "amountInput customPhoneInputField" : "amountInput customPhoneInputField error" : null}>
                          <Form.Input
                            placeholder="Admin"
                            label="Admin *"
                            data="adminName"
                            disabled={schoolDistrict}
                            index={index}
                            onChange={onChangeAdmin}
                            value={item.adminName}

                          />
                          {schoolDetail.isAdminAdded ? <>
                            {simpleValidator.current.message(`admin ${index + 1}Name`, schoolDetail.adminDetails[index].adminName, "required")}
                          </> : ""}
                        </Form.Field>

                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field
                          className={schoolDetail.isAdminAdded ? !schoolEmailValidation ?
                            "amountInput customPhoneInputField" : "amountInput customPhoneInputField error" : null}>
                          <Form.Input
                            className="opacity-1"
                            placeholder="Email"
                            label="Email *"
                            disabled={item.adminHiddenValue === true ? true : false}
                            data="adminEmail"
                            index={index}
                            onChange={onChangeAdmin}
                            value={item.adminEmail}
                          />
                          {schoolDetail.isAdminAdded ?
                            <>
                              {simpleValidator.current.message(`admin ${index + 1} Email `,
                                schoolDetail.adminDetails[index].adminEmail,
                                "required|email"
                              )}
                            </> : ""}

                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <div className="customPhoneInput">
                          <PhoneNumberInput
                            onChange={(value, country, e, formattedValue) =>
                              onHandlePrice(e, {
                                name: "phoneNumber",
                                value: formattedValue,
                                type: "phoneNumber",
                                data: "adminPhoneNumber",
                                index
                              })
                            }
                            value={item.adminPhoneNumber}
                            disabled={schoolDistrict}
                            error={schoolDetail.isAdminAdded ? simpleValidator.current.message(
                              `Phone ${index + 1} Number`,
                              schoolDetail.adminDetails[index].adminPhoneNumber,
                              "required|phone"
                            ) : ""}
                          />
                          <label>Phone number *</label>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2} textAlign="">

                        <div className="mt-24 adminActionBtn">
                          {schoolDetail.schoolId === null ? index !== 0 && (
                            <Popup content="Delete" position='top center' trigger={<Button disabled={schoolDistrict}
                              className="deleteBtn" icon="trash" onClick={() => handleRemoveSpecificRow(index)} />} />
                          ) :
                            <Popup content="Delete" position='top center' trigger={<Button disabled={schoolDistrict}
                              className="deleteBtn" icon="trash" onClick={() => handleRemoveSpecificRow(index)} />} />
                          }
                          {schoolDetail?.adminDetails.length - 1 === index && (
                            <Popup content="Add" position='top center' trigger={<Button disabled={schoolDistrict} icon="plus" onClick={handleAddRow} className='addBtn' />}
                            />
                          )}
                        </div>


                      </Grid.Column>
                    </>
                  );
                })}
                <Grid.Column width={16}>
                  <Divider fitted />
                  <Header as="h4">School Information</Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="School address"
                    label="School address * "
                    data="schoolAddress"
                    disabled={schoolDistrict}
                    onChange={onHandleChange}
                    value={schoolDetail.schoolAddress}
                    error={simpleValidator.current.message(
                      "schoolAddress",
                      schoolDetail.schoolAddress,
                      "required")}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    placeholder="District/Network name"
                    label="District/Network name *"
                    data="districtAndNetworkName"
                    disabled={schoolDistrict}
                    error={simpleValidator.current.message(
                      "districtAndNetworkName",
                      schoolDetail.districtAndNetworkName,
                      "required"
                    )}
                    onChange={onHandleChange}
                    value={schoolDetail.districtAndNetworkName}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Form.TextArea

                    placeholder="Notes"
                    label="Notes"
                    data="notes"
                    disabled={schoolDistrict}
                    onChange={onHandleChange}
                    value={schoolDetail.notes}
                  //error={simpleValidator.current.message('notes',schoolDetail.notes,'required')}
                  />
                </Grid.Column>
              </Grid>
            </Form>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {schoolDistrict ? <Button className="secondaryBtn" onClick={onClose}>
          Cancel
        </Button> :
          <>
            <Button className="secondaryBtn" onClick={onClose}>
              Cancel
            </Button>
            <Button className="primaryBtn" onClick={onHandleSubmit}>
              {schoolDetail.schoolId ? "Update" : "Save"}
            </Button>
          </>}

      </Modal.Actions>
    </Modal>
  );
}

export default AddDistrictSchool;
