import React, { useState, useEffect, useRef } from "react";
import { Grid, Table, Input } from "semantic-ui-react";
import TableHeader from "./table-header";
import TableRow from "./table-row";
import { useDispatch } from 'react-redux';
import { apiCall } from "../../../../store/actions/api.actions";
import ConfirmModal from "../../../components/organisms/modal/common-confirm-modal/index";
import { commonFunctions } from "../../../functional/global-import";



const { forwardRef, useImperativeHandle } = React;
const DataTable = forwardRef((props, ref) => {                                       
    // function DataTable(props) {
    // const { forwardRef, useImperativeHandle } = React;
    const [values, setValues] = useState([]);
    const [gridObjects, setGridObjects] = useState({ pageNo: 1,pageSize: 10,sortArrow: "sort", orderBy: "ModifiedDate", searchValue: "",
        orderByDescending: true, heading: "", hasMore: true
    })
    const [confirmModal, setConfirmModal] = useState({
        modalStatus: false,
        selectedId: "", type: "", isActive: null
    })
    const dispatch = useDispatch();
    const tableRowRef = useRef();
    // const api = useSelector(state => state.api)
    const [gridDataLoading, setGridDataLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        getCommonTable();
    }, [gridObjects,]);


    useImperativeHandle(ref, () => ({
        getCommonTable() {
            getCommonTable()
        },
        setValues(data) {
            setValues(data)
        },
        onSetDefaultValues() {
            onSetDefaultValues()
        },
        values,
    }), [values]);

    const onSetDefaultValues = () => {
        setGridObjects({
            ...gridObjects, pageNo: 1, pageSize: 10, sortArrow: "sort", orderBy: "ModifiedDate", orderByDescending: true,
        })
    }

    const getCommonTable = () => {
        setGridDataLoading(true)
        // let dataDetail = gridObjects.sortArrow === "sort" ? { ...gridObjects, ...props.additionalParams }
        //     : null
        if (gridObjects.sortArrow === "sort") {
        }
        else {
            props?.additionalParams?.orderBy && delete props.additionalParams.orderBy

            if (props?.additionalParams) {
                for (var key in props.additionalParams) {
                    if (key === "orderByDescending") {
                        delete props.additionalParams.orderByDescending
                    }
                }
            }

            // delete props.additionalParams.orderByDescending
        }
        dispatch(apiCall({
            urls: [props.allApi.getApiName], method: "GET",
            data: { ...gridObjects, ...props.additionalParams },
            onSuccess: (response) => {

                let responseData = gridObjects.pageNo === 1 ? response :
                    values.concat(response);
                // this.props.allFunction.setState({ ...this.props.fullState, gridDetail: responseData })
                setValues(responseData)
                if (response.length !== 10) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }
            },
            onFinally: () => { setGridDataLoading(false) } }))
    }
    const confirmModalOpen = (id, type, isActive) => {
        setConfirmModal({ ...confirmModal, modalStatus: true, selectedId: id, type: type, isActive: isActive })
    }
    const modalClose = () => {
        setConfirmModal({ ...confirmModal, modalStatus: !confirmModal.modalStatus, selectedId: "" })
    }

    const upDateToggle = () => {
        dispatch(apiCall({
            urls: [props.allApi.toggleApiName], method: "PATCH", data: { id: confirmModal.selectedId },
            onSuccess: (response) => {
                props?.tagStore?.()
                modalClose();
                // getCommonTable();
                onSetDefaultValues();
            }, actionOfMethod: "Edit",
            showNotification: true
        }))
    }

    const onHandleDelete = () => {
        dispatch(apiCall({
            urls: [props.allApi.deleteApiName], method: "DELETE", data: { id: confirmModal.selectedId },
            onSuccess: (response) => {

                props?.tagStore?.()
                modalClose();
                // getCommonTable();
                onSetDefaultValues();
            }, showNotification: true
        }))
    }
    const onHandleSorting = (heading) => {

        let orderBy = heading.charAt(0).toUpperCase() + heading.slice(1);
        // let orderByDescending = gridObjects.orderByDescending === false ? true : false;
        let orderByDescending = gridObjects.orderByDescending === false ? true : false;
        let sortArrow = gridObjects.sortArrow === "sort up" ? "sort down" : "sort up";
        setGridObjects({ ...gridObjects, pageNo: 1, sortArrow: sortArrow, heading: heading, orderBy: orderBy, orderByDescending: orderByDescending })
    }
    const fetchMoreData = () => {
        const countPageNo = gridObjects.pageNo + 1;
        // 20 more records in 1.5 secs  
        setTimeout(() => {
            setGridObjects({ ...gridObjects, pageNo: countPageNo })
        }, 500);
    };
    const onHandleChangeSearch = (e, { value }) => {
        setGridObjects({ ...gridObjects, searchValue: value, pageNo: 1 });
    }
    // console.log(`values , ${JSON.stringify(values)}`)
    const modalType = (confirmModal.type === "delete" ? onHandleDelete : upDateToggle)
    const showMessage = props.messageInModal !== undefined ? props.messageInModal : "record"
    const messages = confirmModal.isActive ? "deactivate" : "activate"
    const message = "Are you sure you want to " + (confirmModal.type === "update" ? messages : confirmModal.type) + " this " + showMessage + "?"
    const { columns, isToFilterColumn } = props;

    // const newColumns = columns.filter((item) => {
    //     return filterData.indexOf(item.id) === -1
    // })
    
    const newColumns = isToFilterColumn ? columns.filter((singleColumnObj) => {
        return singleColumnObj.isToShowColumn === true
    })
        : columns

    return (
        <Grid>
            {props.searchOption && props.searchOption.show &&
                <Grid.Column computer={8} tablet={8}>
                    <Input fluid icon="search" name="searchValue" data="searchValue" iconPosition="left" placeholder={props.searchOption.placeHolder ? props.searchOption.placeHolder : "Search"}
                        className="common-search-bar" onChange={onHandleChangeSearch} />
                </Grid.Column>
            }
            <Grid.Column width={16}>
                <div>
                    {gridDataLoading && commonFunctions.showLoader()}
                    <div className="commonTable">
                        <Table fixed singleLine className="table-scrolling commonTableDropdown" id={props.gridName}>

                            <TableHeader
                                columns={newColumns}
                                onHandleSorting={onHandleSorting}
                                gridObjects={gridObjects}
                            />
                            <TableRow singleLine
                                columns={newColumns}
                                gridName={props.gridName}
                                ref={tableRowRef}
                                gridData={values}
                                getCommonTable={getCommonTable}
                                confirmModalOpen={confirmModalOpen}
                                fetchMoreData={fetchMoreData}
                                tableHeight={props.tableHeight}
                                hasMore={hasMore}

                            />

                        </Table>
                    </div>
                </div>
            </Grid.Column>
            <ConfirmModal open={confirmModal} onConfirm={modalType} close={modalClose} message={message} data={props} />
        </Grid>
    );
}
)

export default DataTable;


