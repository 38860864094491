import { env } from '../../shared/functional/global-import';
import SignUpNew from '../../pages/sign-up-new';
import LoginPage from '../../pages/login/login-page';
import Login from '../../pages/login';
import VerifyMeassge from '../../pages/verify-meassge';
import ResendEmail from '../../pages/verify-email';
import CompleteYourPayment from '../../pages/complete-your-payment';
import Reset from '../../pages/reset-password';
import PaymentMessage from '../../pages/payment-message';

const NoTemplateRoutesConfing = [
  {
    exact: true,
    private: true,
    path: `${env.PUBLIC_URL}/complete-your-payment`,
    component: CompleteYourPayment
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/sign-up-new`,
   
    component: SignUpNew
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL === '' ? '/' : env.PUBLIC_URL}`,
  
    component: Login
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/resend-email`,
    component: ResendEmail
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/verify-email`,
    component: VerifyMeassge
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/reset-password`,
    component: Reset
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/payment-message`,
    component: PaymentMessage
  },
];


export default NoTemplateRoutesConfing;