import { storeGlobalCodes, storeTags, apiCall, storeUserDetail, logout } from "../../store/actions";
import { Notifications, env } from "../../shared/functional/global-import";
import SideBarDetailConfing from "../../config/sideBar.config"

export const executeLogin = (dispatch, history, logInForm) => {
    dispatch(apiCall({
        urls: ["LOGIN"], method: "POST", data: logInForm,
        onSuccess: (response) => {
            if (response.isSuccess) {
                if (response?.roleAndPermission?.roleAndPermission) {
                    const screenList = JSON.parse(response?.roleAndPermission?.roleAndPermission).map((screen, index) => {
                        if (screen.Permissions !== null) {
                            const permissionlist = JSON.parse(screen.Permissions);
                            const permissionDetail = permissionlist.map((permission, index) => {
                                const actionDetails = JSON.parse(permission.Actions ? permission.Actions : null);
                                return {
                                    ScreenName: permission.ScreenName, screenId: permission.ScreenId, Access: permission.Access, action: actionDetails
                                }
                            })
                            return {
                                Access: screen.Access, ModuleId: screen.ModuleId, ModuleName: screen.ModuleName, permission: permissionDetail
                            }
                        }
                        else {
                            return {
                                Access: screen.Access, ModuleId: screen.ModuleId, ModuleName: screen.ModuleName, permission: screen.Permissions
                            }
                        }
                    })
                    response.roleAndPermission = screenList
                }
                else {response.roleAndPermission = null}
                response.teacherId = response?.teacherId === 0 ? null : response?.teacherId
                let loginUserRole = response.role
                let isTeacher = loginUserRole === "Teacher" ? true : false
                response.isTeacher = isTeacher
                let isNotAdmin = loginUserRole === "Teacher" ? true : loginUserRole === "School" ? true :
                    loginUserRole === "District" ? true : false

                dispatch(storeUserDetail(response));
                onCheckRoleAndRoute(dispatch, history, loginUserRole, response, isNotAdmin);
            }

            getCustomFields(dispatch);
            getGlobalCode(dispatch);
        }, showNotification: true
    }));
};
    
export const onCheckRoleAndRoute = (dispatch, history, loginUserRole, response, isNotAdmin) => {
    if (loginUserRole === "Admin") {
        history.push(`${env.PUBLIC_URL}/dashboard`);
    }
    
    else if (loginUserRole === "SubAdmin") {
        let sideBar = loginUserRole && SideBarDetailConfing?.filter((sideObj) => sideObj.role.includes(loginUserRole))
        let moduleName = response.roleAndPermission && response.roleAndPermission.length > 0 && response.roleAndPermission?.filter((item) => {
            return item.Access === 1 && item.permission
        });

        let allowedScreens = []
        moduleName && moduleName.length > 0 && moduleName?.map((item) => {
            item.permission.map((item2) => {
                if (item2.Access) {
                    allowedScreens.push(item2)
                }
                else {
                    return { ...item2 }
                }
            })
        })
        sideBar = sideBar?.filter((sideObj) => {
            let data = allowedScreens.filter((singleAllowedObj) => {
                return singleAllowedObj.ScreenName.toLowerCase() === sideObj.route.toLowerCase()
            })
            if (data?.length > 0) {
                return sideObj
            }
        })
        history.push(`${env.PUBLIC_URL}/${sideBar[0].route}`);
    }
    else if (loginUserRole === "School") {

        response.emailConfirmed ? history.push(`${env.PUBLIC_URL}/teacher-library`) :
            history.push(`${env.PUBLIC_URL}/school-profile`);

    }
    else if (loginUserRole === "District") {
        response.emailConfirmed ? history.push(`${env.PUBLIC_URL}/teacher-library`) :
            history.push(`${env.PUBLIC_URL}/district-profile`);
    }
    else if (isNotAdmin) {
        response.subscriptionExpired ? 
            history.push(`${env.PUBLIC_URL}/complete-your-payment`) :
            history.push(`${env.PUBLIC_URL}/teacher-library`);
    }
    else {
        dispatch(Notifications.show({
            title: "Error",
            message: 'No role assigned',
            position: 'br', autoDismiss: 5
        }, "error"));
        dispatch(logout());
    }
}

export const getCustomFields = (dispatch) => {
    let tag = [];
    dispatch(apiCall({
        urls: ["ENGAGEMENTCUSTOMFIELDS"], method: "GET", data: {
            pageNo: 1, pageSize: 100, searchValue: "", orderBy: "ModifiedDate", orderByDescending: "true", AllRecords: true},
        onSuccess: (response) => {
            response.map((responseObj) => {
                                                        
                let tags = responseObj?.tags && JSON.parse(responseObj.tags)
                const res = tags.map((single) => {
                    return { value: single.TagId, text: single.TagName }
                });
                tag.push({ [responseObj.fieldName]: res })
            })
            dispatch(storeTags(tag));
        }
    }));
}

export const getGlobalCode = (dispatch) => {
    dispatch(apiCall({
        urls: ["GLOBALCODELIST"], method: "GET", data: { "categoryId": -1 }, onSuccess: (response) => {
            dispatch(storeGlobalCodes(response));
        }, showNotification: false
    }));
}
