import React from 'react'
import CompleteYourPaymentPage from './complete-your-payment'
import PaymentCompletedPage from './payment-completed'
import { useLocation } from 'react-router-dom';

const CompleteYourPayment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('sessionId');
  
  const completed = sessionId !== undefined && sessionId?.length > 0;
  
  return (
      <div>
        { completed 
          ? <PaymentCompletedPage sessionId={sessionId} />
          : <CompleteYourPaymentPage/> 
        }
      </div>
  )
}

export default CompleteYourPayment