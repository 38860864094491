import React, { useState, useEffect } from "react";
import { Link, } from "../../../functional/global-import";
import { Image, Menu, Icon, } from "semantic-ui-react";
import { Logo2, SmallLogo, } from "../../../functional/global-image-import";
import { env } from "../../../functional/global-import";
import { useSelector, useDispatch } from 'react-redux';
import { storeBookDetails } from "../../../../store/actions/global.actions";
import { logout } from "../../../../store/actions";
import SideBarDetailConfing from "../../../../config/sideBar.config"
import { apiCall } from "../../../../store/actions";
import * as FullStory from '@fullstory/browser';
import { ShowMessage } from "../..";

const { forwardRef, useImperativeHandle } = React;
const Sidebar = forwardRef((props, ref) => {
	const [activeItem, setActiveItem] = useState()
	const [user, setUser] = useState(false)
	const userRole = useSelector(state => state.auth.userDetail.role);
	const userDetail = useSelector(state => state.auth.userDetail);
	const [completedProfile, setCompletedProfile] = useState(false);
	const dispatch = useDispatch();
	const roleAndPermission = useSelector(state => state.auth.userDetail.roleAndPermission);

	const handleItemClick = (e, { name }) => {

		dispatch(storeBookDetails(""));
		setActiveItem(name);
	}

	useImperativeHandle(ref, () => ({
		
		setActiveItem(value) {
			setActiveItem(value)
		}
	}))

	useEffect(() => {
		roleAndPermission === undefined && dispatch(logout());
		roleAndPermission?.roleAndPermission === null && dispatch(logout());

		onLogoClick();
	}, []);

	useEffect(() => {
		if (!env.ENABLE_PENDO) return;

		(function(apiKey){
			(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
			v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
				o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
				y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
				z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
		})('d8f91e0f-3cde-45f9-4960-4358d8e24103');		
	}, []);

	useEffect(() => {
		if(!env.ENABLE_GTM) return;

		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
			});
			var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'G-Q2GZSBKSV5');		
	}, []);	

	useEffect(() => {
		if (typeof window.pendo == 'undefined') return;
		
		window.pendo.initialize({
			visitor: {
				id: userDetail.userId,
				email: userDetail.email,
				full_name: userDetail.name,
				role: userDetail.role
			},	
			account: {
				id: userDetail.userId
			}
		});	
	}, []);

	useEffect(() => {
		const fsInitialized = FullStory.isInitialized();

		if (!fsInitialized) return;
		
		if (userDetail.isTeacher){
			dispatch(apiCall({
				urls: ["GETTEACHERSTEP1"], method: "GET", data: { teacherId: userDetail.teacherId },
				onSuccess: (response) => {
					if (response.firstName !== undefined && response.lastName !== undefined)
					{
						FullStory.identify(userDetail.userId, {
							displayName: `${response.firstName} ${response.lastName}`,
							email: userDetail.email,
							teacherId: userDetail.teacherId,
							school: response.school,
							schoolDistrictName: response.schoolDistrictName
						});
					}

					if (!response.school) {
						if (window.location.href.indexOf(`/create-profile`)==-1)
							window.location.href=`${env.PUBLIC_URL}/create-profile`;
					}
				}
			}));
		}
		else {
			if (userDetail.name !== undefined)
			{
				FullStory.identify(userDetail.userId, {
					displayName: userDetail.name,
					email: userDetail.email
				});
			}
		}
	}, []);

	const pathName = window.location.pathname.split("/")[1]
	const onPathnameChange = () => {
		checkPayment();
		checkProfileCompleted();
		reloadMessages();

		let sideBarData = SideBarDetailConfing
		sideBarData.map((singleSideObj) => {
			if (singleSideObj?.testPathName?.test(pathName)) {
				setActiveItem(singleSideObj.name)
				if (singleSideObj.name === "Manage Teachers") {
					setUser(true)
				}
			}
		})
	}

	const checkPayment = () => {
		if (userRole !== "Teacher")
			return;

		dispatch(apiCall({
            urls: ["BILLINGSUBSCRIPTION"], method: "GET",
            onSuccess: (response) => {
				if (response.hasSubscriptionExpired)
					dispatch(logout());
            }
        }));
	};

	useEffect(() => {
		onPathnameChange();
	}, [window.location.pathname]);

	const checkProfileCompleted = ()=> {
		// profile creation is mandatory for teachers

		if (window.location.href.indexOf(`/create-profile`) >= 0 || completedProfile || !userDetail.isTeacher)
			return;

		dispatch(apiCall({
			urls: ["GETTEACHERSTEP1"], method: "GET", data: { teacherId: userDetail.teacherId },
			onSuccess: (response) => {
				if (!response.school) 
					window.location.href=`${env.PUBLIC_URL}/create-profile`;
				else {
					setCompletedProfile(true);
				}
			}
		}));
	};

	const onLogoClick = () => {
		if (userRole === "Teacher") {
			setActiveItem("Library");
		}
		else {
			setActiveItem("Dashboard");
		}
	}

	const handleClickUser = () => {
		setUser(!user)
	}

	const reloadMessages = () => {

	};

	// ********************************************************
	let sideBar = userRole && SideBarDetailConfing?.filter((sideObj) => sideObj.role.includes(userRole))
	let moduleName = roleAndPermission && roleAndPermission.length > 0 && roleAndPermission?.filter((item) => {
		return item.Access === 1 && item.permission
	});

	let allowedScreens = []
	moduleName && moduleName.length > 0 && moduleName?.map((item) => {
		item.permission.map((item2) => {
			if (item2.Access) {
				allowedScreens.push(item2)
			}
			else {
				return { ...item2 }
			}
		})
	})

	sideBar = userRole && userRole === "SubAdmin" ? sideBar &&
		sideBar?.filter((sideObj) => {
			let data = allowedScreens.filter((singleAllowedObj) => {
				return singleAllowedObj.ScreenName.toLowerCase() === sideObj.route.toLowerCase()
			})
			if (data?.length > 0) {
				return sideObj
			}
		})
		:
		sideBar


	return (
		<>
			<div className="Sidebar">
				<div className="logo" onClick={onLogoClick} >
					<Link to={userRole === "Teacher" ? `${env.PUBLIC_URL}/teacher-library`
						: userRole === "School" ? `${env.PUBLIC_URL}/teacher-library` :
							`${env.PUBLIC_URL}/dashboard`}><Image className="big" src={Logo2} />
						<Image className="small" src={SmallLogo} /></Link></div>
				<div className="mainMenu">
					<Menu text vertical>
						{/* {sideBar?.filter((sideObj) => sideObj.role.includes(userRole)).map((sideBarObj) => { */}

						{sideBar && sideBar.length > 0 && sideBar?.map((sideBarObj) => {

							return (
								<>
									{(sideBarObj.name === "Manage Teachers") ?
										<div className={`lessonPlanMenu  ${user === true ? "show" : ""}`}>
											<Menu.Item title={props.isActive ? sideBarObj.name : false} as={Link} to={`${env.PUBLIC_URL}/${sideBarObj.route}`} name={sideBarObj.name} active={activeItem === `${sideBarObj.name}`} onClick={handleItemClick}>
												<Image src={sideBarObj.whiteImage} className="white" />
												<Image src={sideBarObj.goldImage} className="blue" />
												<span>{sideBarObj.name}</span>
											</Menu.Item>
										</div>
										:
										<Menu.Item title={props.isActive ? sideBarObj.name : false} className={sideBarObj.name === "User Management" ? "lessonPlan" : ""} as={Link} to={`${env.PUBLIC_URL}/${sideBarObj.route}`} name={sideBarObj.name} active={activeItem === `${sideBarObj.name}`} onClick={handleItemClick}>
											<Image src={sideBarObj.whiteImage} className="white" />
											<Image src={sideBarObj.goldImage} className="blue" />
											<span>{sideBarObj.name}</span>
											{sideBarObj.name === "User Management" &&
												<Icon name="caret down" onClick={handleClickUser} />
											}
										</Menu.Item>
									}
								</>
							)
						})}
					</Menu>
				</div>
			</div>
			{ userRole === "Teacher" &&
				<ShowMessage reload={reloadMessages} />
			}
		</>
	);
}
)
export default Sidebar;

