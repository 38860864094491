import React from 'react'
import SignUpNewPage from './sign-up-new'


const SignUpNew = () => {
  return (
    <div><SignUpNewPage/></div>
  )
}

export default SignUpNew